import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {listRemoteOrganizations} from "../../../../actions/organizations";
import {Button, Form, Select} from "semantic-ui-react";
import {INTERNAL_TENANT_TYPES, SUBSCRIPTION_STATUSES, TENANT_TYPES} from "../../../../constants/saas";
import {createUseStyles} from "react-jss";
import {TENANTS_FILTER_CHANGED} from "../../../../actions/actionTypes";
import DatePicker from "react-datepicker";
import Grid from "@material-ui/core/Grid";
import {globalStyles} from "../../../styles";
import "./TenantsFilter.css";
import Box from "@material-ui/core/Box";
import moment from "moment";
import {ErrorMessage} from "@hookform/error-message";

const useStyles = createUseStyles(({
    errorMessage: {
        color: "red",
    },
    warningMessage: {
        color: "#ff9800"
    },
    formField: {
        marginLeft: 10,
        marginTop: 10,
    },
    ...globalStyles
}));

const formDefs = {
    onboardTimeFrom: {
        name: "onboardTimeFrom",
    },
    onboardTimeTo: {
        name: "onboardTimeTo",
    },
    tenantTypes: {
        name: "tenantTypes"
    },
    subscriptionStatuses: {
        name: "subscriptionStatuses",
    },
    orgId: {
        name: "orgId",
    },
};

const defaultValues = {
    tenantName: '',
    hostName: '',
    tenantTypes: [],
    subscriptionStatuses: [],
    orgId: '',
    onboardTimeTo: null,
    onboardTimeFrom: null,
};

const TenantsFilter = (props) => {
    const classes = useStyles();
    const {orgIds} = useSelector(state => state.orgs);


    const {register, unregister, watch, handleSubmit, errors, setValue, formState, reset} = useForm({
        defaultValues: defaultValues,
    });

    const dispatch = useDispatch();

    const watchOnboardTimeTo = watch(formDefs.onboardTimeTo.name);
    const watchOnboardTimeFrom = watch(formDefs.onboardTimeFrom.name);
    const watchOrgId = watch(formDefs.orgId.name);
    const watchSubscriptionStatuses = watch(formDefs.subscriptionStatuses.name);


    const {isDirty, isSubmitting} = formState;

    useEffect(() => {
        register({name: formDefs.tenantTypes.name});
        register({name: formDefs.subscriptionStatuses.name});
        register({name: formDefs.orgId.name});
        register({name: formDefs.onboardTimeTo.name});
        register({name: formDefs.onboardTimeFrom.name});


        return () => {
            unregister({name: formDefs.tenantTypes.name});
            unregister({name: formDefs.subscriptionStatuses.name});
            unregister({name: formDefs.orgId.name});
            unregister({name: formDefs.onboardTimeTo.name});
            unregister({name: formDefs.onboardTimeFrom.name});
        }

    }, [register, unregister]);

    useEffect(() => {
        dispatch(listRemoteOrganizations())
    }, [dispatch]);


    const onSearchChange = (e, data) => {
        dispatch(listRemoteOrganizations(0, 10, e.target.value));
    };

    const onOnboardTimeToChanged = (date) => {
        setValue(formDefs.onboardTimeTo.name, date);
    };

    const onBoardTimeFromChanged = (date) => {
        setValue(formDefs.onboardTimeFrom.name, date);
    };

    const onSubmit = data => {

        if (data) {
            if (data.onboardTimeFrom) {
                data.onboardTimeFrom = moment.utc(data.onboardTimeFrom).format("YYYY-MM-DDTHH:mm:ss")
            }

            if (data.onboardTimeTo) {
                data.onboardTimeTo = moment.utc(data.onboardTimeTo).format("YYYY-MM-DDTHH:mm:ss")
            }
        }


        dispatch({type: TENANTS_FILTER_CHANGED, filters: data});
    };


    return (
        <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
            <h3>Search tenant</h3>
            <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center">

                <Grid xs={12} sm={4}>
                    <div className={classes.formField}>
                        <Form.Field>
                            <label>Tenant name</label>
                            <input placeholder='Tenant Admin' name={"tenantName"} ref={register({
                                minLength: {
                                    value: 2,
                                    message: "Must be more than 2 characters"
                                },
                                maxLength: {
                                    value: 100,
                                    message: "Must be at less than 100 characters"
                                },
                            })}/>
                            <ErrorMessage errors={errors} name="tenantName">
                                {({message}) => <p className={classes.errorMessage}>{message}</p>}
                            </ErrorMessage>
                        </Form.Field>
                    </div>
                </Grid>

                <Grid xs={12} sm={4}>
                    <div className={classes.formField}>
                        <Form.Field>
                            <label>Host name</label>

                            <input placeholder='saas-dev-1' name={"hostName"} ref={register({
                                maxLength: {
                                    value: 63,
                                    message: "Must be less than 63 characters"
                                },
                                minLength: {
                                    value: 2,
                                    message: "Must be at least 2 characters"
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9][a-zA-Z0-9.\-_]+[a-zA-Z0-9]$/,
                                    message: "invalid sub-domain prefix"
                                },
                            })}/>
                            <ErrorMessage errors={errors} name="hostName">
                                {({message}) => <p className={classes.errorMessage}>{message}</p>}
                            </ErrorMessage>

                        </Form.Field>
                    </div>
                </Grid>
                <Grid xs={12} sm={4}>
                    <div className={classes.formField}>

                        <Form.Field
                            control={Select}
                            multiple
                            onChange={(e, data) => setValue(formDefs.tenantTypes.name, data.value, {shouldDirty: true})}
                            options={Object.values({...INTERNAL_TENANT_TYPES, ...TENANT_TYPES})}
                            label={{children: 'Tenant types', htmlFor: 'form-select-control-tenant-type'}}
                            placeholder='Tenant types'
                            search
                            searchInput={{id: 'form-select-control-tenant-type'}}
                        />
                        <ErrorMessage errors={errors} name="tenantTypes">
                            {({message}) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </div>
                </Grid>
                <Grid xs={12} sm={4}>
                    <div className={classes.formField}>
                        <Form.Field
                            control={Select}
                            multiple
                            value={watchSubscriptionStatuses}
                            onChange={(e, data) => {
                                setValue(formDefs.subscriptionStatuses.name, data.value, {shouldDirty: true})
                            }}
                            options={Object.values({...SUBSCRIPTION_STATUSES})}
                            label={{children: 'Subscription statuses', htmlFor: 'form-select-control-tenant-type'}}
                            placeholder='Subscription statuses'
                            search
                            searchInput={{id: 'form-select-control-tenant-type'}}
                        />
                        <ErrorMessage errors={errors} name="subscriptionStatuses">
                            {({message}) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </div>

                </Grid>
                <Grid xs={12} sm={4}>
                    <div className={classes.formField}>

                        <Form.Field>
                            <label>Onboardtime from</label>
                            <DatePicker
                                todayButton="Today"
                                placeholderText="Select the onboard time"
                                selected={watchOnboardTimeFrom}
                                locale="pt-BR"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                onChange={onBoardTimeFromChanged}
                            />
                        </Form.Field>
                    </div>
                </Grid>

                <Grid xs={12} sm={4}>
                    <div className={classes.formField}>
                        <Form.Field>
                            <label>Onboardtime to</label>
                            <DatePicker
                                todayButton="Today"
                                placeholderText="Select the onboard time"
                                selected={watchOnboardTimeTo}
                                locale="pt-BR"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                onChange={onOnboardTimeToChanged}
                            />
                        </Form.Field>
                    </div>
                </Grid>

                <Grid xs={12} sm={4}>
                    <div className={classes.formField}>
                        {orgIds &&
                        <Form.Field
                            control={Select}
                            onSearchChange={onSearchChange}
                            value={watchOrgId}
                            onChange={(e, data) => setValue(formDefs.orgId.name, data.value, {shouldDirty: true})}
                            options={[...Object.values(orgIds)]}
                            label={{children: 'Organisation', htmlFor: 'form-select-control-tenant-type'}}
                            placeholder='Organisation'
                            search
                            searchInput={{id: 'form-select-control-tenant-type'}}
                        />}
                        <ErrorMessage errors={errors} name="orgId">
                            {({message}) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Box display={"flex"} className={classes.mt1}>
                        <Button type={"submit"} primary disabled={isSubmitting || !isDirty}>
                            Search
                        </Button>
                        <Button onClick={() => {
                            reset(defaultValues);
                            onSubmit(null);
                        }} disabled={isSubmitting || !isDirty}>
                            Clear
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
}


export default TenantsFilter;
