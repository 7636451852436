import {OrganizationList} from "./OrganizationList";
import {OrgProducts} from "./OrgProducts";
import {OrgDetails} from "./OrgDetail";
import React from "react";
import {Grid, Segment} from "semantic-ui-react";
import {listAllRemotePermissions} from "../../../actions/users";
import {connect} from "react-redux";
import Toolbar from "../Toolbar";
import SimpleTabMenu from "../../components/SimpleTabMenu";


const orgMenuTabs = {
    products: {
        key: "products",
        tabName: "Products",
        component: <OrgProducts/>
    },
    details: {
        key: "details",
        tabName: "Details",
        component: <OrgDetails/>
    },
};

class OrganisationView extends React.Component {

    componentDidMount() {
        this.props.listAllPermissions();
    }

    render() {
        const {currentOrg} = this.props;

        return (

            <Grid padded={true}>


                <Grid.Column widescreen={"10"} mobile={"16"} tablet={"16"}>
                    <Toolbar/>

                    <Segment padded>
                        <OrganizationList/>
                    </Segment>
                </Grid.Column>

                {/*Show below only if curent user is enabled*/}

                <Grid.Column widescreen={"6"} mobile={"16"} tablet={"16"}>
                    {currentOrg &&
                    <SimpleTabMenu menu={orgMenuTabs}/>
                    }

                </Grid.Column>
            </Grid>
        )
    }


}


const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
    currentOrg: state.orgs.currentOrg,
});

const mapDispatchToProps = (dispatch) => ({

    listAllPermissions: () => dispatch(listAllRemotePermissions()),
});


export const OrganisationPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganisationView);
