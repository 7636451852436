import React from 'react';
import {Button, Segment} from "semantic-ui-react";
import UpdateIncidentForm from "./UpdateIncidentForm";
import IncidentTable from "./IncidentTable";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import {Config} from "../../../../actions/http";
import IncidentLogList from "./IncidentLogList";
import SimpleTabMenu from "../../../components/SimpleTabMenu";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {globalStyle} from "../../../../styles/globalStyle";
import Divider from "@material-ui/core/Divider";
import red from "@material-ui/core/colors/red";


const useStyles = makeStyles(theme => ({
    uptimeBtn: {
        marginTop: 10,
        marginLeft: 10,
    },
    errorMessageText: {
        color: red[700],
        marginTop: 16,
    },
    ...globalStyle(theme)
}));

const tabs = {
    logs: {
        key: "logs",
        tabName: "Logs",
    },
    updateIncident: {
        key: "updateIncident",
        tabName: "Update",
    },
};

const IncidentMain = () => {

    const classes = useStyles();
    const {currentIncident} = useSelector(state => state.releases);


    return (
        <>
            <div className={classes.uptimeBtn}>
                <Button positive onClick={() => window.location = `${Config.myAccountUrl}/saas/uptime`}>
                    View uptime
                </Button>
            </div>


            <div className={classes.mt1}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} sm={8}>
                        <IncidentTable/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {currentIncident &&
                        <SimpleTabMenu menu={tabs}>
                            {(active) => (
                                <>
                                    {active === tabs.logs.key &&
                                    <Segment attached={"bottom"}>
                                        <IncidentLogList/>
                                    </Segment>
                                    }
                                    {active === tabs.updateIncident.key &&
                                    <>
                                        <Segment attached={"bottom"}>
                                            <UpdateIncidentForm defaultValues={currentIncident}/>
                                        </Segment>
                                    </>}
                                    {currentIncident &&
                                    <Segment attached={"bottom"}>
                                        {currentIncident.errorMessage &&
                                        <>
                                            <h5>Error message</h5>
                                            <div>Internal error message from the release API server is shown below
                                                for troubleshooting purposes.
                                            </div>
                                            <Divider light className={classes.mt1}/>

                                            <div
                                                className={classes.errorMessageText}>{currentIncident.errorMessage}</div>
                                        </>}
                                    </Segment>}
                                </>
                            )}
                        </SimpleTabMenu>}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default IncidentMain;
