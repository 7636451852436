import {combineReducers} from 'redux';

import {reducer as formReducer} from 'redux-form';
import {session} from "./session";
import {releases} from "./releases";
import {users} from "./users";
import {orgs} from "./organizations";
import {licenses} from "./licenses";
import {filters} from "./filters";

const reducers = {
    session,
    releases,
    users,
    orgs,
    licenses,
    filters,
    form: formReducer.plugin({
        instanceForm: (state, action) => {
            return state;
        }

    })
};

export default combineReducers(reducers);
