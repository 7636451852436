import React, {useEffect} from 'react';
import UsageTable from "./UsageTable";
import {useDispatch, useSelector} from "react-redux";
import {listTenants} from "../../../../../actions/licenses";
import {withRouter} from "react-router-dom";
import {CURRENT_TENANT_READY} from "../../../../../actions/actionTypes";
import {showNotification} from "../../../../../actions/utils";
import UsageFilter from "./UsageFilter";

const ASSaasUsageMain = (props) => {
    const {match} = props;
    const session = useSelector(state => state.session);
    const dispatch = useDispatch();
    const tenantId = match.params.tenantId;

    useEffect(() => {

        if (tenantId) {
            listTenants(session, {
                searchFilters: {tenantId: tenantId}
            }).then(data => {
                console.log("tenant data", data);

                // should only have 1 data returned
                if (data && data.content && data.content.length === 1) {
                    dispatch({type: CURRENT_TENANT_READY, tenant: data.content[0]})
                } else {
                    dispatch(showNotification("Invalid tenant", "invalid tenant id", "error"));
                }

            })
        }
    }, [dispatch, session, tenantId]);

    return (
        <div style={{minHeight: "100vh"}}>
            <UsageFilter/>
            <UsageTable/>
        </div>
    );
};

export default withRouter(ASSaasUsageMain);
