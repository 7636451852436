import React, {useEffect, useState} from 'react';
import {listTenants} from "../../../../actions/licenses";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {CURRENT_TENANT_READY} from "../../../../actions/actionTypes";
import {Config} from "../../../../actions/http";
import {selectOrg} from "../../../../actions/organizations";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";

const columns = {
    name: {
        field: "name",
        filter: "tenantName",
        title: "Name",
        render: (row) => row.name,
    },
    hostName: {
        field: "hostName",
        filter: "hostName",
        title: "Host",
        render: (row) => <a href={`https://${row.hostName}.${Config.saasDomainName}`}>{row.hostName}</a>,
    },
    schemaName: {
        field: "schemaName",
        filter: "schemaName",
        title: "Schema name",
        render: (row) => row.schemaName,
    },
    tenantType: {
        field: "tenantType",
        filter: "tenantType",

        title: "Tenant type",
        render: (row) => row.tenantType,
    },
    subscriptionStatus: {
        field: "subscriptionStatus",
        filter: "subscriptionStatus",

        title: "Subscription status",
        render: (row) => row.subscriptionStatus,
    },
    onboardTime: {
        field: "onboardTime",
        filter: "onboardTime",

        title: "Onboard time",
        render: (row) => row.onboardTime ? moment.utc(row.onboardTime).local().format("lll") : "-",
    },
    statusChangedTime: {
        field: "statusChangedTime",
        filter: "statusChangedTime",

        title: "Status changed",
        render: (row) => row.statusChanged ? moment.utc(row.statusChanged).local().format("lll") : "-",
    },
    expiryDate: {
        field: "expiryDate",
        filter: "expiryDate",
        title: "Expiry time",
        render: (row) => row.expiryDate ? moment.utc(row.expiryDate).local().format("lll") : "-",
    },
};


const TenantsTable = (props) => {

    const [selectedRow, setSelectedRow] = useState(null);
    const session = useSelector(state => state.session);
    const dispatch = useDispatch();
    const {tenantsFilter} = useSelector(state => state.filters);
    const tableRef = React.createRef();

    useEffect(() => {

        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }

    }, [tableRef, tenantsFilter]);

    const onRowClick = (row) => {
        dispatch({type: CURRENT_TENANT_READY, tenant: row});
        dispatch(selectOrg(row.orgId));
        setSelectedRow(row.id);

    };

    return (
        <MaterialTable columns={Object.values(columns)}
                       tableRef={tableRef}
                       title={"ActiveServer tenants"}
                       data={query =>
                           new Promise(async (resolve, reject) => {
                               let filters = {
                                   page: query.page,
                                   size: query.pageSize,
                                   sortDirection: query.orderDirection,
                                   searchFilters: tenantsFilter,
                               };

                               if (query.orderBy) {
                                   filters.orderBy = query.orderBy.field;
                               }

                               const tenantPage = await listTenants(session, filters);

                               if (!tenantPage || !tenantPage.content) {
                                   reject();
                                   return;
                               }

                               resolve({
                                   data: tenantPage.content,
                                   page: tenantPage.number,
                                   totalCount: tenantPage.totalElements,
                               })
                           })
                       }
                       components={{
                           Container: props => <Paper {...props} elevation={0}/>,
                       }}
                       options={{
                           // filtering: true
                           search: false,
                           rowStyle: rowData => ({
                               backgroundColor: (selectedRow === rowData.id) ? '#EEE' : '#FFF'
                           }),
                       }}
                       onRowClick={(e, rowData) => onRowClick(rowData)}
        />
    );
};

export default TenantsTable;
