import React from 'react'
import {Modal} from 'semantic-ui-react'
import {PropTypes} from "prop-types";
import {globalStyles} from "../styles";

const SimpleModal = (props) => {

    const {open, header, children, onClose} = props;
    return (
        <Modal open={open} centered={false} style={globalStyles.modal} closeIcon onClose={onClose}>
            <Modal.Header>{header}</Modal.Header>
            {children}
        </Modal>
    )
};

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    header: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SimpleModal
