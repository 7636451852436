import {Config, get, post, put} from "./http";
import {SubmissionError} from 'redux-form';
import {
    CLOSE_ORG_ADD_MODAL,
    CLOSE_PRODUCT_CONFIRM_MODAL,
    ORG_PRODUCTS_READY,
    ORG_UPDATED,
    ORGS_READY,
    SELECT_ORG,
    UPDATE_ORG_PRODUCTS_SUCCESSFUL
} from "./actionTypes";
import {showNotification} from "./utils";

const organizationsReady = (page) => {
    return {
        type: ORGS_READY,
        page,
    }
};

export const selectOrg = (orgId) => {


    return (dispatch, getState) => {

        if (!orgId) {
            dispatch({type: SELECT_ORG, org: null});
            return
        }

        get(getState().session, `${Config.authHost}/api/v1/admin/org/${orgId}`)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            const org = body.data;

            // console.log("org", org);

            dispatch({type: SELECT_ORG, org});

            dispatch(listOrgProducts(org.id))

        }).catch((error) => {

            console.error("error", error);

            dispatch({type: CLOSE_ORG_ADD_MODAL});
            dispatch({type: SELECT_ORG, org: null});

            // dispatch(showNotification("Failed to get organisation!", error.message, "error"));
        })

    }
};

const organizationUpdated = (org) => {
    return {
        type: ORG_UPDATED,
        org
    }
};

/**
 * List remote organisation per page
 * @param page
 * @param size
 * @param all
 * @param keyword
 * @returns {Function}
 */
export const listRemoteOrganizations = (page = 0, size = 10, keyword = null) => {

    return (dispatch, getState) => {

        let url = `${Config.authHost}/api/v1/admin/orgs?p=${page}&s=${size}`;

        if (keyword) {
            url += `&q=${keyword}`;
        }

        console.log('listing orgs for page keyword', url);
        get(getState().session, url)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('fetch orgs', body);

            const data = body.data;
            // otherwise its request to list organization per page
            dispatch(organizationsReady(data));

        }).catch((error) => {

            console.error("error", error);

            dispatch(showNotification("Failed to list organisations!", error.message, "error"));

        })
    }
};

export const updateOrgDetails = (org) => {

    return (dispatch, getState) => {

        // get the name of current org
        const {currentOrg} = getState().orgs;

        console.log('update org details', org, currentOrg);
        return post(getState().session, `${Config.authHost}/api/v1/admin/org/${currentOrg.id}`, {
            ...org,
        })
            .then(response => {
                if (!response.ok) {
                    throw new SubmissionError(response.status + ': ' + response.statusText);
                }
                return response.json();
            }).then((body) => {

                console.log('org updated', body);

                const data = body.data;
                dispatch(organizationUpdated(data));

                dispatch(showNotification("Saved Changes", "You have successfully saved changes to the organisation!"));

            }).catch((error) => {

                console.error("error", error);

                throw new SubmissionError({_error: error});

            })


    }
};

/**
 * Add new organization
 * @param org
 * @returns {function(*, *): Promise<T>}
 */
export const addNewOrg = (org) => {
    return (dispatch, getState) => {
        return put(getState().session, `${Config.authHost}/api/v1/admin/org`, {
            ...org,
        }).then(response => {
            if (!response.ok) {
                throw new SubmissionError(response.status + ': ' + response.statusText);
            }
            return response.json();
        }).then((body) => {

            if (body.success) {
                //list the last page, since it will be the last entry
                dispatch(listRemoteOrganizations(getState().orgs.currentPage.totalPages - 1, 10));

                dispatch({type: CLOSE_ORG_ADD_MODAL});

                dispatch(showNotification("Saved Changes", 'You have successfully created new organisation'));

            } else {

                throw new SubmissionError({_error: 'unknown server error'});
            }

        }).catch((error) => {

            console.error("error", error);
            throw new SubmissionError({name: 'Organisation name exists.', _error: error.message});

        })
    }
};

export const listOrgProducts = (orgId) => {
    return (dispatch, getState) => {
        return get(getState().session, `${Config.authHost}/api/v1/admin/org/${orgId}/products`)
            .then(response => {
                return response.json();
            }).then(body => {

                if (body.success) {
                    console.log(body.data);
                    dispatch({type: ORG_PRODUCTS_READY, products: body.data})
                }

            }).catch(error => {

                console.error("error", error);

            })
    }
};

/**
 * Updates the permissions of all users associated with this organisation.
 * @returns {function(*, *): *}
 */
export const updateOrgProducts = () => {
    return (dispatch, getState) => {

        const {currentOrg} = getState().orgs;

        const products = Object.values(currentOrg.products);

        return post(getState().session, `${Config.authHost}/api/v1/admin/org/${currentOrg.id}/products`, products)
            .then(response => {
                return response.json();
            }).then(body => {
                if (body.success) {
                    dispatch({type: UPDATE_ORG_PRODUCTS_SUCCESSFUL})
                    dispatch({type: CLOSE_PRODUCT_CONFIRM_MODAL})
                }
            }).catch(error => {
                console.error(error);
            })

    }
};
