export const timeFormats = {
    "DAILY": {
        key: "DAILY",
        text: "Daily",
        value: "DAILY"
    }
    ,
    "WEEKLY": {
        key: "WEEKLY",
        text: "Weekly",
        value: "WEEKLY"
    }
    ,
    "MONTHLY": {
        key: "MONTHLY",
        text: "Monthly",
        value: "MONTHLY"
    },
    "YEARLY": {
        key: "YEARLY",
        text: "Yearly",
        value: "YEARLY",
    }
};

export const TIME_RANGE_TYPES = {
    MONTHLY: {
        key: "MONTHLY",
        text: "Monthly",
        value: "MONTHLY",
    },
    QUARTERLY: {
        key: "QUARTERLY",
        text: "Quaterly",
        value: "QUARTERLY",

    },
    YEARLY: {
        key: "YEARLY",
        text: "Yearly",
        value: "YEARLY",
    },
    CUSTOM: {
        key: "CUSTOM",
        text: "Custom",
        value: "CUSTOM"
    }
};


export const MONTH_OPTIONS = {
    JAN: {
        value: 0,
        text: "January",
        key: "JAN"
    },
    FEB: {
        value: 1,
        text: "February",
        key: "FEB"
    },
    MARCH: {
        value: 2,
        text: "March",
        key: "MARCH"
    },
    APRIL: {
        value: 3,
        text: "April",
        key: "APRIL"
    },
    MAY: {
        value: 4,
        text: "May",
        key: "MAY"
    },
    JUNE: {
        value: 5,
        text: "June",
        key: "JUNE"
    },
    JULY: {
        value: 6,
        text: "July",
        key: "JULY"
    },
    AUGUST: {
        value: 7,
        text: "August",
        key: "AUGUST"
    },
    SEPTEMBER: {
        value: 8,
        text: "September",
        key: "SEPTEMBER"
    },
    OCTOBER: {
        value: 9,
        text: "October",
        key: "OCTOBER"
    },
    NOVEMBER: {
        value: 10,
        text: "November",
        key: "NOVEMBER"
    },
    DECEMBER: {
        value: 11,
        text: "December",
        key: "DECEMBER"
    },
};

export const QUARTER_OPTIONS = {
    Q1: {
        value: 1,
        text: "Q1",
        key: "Q1"
    },
    Q2: {
        value: 2,
        text: "Q2",
        key: "Q2"
    },
    Q3: {
        value: 3,
        text: "Q3",
        key: "Q3"
    },
    Q4: {
        value: 4,
        text: "Q4",
        key: "Q4"
    },
};
