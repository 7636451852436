import React from "react";
import {Button, Form, Header, Message, Modal} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {InputField, SelectField} from "react-semantic-redux-form";
import {CLOSE_ORG_ADD_MODAL} from "../../../actions/actionTypes.js";
import {addNewOrg} from "../../../actions/organizations";
import {isoCountries} from "../../../constants/countries";
import * as validator from "email-validator";
import {globalStyles} from "../../styles";

const validate = (values) => {

    const errors = {};

    if (!values.email) {
        errors.email = 'email address is required';
    } else if (!validator.validate(values.email)) {
        errors.email = 'Invalid email address.';
    }

    return errors;

};
const OrgAddModal = ({toOpen, closeModal, addOrg, error, pristine, handleSubmit, submitting}) =>

    <Modal size="small" open={toOpen} onClose={closeModal} style={globalStyles.modal}
           as="form" onSubmit={handleSubmit(addOrg)} closeIcon>

        <Header icon="building" content="Add new organisation"/>

        <Modal.Content>
            <Form as="div">

                {/*<Form.Group widths='equal'>*/}

                    <Field name="name" label="Name" placeholder="Organisation name" component={InputField} autoFocus/>
                    {/*<Field name="trackerUrl" label="Tracker Url" placeholder="https://tracker.creative.com.au"*/}
                    {/*component={InputField}/>*/}
                {/*</Form.Group>*/}
                <Form.Group widths='equal'>
                    <Field name="address" label="Address" placeholder="Address" component={InputField}/>
                    <Field name="address1" label="Address1" placeholder="Address1" component={InputField}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="city" label="City" placeholder="City" component={InputField}/>
                    <Field name="state" label="State" placeholder="State" component={InputField}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="country" label="Country" placeholder="Country" component={SelectField}
                           options={isoCountries}/>
                    <Field name="postCode" label="Post Code" placeholder="Post Code" component={InputField}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="businessType" label="Business type" placeholder="Business type"
                           component={InputField}/>
                    <Field name="email" label="Email" placeholder="Email" component={InputField}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="contactPerson" label="Contact Person" placeholder="Contact Person"
                           component={InputField}/>
                    <Field name="phone" label="Phone Number" placeholder="Phone Number" component={InputField}/>
                </Form.Group>
                {error && <Message negative>
                    <Message.Header>New organisation could not be created</Message.Header>
                    <p>{error}</p>
                </Message>}
            </Form>

        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={closeModal}>Cancel</Button>
            <Button positive icon='checkmark' labelPosition='right' content='Create' type='submit' primary
                    disabled={pristine || submitting}/>
        </Modal.Actions>

    </Modal>
;


const OrgAddView = reduxForm({
    form: 'addOrgForm',
    validate,
    enableReinitialize: true
})(OrgAddModal);

const mapStateToProps = (state) => ({
    org: state.orgs.currentOrg,
    toOpen: state.orgs.isOrgModalShown
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch({type: CLOSE_ORG_ADD_MODAL}),
    addOrg: (values) => dispatch(addNewOrg(values)),
});


export const OrgAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgAddView);
