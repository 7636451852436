import React from 'react';
import {Button, Divider, Form, Header, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {Field, reduxForm, reset} from "redux-form";
import {InputField} from 'react-semantic-redux-form';
import {updateUserPassword} from "../../../actions/users";

const validate = (values) => {
    const errors = {};

    if (!values.password1) {
        errors.password1 = 'New password is required'
    } else if (!values.password2) {
        errors.password2 = 'Please repeat the new password'
    } else if (values.password1.length < 6) {
        errors.password1 = 'New password must have 6 characters or more'
    } else if (values.password1 !== values.password2) {
        errors.password2 = 'Repeat password must be the same'
    }

    return errors;

};


const UserAccountView = ({updateUserPassword, user, error, pristine, handleSubmit, submitting, reset}) => {
    return (
        <Segment attached='bottom' padded as="form" onSubmit={handleSubmit(updateUserPassword)}>

            <Header as="h3">User Account: {user ? user.firstName + " " + user.lastName : "Not Selected"}</Header>
            <Divider/>

            <Form as="div">
                <Field name="password1" label="Password" placeholder="Password" type="password" component={InputField}/>
                <Field name="password2" label="Confirm Password" placeholder="Confirm Password" type="password"
                       component={InputField}/>

                <Button type='submit' primary disabled={pristine || submitting}>Update Password</Button>
                <Button disabled={pristine || submitting} onClick={reset}>Undo Changes</Button>


                {error && <Message negative>
                    <Message.Header>Update failed</Message.Header>
                    <p>{error}</p>
                </Message>}

            </Form>

        </Segment>
    )
};


const UserAccountView_ = reduxForm({
    form: 'userAccountForm',
    validate,
    enableReinitialize: true
})(UserAccountView);


const mapStateToProps = (state) => ({
    initialValues: state.users.currentUser,
    user: state.users.currentUser,
    isAdmin: state.session.admin,
});

const mapDispatchToProps = (dispatch) => ({
    updateUserPassword: (values) => {
        dispatch(updateUserPassword(values));
        dispatch(reset('userAccountForm'));
    },
});


export const UserAccount = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccountView_);

