import React from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'
import {CLOSE_CONFIRMATION_MODAL} from "../../../actions/actionTypes.js";
import {connect} from "react-redux";
import {updateUserDetails} from "../../../actions/users";
import {globalStyles} from "../../styles";

const EnableUserConfirmationModal = ({toOpen, closeModal, changeUserEnabled, user}) => (
    <Modal open={toOpen} style={globalStyles.modal} onClose={closeModal} closeIcon>
        {user && user.enabled ?
            <Header icon="user" content="Disable User"/>
            : <Header icon="user" content="Enable User"/>
        }
        <Modal.Content>
            {user && user.enabled ?

                <p>
                    Are you sure you want to disable this user?
                </p> :
                <p>
                    Are you sure you want to enable this user?
                </p>
            }
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={closeModal} color='red'>
                <Icon name='remove'/> No
            </Button>
            <Button color='green' onClick={() => changeUserEnabled(user)}>
                <Icon name='checkmark'/> Yes
            </Button>
        </Modal.Actions>
    </Modal>
);

const mapStateToProps = (state) => ({
    toOpen: state.users.isConfirmationModalShown,
    user: state.users.currentUser
});

const mapDispatchToProps = (dispatch) => ({
        closeModal: () => dispatch({type: CLOSE_CONFIRMATION_MODAL}),
        changeUserEnabled: (user) => {
            user.enabled = !user.enabled;
            dispatch(updateUserDetails(user));
            dispatch({type: CLOSE_CONFIRMATION_MODAL});
        }
    }
);

export const ConfirmationModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(EnableUserConfirmationModal);
