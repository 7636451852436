import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getLogQueryId, getLogs} from "../../../../actions/release";
import LogsTable from "./LogsTable";
import LogsFilter from "./LogsFilter";
import {Icon, Message} from "semantic-ui-react";
import {LOG_QUERY_STATUSES} from "../../../../constants/saas";

const LogsMain = () => {
    const dispatch = useDispatch();
    const {logsFilter,} = useSelector(state => state.filters);
    const {logQueryId, useNewCluster, logQueryStatus} = useSelector(state => state.releases);

    useEffect(() => {

        dispatch(getLogQueryId(logsFilter));

    }, [dispatch, logsFilter]);


    useEffect(() => {

        if (logQueryId) {

            let interval = setInterval(() => {
                dispatch(getLogs(logQueryId, useNewCluster));

            }, [3000]);

            if (logQueryStatus === LOG_QUERY_STATUSES.complete.value) {
                clearInterval(interval)
            }

            return () => {
                clearInterval(interval)
            }
        }

    }, [logQueryId, dispatch, logQueryStatus, useNewCluster]);

    return (
        <div>
            {logQueryStatus !== LOG_QUERY_STATUSES.complete.value &&
            <Message icon>
                <Icon name='circle notched' loading/>
                <Message.Content>
                    <Message.Header>Log query in progress</Message.Header>
                    We are fetching that content for you.
                </Message.Content>
            </Message>
            }
            <LogsFilter defaultValues={logsFilter}/>
            <LogsTable/>
        </div>
    );
};

export default LogsMain;
