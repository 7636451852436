import React from 'react';
import {Button, Icon, Menu, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {selectVersion, switchProduct} from "../../../actions/release";
import moment from "moment";
import filesize from 'filesize';

const VersionListView = ({
                             switchPrd,
                             selectVersion,
                             currProduct,
                             currVersion,
                             versionList,
                             fileList,
                             promote,
                             pullback
                         }) => (

    <div>
        <Segment padded attached="bottom">

            {currProduct === 'ios-sdk' &&
            <Button.Group>
                <Button positive>iOS</Button>
                <Button.Or/>
                <Button onClick={() => switchPrd(null, {name: "android-sdk"})}>Android</Button>
            </Button.Group>
            }

            {currProduct === 'android-sdk' &&
            <Button.Group>
                <Button onClick={() => switchPrd(null, {name: "ios-sdk"})}>iOS</Button>
                <Button.Or/>
                <Button positive>Android</Button>
            </Button.Group>
            }


            <p><span>Product description</span></p>
            <Table striped selectable>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Version</Table.HeaderCell>
                        <Table.HeaderCell>File Count</Table.HeaderCell>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell>Uploaded</Table.HeaderCell>
                        <Table.HeaderCell>Promoted</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {versionList && versionList.map(v =>
                        <Table.Row key={v.version}
                                   onClick={(e) => selectVersion(currProduct, v.version)}
                                   active={currVersion && currVersion.version === v.version}>
                            <Table.Cell>{v.version}</Table.Cell>
                            <Table.Cell>{v.fileCount}</Table.Cell>
                            <Table.Cell>{filesize(v.size)}</Table.Cell>
                            <Table.Cell>{v.releaseDate ? moment(v.releaseDate).format('lll') : "Not Yet"}</Table.Cell>
                            <Table.Cell>{v.promoted ? <Icon color="green" name="check"/> : ""} </Table.Cell>
                        </Table.Row>
                    )}

                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='6'>
                            <Menu floated='right' pagination>
                                <Menu.Item as='a' icon>
                                    <Icon name='left chevron'/>
                                </Menu.Item>
                                <Menu.Item as='a'>1</Menu.Item>
                                <Menu.Item as='a'>2</Menu.Item>
                                <Menu.Item as='a'>3</Menu.Item>
                                <Menu.Item as='a'>4</Menu.Item>
                                <Menu.Item as='a' icon>
                                    <Icon name='right chevron'/>
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>


        </Segment>

    </div>
)

const mapStateToProps = (state) => ({
    currProduct: state.releases.currentSelectedProduct,
    currVersion: state.releases.currentSelectedVersion,
    versionList: state.releases.versionList[state.releases.currentSelectedProduct],
});

const mapDispatchToProps = (dispatch) => ({
    switchPrd: (e, {name}) => dispatch(switchProduct(name)),
    selectVersion: (product, version) => dispatch(selectVersion(product, version)),


});


export const VersionList = connect(
    mapStateToProps,
    mapDispatchToProps
)(VersionListView);

