import React from 'react'
import {connect} from 'react-redux';
import {Breadcrumb, Header, Icon, Menu, Message, Segment, Sidebar, TransitionablePortal} from 'semantic-ui-react'
import {logout} from "../actions/auth";
import {Link, Redirect, Route, Switch, withRouter} from "react-router-dom";
import {Config} from "../actions/http";
import {HIDE_NOTIFICATION} from "../actions/actionTypes.js";
import {childPaths, headers, modules} from "./modules/paths";
import {hasAnyPermission} from "../utils/session";
import {isMobile} from "react-device-detect";

const drawerWidth = 200;

export const NavView = ({user, logout, location, notification, hideNotification, currentOrg, isLoading, currentTenant}) => {
    const [visible, setVisible] = React.useState(!isMobile);
    const [childSideBar, setChildSideBar] = React.useState(null);

    const activeLocation = headers.find(m => location.pathname.split('/')[1] === m.path.split('/')[1]);

    return (
        <>
            <Menu inverted borderless style={{margin: 0}}>
                <Menu.Item
                    // color="blue"
                    header
                    onClick={() => setVisible(!visible)}
                    // active={true}
                >
                    <Icon name='bars'/>
                    GP Admin
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item
                        name='profile' onClick={() => window.location = `${Config.myAccountUrl}/profile`}>
                        {"Hi " + user.firstName}!
                    </Menu.Item>

                    <Menu.Item name='logout' onClick={logout}>
                        Logout
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            <Sidebar.Pushable as={Segment} style={{minHeight: "100vh", margin: 0, border: 0}}>
                {/*<Sidebar.Pushable as={Segment}>*/}
                <Sidebar
                    as={Menu}
                    animation='push'
                    icon='labeled'
                    inverted
                    // onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                    width='thin'
                >
                    {headers.map(m => <>

                        {hasAnyPermission(user, m.permissions) && (m.children ?
                            <Menu.Item onClick={() => setChildSideBar(m.children)}>
                                <Icon name={m.icon}/>
                                {m.label}
                            </Menu.Item>
                            :
                            <Menu.Item key={m.path} color="green" as={Link}
                                       active={activeLocation && m.path === activeLocation.path}
                                       to={m.path}>
                                <Icon name={m.icon}/>
                                {m.label}
                            </Menu.Item>)}
                    </>)}
                </Sidebar>
                <Sidebar
                    as={Menu}
                    animation='push'
                    icon='labeled'
                    inverted
                    onHide={() => setChildSideBar(null)}
                    vertical
                    visible={childSideBar !== null}
                    width='thin'
                >
                    <Menu.Item onClick={() => setChildSideBar(null)}>
                        <Icon name={"close"}/>
                    </Menu.Item>
                    {childSideBar && Object.values(childSideBar).map(m => (
                        <>
                            {hasAnyPermission(user, m.permissions) &&
                            <Menu.Item key={m.path} color="green" as={Link}
                                       active={m.path === activeLocation.path}
                                       to={m.path}>
                                {m.icon}
                                {m.label}
                            </Menu.Item>}
                        </>
                    ))}
                </Sidebar>
                <Sidebar.Pusher>

                    <div style={visible ? {margin: 10, maxWidth: `calc(100% - ${drawerWidth}px)`} : {margin: 10}}>
                        {activeLocation &&
                        <Breadcrumb className="Breadcrumb">

                            <Breadcrumb.Section link as={Link}
                                                to={activeLocation.path}>{activeLocation.label}</Breadcrumb.Section>

                            <Breadcrumb.Divider icon='right chevron'/>

                            {location.pathname.split('/')[2] && currentOrg &&
                            <Breadcrumb.Section link>{currentOrg.name}</Breadcrumb.Section>
                            }
                            {location.pathname.split('/')[2] && currentTenant &&
                            <Breadcrumb.Section link>{currentTenant.name}</Breadcrumb.Section>
                            }
                            {location.pathname.split("/")[2] && childPaths[location.pathname.split("/")[2]] &&
                            <Breadcrumb.Section
                                link>{childPaths[location.pathname.split("/")[2]].label}</Breadcrumb.Section>
                            }
                        </Breadcrumb>}

                        {isLoading &&
                        <Message icon>
                            <Icon name='circle notched' loading/>
                            <Message.Content>
                                <Message.Header>Just one second</Message.Header>
                                We are fetching that content for you.
                            </Message.Content>
                        </Message>
                        }

                        <Switch>
                            {modules.filter(u => hasAnyPermission(user, u.permissions)).map((route, i) => (
                                <Route exact {...route} key={i}/>
                            ))}

                            <Redirect to='/404'/>
                        </Switch>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>

            {notification && notification.format === "success" &&
            <TransitionablePortal onClose={hideNotification} open={notification !== null}>

                <Segment color="green" style={{left: '40%', position: 'fixed', bottom: '5%', zIndex: 1000}}>
                    <Header color="green">{notification.header}</Header>
                    <p>{notification.message}</p>
                </Segment>
            </TransitionablePortal>

            }


            {notification && notification.format === "error" &&
            <TransitionablePortal onClose={hideNotification} open={notification !== null}>
                <Segment color="red" style={{left: '40%', position: 'fixed', bottom: '5%', zIndex: 1000}}>
                    <Header color="red">{notification.header}</Header>
                    <p>{notification.message}</p>
                </Segment>
            </TransitionablePortal>
            }

        </>
    )
};


const mapStateToProps = (state) => ({
    user: state.session.user,
    notification: state.session.notification,
    currentOrg: state.orgs.currentOrg,
    currentTenant: state.licenses.currentTenant,
    isLoading: state.session.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    hideNotification: () => dispatch({type: HIDE_NOTIFICATION}),

});


export const Navigation = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NavView));

