import React from 'react';
import {Button, Divider, Dropdown, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {listOrgProducts} from "../../../actions/organizations";
import {products} from "../../../constants/products";
import {OPEN_PRODUCT_CONFIRM_MODAL, ORG_ADD_PRODUCT, ORG_REMOVE_PRODUCT} from "../../../actions/actionTypes.js";
import OrgProductConfirmation from "./OrgProductConfirmation";

class OrgProductsView extends React.Component {

    componentWillMount() {
        const {currentOrg} = this.props;
        if (currentOrg) {
            this.props.listProducts(currentOrg.id)
        }
    }

    render() {

        const {currentOrg} = this.props;

        return (
            <Segment attached='bottom' padded>
                <Header
                    as="h3">Organisation: {currentOrg ? currentOrg.name : "Not Selected"}</Header>
                <Divider/>

                <Header as="h4">Accessible products</Header>
                <Table striped selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Product Code</Table.HeaderCell>
                            <Table.HeaderCell>Product Name</Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {currentOrg && currentOrg.products &&
                        Object.values(currentOrg.products).map((product, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>{product.productCode}</Table.Cell>
                                    <Table.Cell>{product.productName}</Table.Cell>
                                    <Table.Cell>
                                        <Button onClick={() => this.props.removeProduct(product.productCode)} icon
                                                size="mini">
                                            <Icon name="close"/>
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        )}
                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <Dropdown upward text='Add Product' icon='add' floating labeled button className='icon'>
                                    <Dropdown.Menu>
                                        <Dropdown.Header icon='key' content='List of all products'/>
                                        <Dropdown.Menu scrolling>
                                            {Object.values(products).map((product, i) => {

                                                let isSelected = false;
                                                let label = {color: 'red', empty: true, circular: true};
                                                // change the label to green if permissionId is in currentOrg
                                                if (currentOrg && currentOrg.products && product.code in currentOrg.products) {
                                                    label.color = 'green';
                                                    isSelected = true;
                                                }

                                                return (
                                                    <Dropdown.Item
                                                        disabled={isSelected}
                                                        key={product.code}
                                                        onClick={() => this.props.addProduct(product.code, product)}
                                                        text={product.name}
                                                        value={product.code} label={label}/>
                                                )
                                            })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <OrgProductConfirmation/>
                <Button onClick={this.props.openModal} primary>Save Change</Button>
            </Segment>
        )
    }
}


const mapStateToProps = (state) => ({
    currentOrg: state.orgs.currentOrg,
});

const mapDispatchToProps = (dispatch) => ({
    listProducts: (orgId) => dispatch(listOrgProducts(orgId)),
    removeProduct: (code) => dispatch({type: ORG_REMOVE_PRODUCT, code}),
    addProduct: (code, product) => dispatch({type: ORG_ADD_PRODUCT, code, product}),
    openModal: () => dispatch({type: OPEN_PRODUCT_CONFIRM_MODAL})
});


export const OrgProducts = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgProductsView);

