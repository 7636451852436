import React from 'react';
import {Button, Divider, Form, Header, Menu, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {setVersionSummaryMode, updateCurrentVersionSummary} from "../../../actions/release";

import ReactMarkdown from 'react-markdown';
import {Field, getFormValues, reduxForm} from "redux-form";
import {RenderedTextArea} from "../../form/formComponents";

const VersionSummaryView = ({
                                initialValues,
                                handleSubmit,
                                updateBasicInfo,
                                pristine,
                                reset,
                                submitting,
                                error,
                                currProduct,
                                currVersion,
                                displaySummary,
                                editSummary,
                                summaryMode,
                                previewSummary,
                                updateSummary,
                            }) => (


    <Segment attached="bottom" padded as="form" onSubmit={handleSubmit(updateSummary)}>

        <Header as="h3">Release Summary</Header>


        <Menu tabular attached="top">
            <Menu.Item name='displaySummary' active={summaryMode === 'display'}
                       onClick={(e) => displaySummary(currProduct, currVersion.name)}>
                Markdown Formatted
            </Menu.Item>
            <Menu.Item name='editSummary' active={summaryMode === 'edit'}
                       onClick={(e) => editSummary(currProduct, currVersion.name)}>
                Edit
            </Menu.Item>
        </Menu>
        <Segment attached="bottom">

            {summaryMode === 'edit' ?
                <Form as="div">
                    <Field name="summary" component={RenderedTextArea} autoFocus autoHeight={true}/>
                    <Divider/>
                    <Button type="submit" primary disabled={pristine || submitting}>Save Changes</Button>
                </Form>
                :
                <ReactMarkdown source={previewSummary ? previewSummary.summary : ""}/>
            }
        </Segment>

    </Segment>

);

const VersionSummaryForm = reduxForm({
    form: 'summaryForm',

    validate: () => {
    },
    enableReinitialize: true

})(VersionSummaryView);

const mapStateToProps = (state) => ({
    currProduct: state.releases.currentSelectedProduct,
    currVersion: state.releases.currentSelectedVersion,
    summaryMode: state.releases.currentSelectedVersionSummaryMode,
    previewSummary: getFormValues('summaryForm')(state),
    initialValues: {
        summary: state.releases.currentSelectedVersion ? state.releases.currentSelectedVersion.summary : null
    }
});

const mapDispatchToProps = (dispatch) => ({
    editSummary: (product, version) => dispatch(setVersionSummaryMode(product, version, 'edit')),
    displaySummary: (product, version) => dispatch(setVersionSummaryMode(product, version, 'display')),
    updateSummary: (values) => dispatch(updateCurrentVersionSummary(values))

});


export const VersionSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(VersionSummaryForm);

