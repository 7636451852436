import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Icon, Select} from "semantic-ui-react";
import {DATA_POINTS, INCIDENT_VISIBILITIES} from "../../../../constants/saas";
import {createUseStyles} from "react-jss";
import {globalStyle} from "../../../../styles/globalStyle";
import {useDispatch} from "react-redux";
import {createIncident} from "../../../../actions/release";
import * as moment from "moment";
import PropTypes from "prop-types";
import {ErrorMessage} from "@hookform/error-message";
import {DateTimePicker} from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const useStyles = createUseStyles(({
    ...globalStyle()
}));

const formDef = {
    dataPoint: "dataPoint",
    visibility: "visibility",
    occurredAt: 'occurredAt',
    cardScheme: "occurredAt",
    description: "description",
};

const CreateIncidentForm = (props) => {
    const {onSubmit} = props;
    const classes = useStyles();
    const {register, unregister, handleSubmit, errors, setValue, formState, control} = useForm({
        defaultValues: {
            occurredAt: new Date(),
        },
    });
    const {isSubmitting, isDirty} = formState;

    const dispatch = useDispatch();
    // const watchConnectionTYpe = watch(formDef.dataPoint);

    const onSubmitForm = (data) => {

        data.occurredAt = moment.utc(data.occurredAt).format("YYYY-MM-DDTHH:mm:ss");
        dispatch(createIncident(data));


        if (onSubmit) {
            onSubmit(data);
        }
    };

    useEffect(() => {
        register({name: formDef.dataPoint}, {required: "Type is required"});
        register({name: formDef.visibility}, {required: "Visibility is required"});
        register({name: formDef.occurredAt}, {required: "Occurred at is required"});
        register({name: formDef.cardScheme});

        return () => {
            unregister(formDef.dataPoint);
            unregister(formDef.visibility);
            unregister(formDef.cardScheme);
            unregister(formDef.occurredAt);
        }

    }, [register, unregister]);

    const onIncidentTypeChange = (e, data) => {
        setValue(formDef.dataPoint, data.value);
    };

    const onVisibilityChange = (e, data) => {
        setValue(formDef.visibility, data.value);
    };


    // const onCardSchemeChange = (e, data) => {
    //     setValue(formDef.cardScheme, data.value);
    // };

    return (
        <div>
            <Form as={"form"} onSubmit={handleSubmit(onSubmitForm)}>
                <Form.Field
                    control={Select}
                    onChange={onIncidentTypeChange}
                    options={Object.values({AS: DATA_POINTS.AS})}
                    label={{
                        children: <label>Connection type <span className={classes.required}>*</span></label>,
                        htmlFor: 'form-select-control-tenant-type'
                    }}
                    placeholder='Type of incident'
                    search
                    searchInput={{id: 'form-select-control-tenant-type'}}
                />
                <ErrorMessage errors={errors} name={formDef.dataPoint}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>

                <Form.Field
                    control={Select}
                    onChange={onVisibilityChange}
                    options={Object.values(INCIDENT_VISIBILITIES)}
                    label={{
                        children: <label>Visibility <span className={classes.required}>*</span>
                            <Tooltip title={
                                <Typography variant={"h5"}>
                                    The visibility of the incident<br/>
                                    ● Published - Shows up on MyAccount uptime page.<br/>
                                    ● Major/Minor - Internal use only.
                                </Typography>}>
                                <Icon name={"info circle"}/>
                            </Tooltip></label>,
                        htmlFor: 'form-select-control-tenant-type'
                    }}
                    placeholder='Visibility of incident'
                    search
                    searchInput={{id: 'form-select-control-tenant-type'}}
                />
                <ErrorMessage errors={errors} name={formDef.visibility}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                {/*{(watchConnectionTYpe === CONNECTION_TYPES.TEST_LABS_DS.value*/}
                {/*    || watchConnectionTYpe === CONNECTION_TYPES.PROD_DS.value*/}
                {/*    || watchConnectionTYpe === CONNECTION_TYPES.TEST_LABS_DS_PORT.value*/}
                {/*    || watchConnectionTYpe === CONNECTION_TYPES.PROD_DS_PORT.value) &&*/}
                {/*<>*/}
                {/*    <Form.Field*/}
                {/*        control={Select}*/}
                {/*        onChange={onCardSchemeChange}*/}
                {/*        options={getDirectoryServers(watchConnectionTYpe === CONNECTION_TYPES.TEST_LABS_DS.value*/}
                {/*            || watchConnectionTYpe === CONNECTION_TYPES.TEST_LABS_DS_PORT.value)}*/}
                {/*        label={{*/}
                {/*            children: <label>DS <span className={classes.required}>*</span></label>,*/}
                {/*            htmlFor: 'form-select-control-tenant-type'*/}
                {/*        }}*/}
                {/*        placeholder='Directory server which had an issue'*/}
                {/*        search*/}
                {/*        searchInput={{id: 'form-select-control-tenant-type'}}*/}
                {/*    />*/}
                {/*    <ErrorMessage errors={errors} name={formDef.cardScheme}>*/}
                {/*        {({message}) => <p className={classes.errorMessage}>{message}</p>}*/}
                {/*    </ErrorMessage>*/}
                {/*</>}*/}
                <Form.Field>
                    <label>Occurred at <span className={classes.required}>*</span></label>
                    <Controller
                        name={formDef.occurredAt}
                        control={control}
                        render={({onChange, value}) => (
                            <DateTimePicker
                                // label={"Occurred "}
                                disableFuture
                                inputVariant="outlined"
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Field>
                <ErrorMessage errors={errors} name={formDef.occurredAt}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                <Form.Field>
                    <label>Description</label>
                    <textarea placeholder='Notes about the incident' name={formDef.description} cols="40" rows="5"
                              ref={register({
                                  required: true,
                                  minLength: {
                                      value: 5,
                                      message: "Should be longer than 5 characters",
                                  },
                                  maxLength: {
                                      value: 200,
                                      message: "Cannot be longer than 200 characters",
                                  },
                              })}/>
                </Form.Field>
                <ErrorMessage errors={errors} name={formDef.description}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>

                <Button type={"submit"} primary disabled={isSubmitting || !isDirty}>
                    Create
                </Button>
            </Form>
        </div>
    );
};

CreateIncidentForm.propTypes = {
    onSubmit: PropTypes.func,
}

export default CreateIncidentForm;
