import React, {useEffect, useState} from 'react';
import {Button, Form, Select} from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";
import {MONTH_OPTIONS, QUARTER_OPTIONS, TIME_RANGE_TYPES} from "../../constants/datetime";
import {Controller, useForm} from "react-hook-form";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
import PropTypes from "prop-types";
import {ErrorMessage} from "@hookform/error-message";
import {createUseStyles} from "react-jss";
import {globalStyles} from "../styles";
import Box from "@material-ui/core/Box";


const useStyles = createUseStyles(({
    errorMessage: {
        color: "red",
    },
    warningMessage: {
        color: "#ff9800"
    },
    formField: {
        marginLeft: 10,
        marginTop: 10,
        minWidth: 300,
        maxWidth: 500,
        marginBottom: 0,
    },
    filterContainer: {
        padding: 16
    },
    submitBtn: {
        marginTop: 10,
        marginLeft: 10,
    },
    ...globalStyles
}));


const formDef = {
    startDate: "startDate",
    endDate: "endDate",
};

const defaultValues = {
    timeRangeType: '',
    startDate: null,
    endDate: null,
};

const TimeRangeSelector = (props) => {
    const classes = useStyles();
    const {onSubmit, buttonText, defaultStartDate, defaultEndDate} = props;

    const {handleSubmit, control, errors, setValue, reset} = useForm({
        defaultValues: {
            ...defaultValues,
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        }
    });

    const [timeRangeType, setTimeRangeType] = useState(TIME_RANGE_TYPES.CUSTOM.value);

    const [yearSelected, setYearSelected] = useState(null);
    const [monthSelected, setMonthSelected] = useState(null);
    const [quaterSelected, setQuaterSelected] = useState(null);


    const [yearOptions, setYearOptions] = useState([]);


    useEffect(() => {

        const options = [];

        const currentYear = moment().year();

        for (let i = currentYear; i >= currentYear - 10; i--) {
            options.push({
                key: i,
                value: i,
                text: i,
            })
        }
        setYearOptions(options);
    }, []);

    const onTimeRangeTypeChanged = (e, data) => {

        setTimeRangeType(data.value);

        // reset the filters
        setYearSelected(null);
        setMonthSelected(null);
        setQuaterSelected(null);
        reset();
    };


    const onYearChanged = (e, data) => {
        if (timeRangeType === TIME_RANGE_TYPES.MONTHLY.value && monthSelected) {
            setValue(formDef.startDate, moment().year(data.value).month(monthSelected).startOf("month"));
            setValue(formDef.endDate, moment().year(data.value).month(monthSelected).endOf("month"))
        } else if (timeRangeType === TIME_RANGE_TYPES.YEARLY.value) {
            setValue(formDef.startDate, moment().year(data.value).startOf("year"));
            setValue(formDef.endDate, moment().year(data.value).endOf("year"))
        } else if (timeRangeType === TIME_RANGE_TYPES.QUARTERLY.value && quaterSelected) {
            setValue(formDef.startDate, moment().year(data.value).quarter(quaterSelected).startOf("quarter"));
            setValue(formDef.endDate, moment().year(data.value).quarter(quaterSelected).endOf("quarter"))
        }

        setYearSelected(data.value);
    };

    const onMonthChanged = (e, data) => {
        if (yearSelected) {
            setValue(formDef.startDate, moment().year(yearSelected).month(data.value).startOf("month"));
            setValue(formDef.endDate, moment().year(yearSelected).month(data.value).endOf("month"))
        }
        setMonthSelected(data.value);
    };

    const onQuarterChanged = (e, data) => {
        if (yearSelected) {
            setValue(formDef.startDate, moment().year(yearSelected).quarter(data.value).startOf("quarter"));
            setValue(formDef.endDate, moment().year(yearSelected).quarter(data.value).endOf("quarter"))
        }
        setQuaterSelected(data.value);
    };


    return (
        <div>
            <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="center">

                    <Grid xs={12} sm={12}>
                        <div className={classes.formField}>
                            <Form.Field
                                control={Select}
                                value={timeRangeType}
                                onChange={onTimeRangeTypeChanged}
                                options={Object.values(TIME_RANGE_TYPES)}
                                label={{children: 'Time range', htmlFor: 'form-select-control-time-range'}}
                                placeholder='Time range'
                                search
                                searchInput={{id: 'form-select-control-time-range'}}
                            />
                        </div>
                    </Grid>

                    <Grid xs={12} sm={12}>

                        <Box display={"flex"}>

                            {(timeRangeType === TIME_RANGE_TYPES.MONTHLY.value
                                || timeRangeType === TIME_RANGE_TYPES.QUARTERLY.value
                                || timeRangeType === TIME_RANGE_TYPES.YEARLY.value) &&
                            <div className={classes.formField}>
                                <Form.Field
                                    control={Select}
                                    value={yearSelected}
                                    onChange={onYearChanged}
                                    options={yearOptions}
                                    label={{children: 'Year', htmlFor: 'form-select-control-year'}}
                                    placeholder='Year'
                                    search
                                    searchInput={{id: 'form-select-control-year'}}
                                />
                            </div>}
                            {(timeRangeType === TIME_RANGE_TYPES.MONTHLY.value) &&
                            <div className={classes.formField}>
                                <Form.Field
                                    control={Select}
                                    value={monthSelected}
                                    onChange={onMonthChanged}
                                    options={Object.values(MONTH_OPTIONS)}
                                    label={{children: 'Month', htmlFor: 'form-select-control-month'}}
                                    placeholder='Month'
                                    search
                                    searchInput={{id: 'form-select-control-month'}}
                                />
                            </div>
                            }
                            {(timeRangeType === TIME_RANGE_TYPES.QUARTERLY.value) &&
                            <div className={classes.formField}>
                                <Form.Field
                                    control={Select}
                                    value={onQuarterChanged}
                                    onChange={onQuarterChanged}
                                    options={Object.values(QUARTER_OPTIONS)}
                                    label={{children: 'Quarter', htmlFor: 'form-select-control-quarter'}}
                                    placeholder='Quarter'
                                    search
                                    searchInput={{id: 'form-select-control-quarter'}}
                                />
                            </div>
                            }
                        </Box>
                    </Grid>


                    <Grid xs={12} sm={12}>

                        <Box display={"flex"}>
                            <div className={classes.formField}>
                                <Form.Field>
                                    <label>Start Date</label>
                                    <Controller
                                        name={formDef.startDate}
                                        control={control}
                                        rules={{required: "required"}}
                                        render={({onChange, value}) => (
                                            <DatePicker
                                                // label={"Occurred "}
                                                // disableFuture
                                                format={"LL"}
                                                fullWidth
                                                disabled={timeRangeType !== TIME_RANGE_TYPES.CUSTOM.value}
                                                inputVariant="outlined"
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Field>
                                <ErrorMessage errors={errors} name={formDef.startDate}
                                              render={({message}) => <span
                                                  className={classes.errorMessage}>{message}</span>}>
                                </ErrorMessage>
                            </div>
                            <div className={classes.formField}>
                                <Form.Field>
                                    <label>End Date</label>
                                    <Controller
                                        name={formDef.endDate}
                                        control={control}
                                        rules={{required: "required"}}
                                        render={({onChange, value}) => (
                                            <DatePicker
                                                // label={"Occurred "}
                                                // disableFuture
                                                format={"LL"}
                                                fullWidth
                                                disabled={timeRangeType !== TIME_RANGE_TYPES.CUSTOM.value}
                                                inputVariant="outlined"
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Field>
                                <ErrorMessage errors={errors} name={formDef.endDate} render={({message}) => <span
                                    className={classes.errorMessage}>{message}</span>}/>
                            </div>
                        </Box>
                    </Grid>
                </Grid>

                <div className={classes.submitBtn}>
                    <Button positive success type={"submit"}>
                        {buttonText}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

TimeRangeSelector.defaultProps = {
    buttonText: "Filter"
};

TimeRangeSelector.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
    defaultStartDate: PropTypes.object,
    defaultEndDate: PropTypes.object,

};

export default TimeRangeSelector;
