import {LOGS_FILTER_CHANGED, TENANTS_FILTER_CHANGED} from "../actions/actionTypes";
import moment from "moment";

const initialSettings = {
    tenantsFilter: {
        tenantName: null,
        hostName: null,
        tenantTypes: [],
        subscriptionStatuses: [],
        onboardTimeFrom: [],
        onboardTimeTo: [],
    },
    logsFilter: {
        transId: null,
        limit: 0,
        dateTimeFrom: moment().add(-1, "day").toDate(),
        dateTimeTo: moment().toDate(),
        useNewCluster: true
    }
};

export const filters = (state = initialSettings, action) => {
    switch (action.type) {

        case TENANTS_FILTER_CHANGED: {
            return {
                ...state,
                tenantsFilter: action.filters,
            }
        }
        case LOGS_FILTER_CHANGED: {
            return {
                ...state,
                logsFilter: action.filters,
            }
        }
        default:
            return state;
    }
};
