export const arrayToObject = (array, keyField, sortKey) => {

    if (Array.isArray(array)) {
        array.sort(((a, b) => a[sortKey] > b[sortKey]));
        return array.reduce((obj, item) => {
            obj[item[keyField]] = item;
            return obj
        }, {});
    }
    return array;
};