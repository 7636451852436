export const PERMISSIONS = {
    AS_SAAS_READ_LOGS: {
        value: "as-saas/READ_LOGS",
        label: "Allows to access Saas logs"
    },
    AS_SAAS_READ_INCIDENTS: {value: "as-saas/READ_INCIDENTS", label: "Allows to access Saas incident page"},
    AS_SAAS_WRITE_INCIDENTS: {value: "as-saas/WRITE_INCIDENTS", label: "Allows to edit Saas incident"},
    AS_SAAS_READ_TENANTS: {value: "as-saas/READ_TENANTS", label: "Allows read ActiveServer tenants"},
    AS_SAAS_WRITE_TENANTS: {value: "as-saas/WRITE_TENANTS", label: "Allows to write ActiveServer tenants"},
    AS_SAAS_READ_DETAILED_UPTIME: {
        value: "as-saas/READ_DETAILED_UPTIME",
        label: "Allows to access ActiveServer Saas detailed uptime information such as S3 and KMS statuses"
    },
    MY_ACCOUNT_READ_USERS: {value: "myaccount/READ_USERS", label: "Allows to access users page"},
    MY_ACCOUNT_WRITE_USERS: {value: "myaccount/WRITE_USERS", label: "Allows to edit MyAccount users"},
    MY_ACCOUNT_READ_ORGS: {value: "myaccount/READ_ORGS", label: "Allows read organisations"},
    MY_ACCOUNT_WRITE_ORGS: {value: "myaccount/WRITE_ORGS", label: "Allows create/edit MyAccount organisations"},
    AS_READ_RELEASE: {value: "as/READ_RELEASE", label: "Allows read ActiveServer release page"},
    AS_WRITE_RELEASE: {value: "as/WRITE_RELEASE", label: "Allows publish packages to ActiveServer release page"},
    AS_READ_LICENSE: {value: "as/READ_LICENCE", label: "Allows to access ActiveServer license/usage information"},
    AS_WRITE_LICENSE: {value: "sdk/WRITE_LICENSE", label: "Allows to edit ActiveServer license/usage information"},
    AA_READ_RELEASE: {value: "aa/READ_RELEASE", label: "Allows read ActiveAccess release page"},
    AA_WRITE_RELEASE: {value: "aa/WRITE_RELEASE", label: "Allows publish packages to ActiveAccess release page"},
    AME_READ_RELEASE: {value: "ame/READ_RELEASE", label: "Allows read ActiveMerchant Enterprise release page"},
    AME_WRITE_RELEASE: {
        value: "ame/WRITE_RELEASE",
        label: "Allows publish packages to ActiveMerchant Enterprise release page"
    },
    SDK_READ_LICENSE: {value: "sdk/READ_LICENCE", label: "Allows to access SDK license/usage information"},
    SDK_WRITE_LICENSE: {value: "sdk/WRITE_LICENSE", label: "Allows to edit SDK license/usage information"},
};

export const getPemisssion = (id) => {
    return Object.values(PERMISSIONS).find(d => d.value === id);
};

export const ROLES = {
    ROLE_ADMIN: {
        value: "ROLE_ADMIN",
    }
};
