import React from 'react';
import {connect} from "react-redux";
import {importSDKSecret} from "../../../actions/licenses";
import {reduxForm} from "redux-form/es";
import {Button, Divider, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {Field} from "redux-form";
import {RenderedInputField} from "../../form/formComponents";

const SdkSecretForm = (props) => {

    const {error, pristine, handleSubmit, submitting} = props;

    return (
        <div>
            <Segment attached={"bottom"}>
                <Header as="h3">Import new</Header>

                <Message>
                    <Message.Header>Importing new salt</Message.Header>
                    <p>Please generate a hexadecimal string from <a href={"https://www.random.org/bytes/"}>here</a>.
                        Importing new salt will use the new salt for new wrapping key generation.</p>
                </Message>
                <Form onSubmit={handleSubmit(props.importSDKSecret)}>
                    <Grid>
                        {/*<Grid.Column width={6}>*/}
                        {/*    <Field name="productCode" label="Product" component={RenderedDropDown} autoFocus*/}
                        {/*           options={options}/>*/}
                        {/*</Grid.Column>*/}
                        <Grid.Column width={12}>
                            <Field name="salt"
                                   placeholder="Paste in the hexadecimal e.g. e8 f6 e2 e2 90 90 da fd e3 44 cb 47 1a 6b 34 c0"
                                   component={RenderedInputField}/>
                        </Grid.Column>
                    </Grid>
                    {error && <Message negative>
                        <Message.Header>Import failed</Message.Header>
                        <p>{error}</p>
                    </Message>}
                    <Divider/>
                    <Button type="submit" primary disabled={pristine || submitting}>Import</Button>
                </Form>
            </Segment>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    importSDKSecret: (values) => dispatch(importSDKSecret(values)),
});

const SdkSecretView = reduxForm({
    form: "sdkSecretForm",
    enableReinitialize: true,
    validate: () => {

    },
})(SdkSecretForm);

export default connect(mapStateToProps, mapDispatchToProps)(SdkSecretView);