import React from 'react';
import './App.css';
import {Router} from "react-router-dom";
import {Navigation} from "./ui/Navigation";
import {Loading} from "./ui/Loading";
import 'semantic-ui-css/semantic.min.css'
import {useSelector} from "react-redux";
import history from "./routes/history"


const App = props => {
    const {user} = useSelector(state => state.session);

    if (user) {

        return (<Router history={history}>
            <div>
                <Navigation/>
            </div>
        </Router>)

    } else {
        return <Loading/>

    }
};

export default App;

