import React from 'react';
import Box from "@material-ui/core/Box";
import {Header, Icon} from "semantic-ui-react";


export const Dashboard = () => (
    <div>
        <Box display={"flex"} justifyContent={"center"}>
            <Box display={"block"}>
                <Header as='h2' icon>
                    <Icon name='settings'/>
                    Welcome to GP Admin
                    <Header.Subheader>
                        Management dashboard for GPayments
                    </Header.Subheader>
                </Header>
            </Box>
        </Box>
    </div>
)
