import React, {useState} from 'react';
import RolesList from "./RolesList";
import {Grid, Icon, Menu} from "semantic-ui-react";
import PermissionSettings from "./PermissionSettings";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import SimpleModal from "../../components/SimpleModal";
import CreateRoleForm from "./CreateRoleForm";
import {useDispatch} from "react-redux";
import {createRole} from "../../../actions/auth";

const SettingsMain = () => {
    const [openDialog, setOpenDialog] = useState(false);

    const dispatch = useDispatch();
    const onRoleCreate = (values) => {

        dispatch(createRole(values));
        setOpenDialog(false);
    };

    return (
        <div style={{minHeight: "100vh"}}>
            <Grid padded={true}>

                <Grid.Column widescreen={"10"} mobile={"16"} tablet={"16"}>
                    <Menu pointing attached="top">

                        <Menu.Menu position={"right"}>
                            <Menu.Item>
                                <Button circular animated='vertical' onClick={() => setOpenDialog(true)}>
                                    <Button.Content hidden>
                                        <Icon name='plane'/>
                                    </Button.Content>
                                    <Button.Content visible>
                                        <Icon name='plus'/>
                                    </Button.Content>
                                </Button>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <RolesList/>
                </Grid.Column>
                <Grid.Column widescreen={"6"} mobile={"16"} tablet={"16"}>
                    <PermissionSettings/>
                </Grid.Column>
            </Grid>
            <SimpleModal open={openDialog} onClose={() => setOpenDialog(false)}>
                <Grid padded>
                    <Grid.Column largeScreen={13} mobile={16}>
                        <CreateRoleForm onSubmit={onRoleCreate}/>
                    </Grid.Column>
                </Grid>
            </SimpleModal>
        </div>
    );
};

export default SettingsMain;
