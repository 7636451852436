import {Button, Divider, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {InputField, SelectField} from "react-semantic-redux-form";
import {connect} from "react-redux";
import React from "react";
import {updateServerDetails} from "../../../actions/licenses";
import {SELECT_STOP_DATE} from "../../../actions/actionTypes.js";

const licenseActionList = [
    {key: "NONE", value: "NONE", text: "No Action"},
    {key: "WARN", value: "WARN", text: "Give warning message"},
    {key: "RESUME", value: "RESUME", text: "Resume the instance"},
    {key: "STOP", value: "STOP", text: "Stop the instance (Disabled, NoOp)"},
];


function isValidDate(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Invalid format
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
}

const InstanceDetailsView = (props) => {

    const {error, server, pristine, handleSubmit, submitting, reset, ai} = props;

    return <div>
        <Segment padded as="form" onSubmit={handleSubmit(props.updateServerDetails)}>

            <Grid>
                <Grid.Column largeScreen={8} mobile={16}>
                    <Header as="h3">Server: {server && server.name}</Header>
                    <Divider/>
                    <Form as="div">
                        <Field name="url" label="Url" placeholder="External URL" component={InputField} autoFocus/>
                        <Field name="name" label="Server Name" placeholder="Name of the server instance"
                               component={InputField}
                               autoFocus/>
                        <Field name="ai" label="License Action" placeholder="License Action" component={SelectField}
                               options={licenseActionList}/>

                        {ai && ai === "WARN" &&
                        <Field name="aiStopDate" label="Stop Date" placeholder="YYYY-MM-DD" component={InputField}
                               autoFocus/>
                        }


                        <Divider/>
                        <Button type='submit' primary disabled={pristine || submitting}>Submit</Button>
                        <Button disabled={pristine || submitting} onClick={reset}>Undo Changes</Button>

                        {error && <Message negative>
                            <Message.Header>Update failed</Message.Header>
                            <p>{error}</p>
                        </Message>}
                    </Form>
                </Grid.Column>
            </Grid>

        </Segment>
    </div>;
};

const formName = "instanceForm";

const ServerDetails = reduxForm({
    form: formName,
    validate: (values) => {
        const errors = {};
        if (values.aiStopDate && !isValidDate(values.aiStopDate)) {
            errors.aiStopDate = "It must be in the format YYYY-MM-DD";
        }
        return errors;
    },
    enableReinitialize: true
})(InstanceDetailsView);

const selector = formValueSelector(formName);
const mapStateToProps = (state) => {

    // select the current ai value
    const ai = selector(state, 'ai');
    return {
        ai,
        server: state.licenses.currServer,
        initialValues: state.licenses.currServer,
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateServerDetails: (values) => dispatch(updateServerDetails(values)),
    handleDateChange: (date) => dispatch({type: SELECT_STOP_DATE, date}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServerDetails);

