import {Config, del, get, post, put} from "./http";
import {
    DOWNLOAD_HISTORY_READY,
    INCIDENT_CREATED,
    INCIDENT_LOG_ADDED,
    INCIDENT_LOG_DELETED,
    INCIDENT_LOG_UPDATED,
    INCIDENT_LOGS_READY,
    INCIDENT_UPDATED,
    INCIDENTS_READY,
    LOG_QUERY_ID_CHANGED,
    LOGS_READY,
    RELEASE_SUMMARY_SAVED,
    SET_VERSION_SUMMARY_MODE,
    SET_VERSION_TO_DISPLAY,
    UPDATE_PACKAGE_SUMMARY,
    VERSION_FILE_INFO_READY,
    VERSION_PROMOTED,
    VERSION_PULLBACK,
    VERSIONS_READY
} from "./actionTypes";
import {showNotification} from "./utils";
import {INCIDENT_STATUSES} from "../constants/saas";
import moment from "moment";

const versionsReady = (product, versions) => {
    return {
        type: VERSIONS_READY,
        product,
        versions,
    }
};

const filesReady = (product, version, files) => {
    return {
        type: VERSION_FILE_INFO_READY,
        product,
        version,
        files
    }
};

export const switchProduct = (product) => {

    console.log('switching product to:', product);

    return (dispatch, getState) => {
        get(getState().session, Config.releaseApiServerUrl + "/admin/" + product + "/versions")
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('fetch version', body);

            const data = body.data;

            dispatch(versionsReady(product, data));

            if (data && data.length > 0) {
                //get the files
                dispatch(selectVersion(product, data[0].version))
            }

        }).catch((error) => {

            console.log("error", error)


        })


    }
};

export const selectVersion = (product, version) => {

    return (dispatch, getState) => {
        get(getState().session, Config.releaseApiServerUrl + "/admin/" + product + "/" + version + "/files")
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('fetch files', body);

            const data = body.data;

            dispatch({type: SET_VERSION_TO_DISPLAY, product, version});

            dispatch(filesReady(product, version, data));

        }).catch((error) => {

            console.log("error", error)


        })
    }
};

export const promoteVersion = (action, version) => {


    return (dispatch, getState) => {

        const {currentSelectedProduct} = getState().releases;

        post(getState().session, `${Config.releaseApiServerUrl}/admin/${currentSelectedProduct}/${version.version}/${action}`, version)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('version promoted', body);

            const data = body.data;

            let actionType = VERSION_PROMOTED;
            if (action === 'pullback') {
                actionType = VERSION_PULLBACK;
            }

            dispatch({type: actionType, product: currentSelectedProduct, data});


        }).catch((error) => {

            console.log("error", error)


        })


    }


};

export const updateCurrentVersionSummary = (values) => {


    return (dispatch, getState) => {

        const product = getState().releases.currentSelectedProduct;
        const version = getState().releases.currentSelectedVersion.version;

        post(getState().session, `${Config.releaseApiServerUrl}/admin/${product}/${version}/summary`,
            {
                content: values.summary
            })
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('version release summary updated', body);

            const data = body.data;

            dispatch({type: RELEASE_SUMMARY_SAVED, product, data});

            dispatch(showNotification("Saved Changes", `You have successfully updated ${version} version summary!`));

        }).catch((error) => {

            console.log("error", error)


        })


    }


};


export const setVersionSummaryMode = (product, version, mode) => {

    return {
        type: SET_VERSION_SUMMARY_MODE,
        mode,
    }
};


export const updatePackageSummary = (values) => {
    return (dispatch, getState) => {

        let metadata = getState().releases.currentSelectedPackage;


        metadata.summary = values.summary;

        post(getState().session, `${Config.releaseApiServerUrl}/admin/artifact/${metadata.id}`, metadata)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('package summary updated', body);

            const data = body.data;

            dispatch({type: UPDATE_PACKAGE_SUMMARY, data});

            dispatch(showNotification("Saved Changes", `You have successfully updated ${metadata.fileName} package summary!`));
        }).catch((error) => {

            console.log("error", error)


        })

    }
};

export const getDownloadHistory = (page = 0, pageSize = 10) => {
    return (dispatch, getState) => {
        const {session} = getState();
        const {currentSelectedPackage} = getState().releases;

        return get(session, `${Config.releaseApiServerUrl}/admin/artifacts/${currentSelectedPackage.id}/downloads?page=${page}&size=${pageSize}&sort=downloadDate,desc`)
            .then((response) => {
                console.log(response)
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then(body => {
                const data = body.data;

                dispatch({type: DOWNLOAD_HISTORY_READY, history: data})
            })
    }
};


export const createIncident = values => {
    return (dispatch, getState) => {
        return post(getState().session, `${Config.releaseApiServerUrl}/incidents`, values)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {
                const data = body.data;

                dispatch({type: INCIDENT_CREATED, incident: data});

                // refresh the incident logs
                dispatch(getIncidentLogs(data.incidentID));

                dispatch(showNotification("Saved Changes", `You have successfully created an new incident!`));
            }).catch((error) => {

                console.log("error", error);
                dispatch(showNotification("Failed", "Failed to create a new incident!", "error"));
            })
    }
};


export const getLogQueryId = ({useNewCluster, transId, limit, dateTimeFrom, dateTimeTo}) => {
    return (dispatch, getState) => {
        const {session} = getState();

        let url = `${Config.releaseApiServerUrl}/admin/devops/as/logs`;

        return post(session, url, {
            transId: transId,
            limit: limit,
            useNewCluster: useNewCluster,
            dateTimeFrom: moment(dateTimeFrom).toISOString(),
            dateTimeTo: moment(dateTimeTo).toISOString(),
        })
            .then((response) => {
                console.log(response);
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then(body => {
                const data = body.data;

                dispatch({type: LOG_QUERY_ID_CHANGED, queryId: data.queryId, useNewCluster: useNewCluster});
            })
    }
};

export const getLogs = (queryId, useNewCluster) => {
    return (dispatch, getState) => {

        return get(getState().session, `${Config.releaseApiServerUrl}/admin/devops/as/logs?queryId=${queryId}&useNewCluster=${useNewCluster}`)
            .then((response) => {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then(body => {
                const data = body.data;

                dispatch({type: LOGS_READY, data: data});
            })
    }
};


export const updateIncident = (incidentID, values) => {
    return (dispatch, getState) => {
        return put(getState().session, `${Config.releaseApiServerUrl}/incidents/${incidentID}`, values)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {
                const data = body.data;

                dispatch({type: INCIDENT_UPDATED, incident: data});

                dispatch(showNotification("Saved Changes", `You have successfully updated an incident!`));
            }).catch((error) => {

                console.log("error", error);
                dispatch(showNotification("Failed", "Failed to update incident!", "error"));
            })
    }
};

export const getIncidents = () => {
    return (dispatch, getState) => {
        return get(getState().session, `${Config.releaseApiServerUrl}/incidents`)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {
                const data = body.data;

                dispatch({type: INCIDENTS_READY, incidents: data});
            }).catch((error) => {
                console.log("error", error);
            })

    }
};


export const getIncidentLogs = (incidentId) => {
    return (dispatch, getState) => {
        return get(getState().session, `${Config.releaseApiServerUrl}/incidents/${incidentId}/logs`)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {
                const data = body.data;

                dispatch({type: INCIDENT_LOGS_READY, logs: data});
            }).catch((error) => {
                console.log("error", error);
                dispatch({type: INCIDENT_LOGS_READY, logs: []});
            })
    }
};


export const createIncidentLog = (incidentId, values) => {
    return (dispatch, getState) => {
        return post(getState().session, `${Config.releaseApiServerUrl}/incidents/${incidentId}/logs`, values)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {
                const data = body.data;

                dispatch({type: INCIDENT_LOG_ADDED, log: data});

                // refresh the incident if the incident status is resolved
                if (values.incidentStatus === INCIDENT_STATUSES.RESOLVED.value) {
                    dispatch(getIncidents());
                }

                dispatch(showNotification("Saved Changes", `You have successfully created an new incident status!`));

            }).catch((error) => {
                console.log("error", error);

                dispatch(showNotification("Failed", "Failed to create incident status!", "error"));
            })
    }
};

export const updateIncidentLog = (incidentId, logId, values) => {
    return (dispatch, getState) => {
        return put(getState().session, `${Config.releaseApiServerUrl}/incidents/${incidentId}/logs/${logId}`, values)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {
                const data = body.data;

                dispatch({type: INCIDENT_LOG_UPDATED, log: data});

                dispatch(showNotification("Saved Changes", `You have successfully updated an new incident status!`));

            }).catch((error) => {
                console.log("error", error);

                dispatch(showNotification("Failed", "Failed to update incident status!", "error"));
            })
    }
};

export const deleteIncidentLog = (incidentId, logId) => {
    return (dispatch, getState) => {
        return del(getState().session, `${Config.releaseApiServerUrl}/incidents/${incidentId}/logs/${logId}`)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

                dispatch({type: INCIDENT_LOG_DELETED, logId: logId});

                dispatch(showNotification("Saved Changes", `You have successfully deleted an new incident status!`));

            }).catch((error) => {
                console.log("error", error);

                dispatch(showNotification("Failed", "Failed to delete incident status!", "error"));
            })
    }
};
