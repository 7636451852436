import {
    CURRENT_INCIDENT_READY,
    DOWNLOAD_HISTORY_READY,
    INCIDENT_CREATED,
    INCIDENT_LOG_ADDED,
    INCIDENT_LOG_DELETED,
    INCIDENT_LOG_UPDATED,
    INCIDENT_LOGS_READY,
    INCIDENT_UPDATED,
    INCIDENTS_READY,
    LOG_QUERY_ID_CHANGED,
    LOGS_READY,
    OPEN_PROMOTE_PULLBACK_MODAL,
    RELEASE_SUMMARY_SAVED,
    SELECT_PACKAGE,
    SET_VERSION_SUMMARY_MODE,
    SET_VERSION_TO_DISPLAY,
    VERSION_FILE_INFO_READY,
    VERSION_PROMOTED,
    VERSION_PULLBACK,
    VERSIONS_READY
} from '../actions/actionTypes';
import {ArrayToObject} from "../actions/utils";


const initialSettings = {
    currentSelectedProduct: null,
    currentSelectedVersion: null,
    currentSelectedVersionSummaryMode: 'display',
    currentSelectedVersionSummaryPreview: "",
    currentSelectedPackage: null,
    versionList: {},
    files: {},
    fetched: {},

    promotePullbackModal: {
        open: false,
        type: "promote"
    },
    logs: null,
    logQueryId: null,
    logQueryStatus: null,
    incidents: null,
    currentIncident: null,
    incidentLogs: null,
};


export const releases = (state = initialSettings, action) => {
    switch (action.type) {
        case VERSIONS_READY:
            return {
                ...state,
                versionList: {
                    ...state.versionList,
                    [action.product]: action.versions
                },
                currentSelectedProduct: action.product,
                currentSelectedVersion: action.versions[0],
                fetched: {
                    ...state.fetched,
                    [action.product]: new Date()
                }
            };
        case SET_VERSION_TO_DISPLAY:

            const currentVer = state.versionList[state.currentSelectedProduct] ? state.versionList[state.currentSelectedProduct].find(v => v.version === action.version) : null;

            return {
                ...state,
                currentSelectedVersion: currentVer,
                currentSelectedVersionSummaryPreview: currentVer ? currentVer.summary : "",
                currentSelectedPackage: undefined
            };

        case OPEN_PROMOTE_PULLBACK_MODAL: {
            return {
                ...state,
                promotePullbackModal: {
                    open: action.open,
                    type: action.modalType
                }
            }
        }

        case VERSION_PROMOTED:
        case VERSION_PULLBACK:
            let newState = state;
            if (state.currentSelectedProduct === action.product && state.currentSelectedVersion.version === action.data.version) {
                newState = {
                    ...state,
                    currentSelectedVersion: action.data
                }
            }
            return {
                ...newState,
                versionList: {
                    ...state.versionList,
                    [action.product]: state.versionList[action.product].map(v => v.version === action.data.version ? action.data : v),
                },
                promotePullbackModal: {
                    open: false
                }
            };
        case VERSION_FILE_INFO_READY:
            return {
                ...state,
                files: {

                    ...state.files,
                    [action.product]: {
                        ...state.files[action.product],
                        [action.version]: action.files
                    }

                }
            }
        case SET_VERSION_SUMMARY_MODE:
            return {
                ...state,
                currentSelectedVersionSummaryMode: action.mode,
                currentSelectedVersionSummaryPreview: action.summary
            }
        case RELEASE_SUMMARY_SAVED:
            newState = state;
            if (state.currentSelectedProduct === action.product && state.currentSelectedVersion.version === action.data.version) {
                newState = {
                    ...state,
                    currentSelectedVersion: action.data
                }
            }
            return {
                ...newState,
                versionList: {
                    ...state.versionList,
                    [action.product]: state.versionList[action.product].map(v => v.version === action.data.version ? action.data : v),
                }
            };
        case SELECT_PACKAGE:
            return {
                ...state,
                currentSelectedPackage: action.file
            };

        case DOWNLOAD_HISTORY_READY: {
            return {
                ...state,
                currentSelectedPackage: {
                    ...state.currentSelectedPackage,
                    downloadHistory: action.history,
                }
            }
        }
        case LOG_QUERY_ID_CHANGED: {
            return {
                ...state,
                logQueryId: action.queryId,
                useNewCluster: action.useNewCluster,
                logQueryStatus: null,
            }
        }
        case LOGS_READY: {

            let data = [];

            if (action.data) {
                action.data.results.forEach(d => {
                    let formatted = {};
                    d.forEach(val => {
                        if (val.field === "@timestamp") {
                            formatted = {
                                ...formatted,
                                timestamp: val.value,
                            }
                        } else if (val.field === "@message") {
                            let msg = JSON.parse(val.value);
                            formatted = {
                                ...formatted,
                                //if log field is presented, use it, otherwise try message, fallback to the value if none of the conditions are met
                                message: msg.log ? msg.log: (msg.message? msg.message: val.value),
                            }
                        } else if (val.field === "@ptr") {
                            formatted = {
                                ...formatted,
                                id: val.value,
                            }
                        }
                    });


                    data = [
                        ...data,
                        formatted
                    ]
                })
            }

            return {
                ...state,
                logs: data,
                logQueryStatus: action.data.status,
            }
        }
        case INCIDENTS_READY: {
            return {
                ...state,
                incidents: ArrayToObject(action.incidents, 'incidentID'),
            }
        }
        case INCIDENT_LOGS_READY: {
            return {
                ...state,
                incidentLogs: ArrayToObject(action.logs, 'logId'),
            }
        }
        case INCIDENT_LOG_ADDED: {

            const logId = action.log.logId;
            return {
                ...state,
                incidentLogs: {
                    [logId]: action.log,
                    ...state.incidentLogs,
                },
            }
        }

        case INCIDENT_LOG_UPDATED: {

            const logId = action.log.logId;

            let logs = {...state.incidentLogs};
            logs[logId] = action.log;

            return {
                ...state,
                incidentLogs: logs,
            }

        }
        case INCIDENT_LOG_DELETED: {
            let logs = {...state.incidentLogs};
            delete logs[action.logId];

            return {
                ...state,
                incidentLogs: logs,
            }
        }

        case INCIDENT_UPDATED: {

            if (!action.incident) {
                return {
                    ...state,
                }
            }

            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    [action.incident.incidentID]: action.incident,
                },
            }
        }
        case INCIDENT_CREATED: {
            if (!action.incident) {
                return {
                    ...state,
                }
            }

            return {
                ...state,
                incidents: {
                    [action.incident.incidentID]: action.incident,
                    ...state.incidents,
                },
                currentIncident: action.incident,
            }
        }
        case CURRENT_INCIDENT_READY: {
            return {
                ...state,
                currentIncident: action.incident,
            }
        }
        default:
            return state;
    }
};


