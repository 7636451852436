import React from 'react';

import {Checkbox, Dropdown, Form, TextArea} from "semantic-ui-react";

export const RenderedTextArea = ({
                                     input,
                                     label,
                                     meta: {touched, error},
                                     ...custom
                                 }) =>
    <Form.Field>
        <label>{label}</label>
        <TextArea
            {...input}
            {...custom}
        />
    </Form.Field>
;

export const RenderedInputField = ({
                                       input,
                                       meta: {touched, error},
                                       ...custom
                                   }) =>
    <Form.Input
        {...input}
        {...custom}
    />;


export const RenderedCheckbox = ({
                                     input,
                                     meta: {touched, error, initial},
                                     ...custom
                                 }) =>
    <Form.Field>
        <Checkbox
            defaultChecked={input.value ? true : false}


            {...input}
            {...custom} />
    </Form.Field>


export const CheckboxField = ({input, label, meta: {touched, error}, ...custom}) => ( //eslint-disable-line
    <Form.Field control={Checkbox} label={label}
                checked={input.value}
                onChange={(event, data) => {
                    console.log(data);
                    input.onChange(event, data.checked)
                }} {...custom}/>
);

export const RenderedDropDown = ({input, label, meta: {touched, error}, ...custom}) => (
    <Form.Field>
        <label>{label}</label>
        <Dropdown selection {...input}
                  value={input.value}
                  onChange={(param, data) => input.onChange(data.value)}
                  search
                  {...custom}
        />
    </Form.Field>
);

export const Multiselect = ({input, label, meta: {touched, error}, ...custom}) => (
    <Form.Field>
        <label>{label} {input.value}</label>
        <Dropdown selection {...input}
                  value={input.value}
                  onChange={(param, data) => {
                      console.log(data);

                      input.onChange(data.value)
                  }}
                  search
                  {...custom}
        />
    </Form.Field>
);


