export const DS_PROFILES = {
    prod: {
        value: "Production",
        text: "Production"
    },
    testlab: {
        value: "TestLab",
        text: "TestLab"
    },
};

export const INTERNAL_TENANT_TYPES = {
    ADMIN: {
        key: "A",
        value: "ADMIN",
        text: "Admin",
        tooltip: "SaaS Administration Tenant. This tenant can make changes to global settings.",
        dsProfile: DS_PROFILES.testlab.value,
    },
    GP: {
        key: "S",
        value: "GP",
        text: "GP",
        tooltip: "Internal Test users. This type of tenant can override the DSProfileType by the API parameter \"trans-type\".",
        dsProfile: DS_PROFILES.testlab.value,
    },
};

export const TENANT_TYPES = {
    PROD: {
        key: "P",
        value: "PROD",
        text: "Prod",
        tooltip: "Normal Tenant. Paid subscription, only allow prod transactions",
        dsProfile: DS_PROFILES.prod.value,
    },
    TRIAL: {
        key: "T",
        value: "TRIAL",
        text: "Trial",
        tooltip: "Trial Tenant. Only allows GPayments TestLabs transaction.",
        dsProfile: DS_PROFILES.testlab.value,
    },
    TEST: {
        key: "D",
        value: "TEST",
        text: "Test",
        tooltip: "Test tenant, these users are the same as TRIAL, only difference is that they usually don't have expiry date.",
        dsProfile: DS_PROFILES.testlab.value,
    }
};

export const getTenantType = (tenantType) => {
    if (INTERNAL_TENANT_TYPES[tenantType]) {
        return INTERNAL_TENANT_TYPES[tenantType];
    } else if (TENANT_TYPES[tenantType]) {
        return TENANT_TYPES[tenantType];
    }

    return null;
};

export const SUBSCRIPTION_STATUSES = {
    Subscribed: {
        key: "SUBSCRIBED",
        value: "SUBSCRIBED",
        text: "Subscribed",
    },
    Suspended: {
        key: "SUSPENDED",
        value: "SUSPENDED",
        text: "Suspended",
    },
    Cancelled: {
        key: "CANCELLED",
        value: "CANCELLED",
        text: "Cancelled",
    }
};


export const DATA_POINTS = {
    AS: {
        value: "AS",
        text: "ActiveServer",
    },
    // DB: {
    //     value: "DB",
    //     text: "Database",
    // },
    S3: {
        value: "S3",
        text: "AWS S3 Bucket",
    },
    KMS: {
        value: "KMS",
        text: "AWS KMS",
    },
    ADMIN_UI: {
        value: "ADMIN_UI",
        text: "Admin UI",
    },
    AUTH_API: {
        value: "AUTH_API",
        text: "Authentication API",
    }
    // PROD_DS: {
    //     value: "PROD_DS",
    //     text: "Production Directory Server",
    // },
    // TEST_LABS_DS: {
    //     value: "TEST_LABS_DS",
    //     text: "TestLabs Directory Server",
    // },
    // PROD_DS_PORT: {
    //     value: "PROD_DS_PORT",
    //     text: "Production Directory Server port failed",
    // },
    // TEST_LABS_DS_PORT: {
    //     value: "TEST_LABS_DS_PORT",
    //     text: "TestLabs Directory Server port failed",
    // },
    // OTHER: {
    //     value: "OTHER",
    //     text: "Other type of incidents",
    // }
};

export const INCIDENT_STATUSES = {
    INVESTIGATING: {
        value: "INVESTIGATING",
        text: "Investigating",
    },
    UPDATE: {
        value: "UPDATE",
        text: "Update"
    },
    IDENTIFIED: {
        value: "IDENTIFIED",
        text: "Identified"
    },
    RESOLVED: {
        value: "RESOLVED",
        text: "Resolved"
    },
    MONITORING: {
        value: "MONITORING",
        text: "Monitoring"
    },
};


export const INCIDENT_VISIBILITIES = {
    MINOR: {
        text: "Minor",
        value: "MINOR",
    },
    MAJOR: {
        text: "Major",
        value: "MAJOR",
    },
    PUBLISHED: {
        text: "Publish",
        value: "PUBLISHED",
    }
};


export const LOG_QUERY_STATUSES = {
    complete: {
        value: "Complete",
    }
};

export const TENANT_CAPABILITIES = {
    PAN_ENCRYPTION: {
        id: "8d610b91-664d-4053-9e44-51b4e5706fbc",
        simpleId: "PAN-ENC",
        description: "PAN Encryption"
    },
    // NO_OP: {
    //     id: "70dcd90e-7303-4c0c-a928-098292409206",
    //     simpleId: "NO_OP",
    //     description: "NO_OP, internal use only"
    // },
    AM_TO_SAAS_MIGRATION: {
        id: "aa0e6853-4b67-41dd-b8fa-20c371e09a28",
        simpleId: "AM_TO_SAAS_MIGRATION",
        description: "ActiveMerchant to SaaS migration functionality"
    },
    THREE_DS_1_0_SUPPORT: {
        id: "834e8bc5-b498-47b8-8e73-37b51744df5f",
        simpleId: "THREE_DS_1_SUPPORT",
        description: "Support 3DSecure 1.0.2 transactions"
    },
    //Regional card scheme MIR support
    MIR_SUPPORT: {
        id: "231b2461-74ce-4441-bf5b-1df99fa76708",
        simpleId: "MIR",
        description: "MIR Support"
    },


    //Regional card scheme Eftpos support
    EFTPOS_SUPPORT: {
        id: "dd44d544-9b67-4f7f-a756-e78616eb340a",

        description: "EFTPos Support",
        simpleId: "EFTPOS"
    },

    //Regional card scheme BCARD support
    BCARD_SUPPORT: {
        id: "e51caeb7-fe58-4403-87e5-d6a6994fc0d6",

        description: "BCARD Support",
        simpleId: "BCARD"
    },

    //allow the tenant (or the inhouse instance) to customise the 3DSMethod Time out time. (in seconds)
    THREEDS_METHOD_TIMEOUT_CUSTOMISATION: {
        id: "5f1ba496-74fe-4be3-893f-c25402cc3292",
        description: "3DS Method Timeout customisation",
        simpleId: "3DSMETHOD_TIMEOUT_SETTING"
    },


    //#1680
    //allow the tenant to export merchants and other necessary data for an In-House instance.
    EXPORT_MERCHANT_DATA: {
        id: "84a92fd6-1a08-4e88-b41d-8c078face133",
        description: "Allow the Tenant to export merchants and associated key information for an In-House instance (or another Saas Tenant)",
        simpleId: "EXPORT_MERCHANT_DATA"
    },

    //#2353
    DISABLE_USER_2FA: {
        id: "216eeeb4-27da-4414-abfd-4fe3ec7f209a",
        description: "Allow the Tenant to Disable User 2Fa",
        simpleId: "DISABLE_USER_2FA"
    },

    //#2669 allow the tenant to choose an alternative host name for the callback URLs, for example a1.3dsecure2.com
    //this feature should usually be premium
    USE_CUSTOMISED_BASE_DOMAIN_NAME: {
        id: "8a501dbc-a48c-41d3-8d44-a53b95b75c86",
        description: "Allow to use the first customised base domain name",
        simpleId: "USE_CUSTOMISED_BASE_DOMAIN_NAME"
    },

    //2872
    PROSTIR_SUPPORT: {
        id: "8c6b74fd-7e34-49f2-a279-511428cf3606",
        description: "Allow the Tenant to support Prostir cardscheme",
        simpleId: "PROSTIR_SUPPORT"
    },

    //2913
    PW_RESET_BY_POST: {
        id: "8fcc6f4f-b0bf-4c2f-85b3-063af57201e5",
        description: "Allow the Tenant to support password reset using http post",
        simpleId: "PW_RESET_BY_POST"
    }


}

export const getCapabilityById = (id) => {
    return Object.values(TENANT_CAPABILITIES).find(cap => cap.id === id);
}
