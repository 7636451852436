import filesize from "filesize";
import {Button, Divider, Form, Header, Icon, Menu, Segment, Table} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import ReactMarkdown from 'react-markdown';
import {SELECT_PACKAGE} from "../../../actions/actionTypes.js";
import {updatePackageSummary} from "../../../actions/release";
import {CustomPlaceholder} from "../../CustomPlaceholder";
import DownloadHistoryTable from "./DownloadHistoryTable";
import {RenderedTextArea} from "../../form/formComponents";

const PackageInfoView = (props) => {

    const [activeItem, setActiveItem] = React.useState("summary");

    const {
        fileList,
        selectedFile,
        pristine,
        handleSubmit,
        submitting,
    } = props;

    const handleItemClick = (e, {name}) => {
        setActiveItem(name);
    };

    // return placeholder if there are no artifcats
    if (!fileList || fileList.length === 0) {
        return (
            <CustomPlaceholder iconName='file outline' header='No artifacts found for this product version'/>
        )
    }

    return (
        <Segment attached="bottom" padded as="form" onSubmit={handleSubmit(props.updatePackageInfo)}>


            <Header as="h3">Package Info</Header>

            <Table striped selectable>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell>Is Doc?</Table.HeaderCell>
                        <Table.HeaderCell>Download Count</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>


                    {fileList && fileList.map(f =>

                        <Table.Row key={f.fileName} onClick={() => props.selectFile(f)}
                                   active={selectedFile && selectedFile.id === f.id}>
                            <Table.Cell>{f.fileName}</Table.Cell>
                            <Table.Cell>{filesize(f.fileSize)}</Table.Cell>
                            <Table.Cell>{f.doc ? <Icon color="green" name="check"/> : ""}</Table.Cell>
                            <Table.Cell>{f.dlCount}</Table.Cell>
                            {/*<Table.Cell>{f.summary}</Table.Cell>*/}
                        </Table.Row>
                    )}

                </Table.Body>

            </Table>

            {selectedFile &&
            <div>
                <Menu tabular attached="top">
                    <Menu.Item name='summary' active={activeItem === 'summary'}
                               onClick={handleItemClick}>Summary</Menu.Item>
                    <Menu.Item name='edit' active={activeItem === 'edit'}
                               onClick={handleItemClick}>Edit</Menu.Item>
                </Menu>
                <Segment attached="bottom">
                    {activeItem === 'summary' ?
                        <ReactMarkdown source={selectedFile && selectedFile.summary}/>
                        :
                        <Form as="div">
                            <Field name="summary" component={RenderedTextArea} autoFocus autoHeight={true}/>
                            <Divider/>
                            <Button type="submit" primary disabled={pristine || submitting}>Save
                                Changes</Button>

                        </Form>
                    }
                </Segment>

                <DownloadHistoryTable/>
            </div>

            }

        </Segment>
    );
};


const PackageInfo = reduxForm({
    form: 'packageInfoForm',
    validate: () => {
    },
    enableReinitialize: true

})(PackageInfoView);

const getFileList = (state) => {
    if (state.releases.files[state.releases.currentSelectedProduct]) {
        return state.releases.files[state.releases.currentSelectedProduct][state.releases.currentSelectedVersion.version]
    } else return null;
};

const mapStateToProps = (state) => ({
    initialValues: state.releases.currentSelectedPackage,
    fileList: getFileList(state),
    selectedFile: state.releases.currentSelectedPackage
});

const mapDispatchToProps = (dispatch) => ({
    selectFile: (file) => dispatch({type: SELECT_PACKAGE, file}),
    updatePackageInfo: (values) => dispatch(updatePackageSummary(values))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PackageInfo);
