import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Modal, Segment, Select} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {INTERNAL_TENANT_TYPES, SUBSCRIPTION_STATUSES, TENANT_TYPES} from "../../../../constants/saas";
import {useDispatch, useSelector} from "react-redux";
import {getMinimumExpiryDate, isExpiryDateAllowed, updateTenant} from "../../../../actions/licenses";
import {createUseStyles} from "react-jss";
import SimpleModal from "../../../components/SimpleModal";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import {ErrorMessage} from "@hookform/error-message";

const useStyles = createUseStyles(({
    errorMessage: {
        color: "red",
    },
    mt1: {
        marginTop: 10,
    },
    warningMessage: {
        color: "#ff9800"
    },
    noPadding: {
        padding: 0,
    }
}));

const ConfirmationModal = (props) => {
    const classes = useStyles();

    const {open, onClose, onClickNo, confirmationText, onSubmit, content} = props;

    const {register, errors, handleSubmit, formState} = useForm({});
    const {isDirty, isSubmitting} = formState;

    return (
        <SimpleModal
            header={"Are you sure?"}
            open={open}
            onClose={onClose}>
            <Modal.Content>
                {content}
                <div>
                    If you would like to proceed type "{confirmationText}" then press "Yes". <br/>
                </div>
            </Modal.Content>

            <Segment>
                <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
                    <Form.Field>
                        {/*<label>Confirm</label>*/}
                        <input placeholder={confirmationText} name={"confirmationText"} ref={register({
                            validate: value => value === confirmationText || 'Confirmation text does not match.',
                            required: "Confirmation text is required",
                        })}/>
                        <ErrorMessage errors={errors} name="confirmationText">
                            {({message}) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </Form.Field>
                    <Modal.Actions>
                        <Button color='red' onClick={onClickNo}>
                            <Icon name='remove'/> No
                        </Button>
                        <Button color='green' type={"submit"} disabled={!isDirty || isSubmitting}>
                            <Icon name='checkmark'/> Yes
                        </Button>
                    </Modal.Actions>
                </Form>
            </Segment>
        </SimpleModal>
    )
};

ConfirmationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onClickNo: PropTypes.func.isRequired,
    confirmationText: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    content: PropTypes.node,
};


const UpdateTenantForm = (props) => {
    const classes = useStyles();
    const {currentTenant} = useSelector(state => state.licenses);
    const [openDialog, setOpenDialog] = useState(false);
    // const [expiryDate, setExpiryDate] = useState(null);

    const subscriptionStatus = SUBSCRIPTION_STATUSES[currentTenant.subscriptionStatus]
        && SUBSCRIPTION_STATUSES[currentTenant.subscriptionStatus].value;

    const {register, handleSubmit, errors, setValue, watch, getValues, formState, reset, unregister} = useForm({
        defaultValues: {
            tenantName: currentTenant.name,
            tenantType: currentTenant.tenantType,
            subscriptionStatus: subscriptionStatus,
            tenantDesc: currentTenant.desc,
            expiryDate: currentTenant.expiryDate ? moment.utc(currentTenant.expiryDate).local().toDate() : null,
        },
    });

    const {isDirty, isSubmitting} = formState;

    const watchTenantType = watch("tenantType", currentTenant.tenantType);
    const watchSubscriptionStatus = watch("subscriptionStatus", subscriptionStatus);
    const watchExpiryDate = watch('expiryDate', null);

    const dispatch = useDispatch();

    useEffect(() => {
        register({name: 'tenantType'}, {required: "Tenant type is required"});
        register({name: 'subscriptionStatus'}, {required: "Subscription status is required"});
        register({name: 'expiryDate'});

        return () => {
            unregister("tenantType");
            unregister("subscriptionStatus");
            unregister("expiryDate");
        }

    }, [register, unregister]);

    useEffect(() => {
        reset({
            tenantName: currentTenant.name,
            tenantType: currentTenant.tenantType,
            subscriptionStatus: subscriptionStatus,
            tenantDesc: currentTenant.desc,
            expiryDate: currentTenant.expiryDate ? moment.utc(currentTenant.expiryDate).local().toDate() : null,
        });
    }, [currentTenant, reset, subscriptionStatus]);

    const onTenantChange = (e, data) => {
        setValue('tenantType', data.value, {shouldDirty: true});
    };

    const onSubscriptionChange = (e, data) => {
        setValue('subscriptionStatus', data.value, {shouldDirty: true});
    };

    const onSubmit = data => {

        if (currentTenant.subscriptionStatus !== SUBSCRIPTION_STATUSES.Cancelled.text
            && data.subscriptionStatus === SUBSCRIPTION_STATUSES.Cancelled.value) {
            // open confirmation dialog
            setOpenDialog(true);
        } else {

            // only TEST or TRIAL tenant can have the expiry date
            if (!isExpiryDateAllowed(data.tenantType)) {
                delete data['expiryDate'];
            }

            if (currentTenant.expiryDate && !data.expiryDate) {
                data.removeExpiryDate = true;
                data.expiryDate = null;
            }

            dispatch(updateTenant(currentTenant.id, data));
        }
    };

    return (
        <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Header>Update Tenant</Header>
            <Form.Field>
                <label>Tenant name</label>
                <input placeholder='Tenant Admin' name={"tenantName"} ref={register({
                    minLength: {
                        value: 2,
                        message: "Must be more than 2 characters"
                    },
                    maxLength: {
                        value: 100,
                        message: "Must be at less than 100 characters"
                    },
                    required: "Tenant name is required",
                })}/>
                <ErrorMessage errors={errors} name="tenantName">
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
            </Form.Field>
            <Form.Field
                disabled={currentTenant.tenantType === TENANT_TYPES.PROD.value || INTERNAL_TENANT_TYPES[watchTenantType]}
                control={Select}
                value={watchTenantType}
                onChange={onTenantChange}
                options={INTERNAL_TENANT_TYPES[watchTenantType] ? Object.values(INTERNAL_TENANT_TYPES) : Object.values(TENANT_TYPES)}
                label={{children: 'Tenant type', htmlFor: 'form-select-control-tenant-type'}}
                placeholder='Tenant type'
                search
                searchInput={{id: 'form-select-control-tenant-type'}}
            />
            <div className={classes.mt1}>
                <ErrorMessage errors={errors} name="tenantType">
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
            </div>
            <Form.Field
                disabled={currentTenant.subscriptionStatus === SUBSCRIPTION_STATUSES.Cancelled.text}
                control={Select}
                value={watchSubscriptionStatus}
                onChange={onSubscriptionChange}
                options={Object.values(SUBSCRIPTION_STATUSES)}
                label={{children: 'Subscription status', htmlFor: 'form-select-control-subscription-status'}}
                placeholder='Subscription status'
                search
                searchInput={{id: 'form-select-control-subscription-status'}}
            />
            <ErrorMessage errors={errors} name="subscriptionStatus">
                {({message}) => <p className={classes.errorMessage}>{message}</p>}
            </ErrorMessage>
            {isExpiryDateAllowed(watchTenantType) &&
            <Form.Field>
                <label>Expiry date</label>
                <DatePicker
                    todayButton="Today"
                    minDate={getMinimumExpiryDate()}
                    placeholderText="Select the expiry date of tenant"
                    selected={watchExpiryDate}
                    // locale="pt-BR"
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    onChange={date => {
                        setValue('expiryDate', date, {shouldDirty: true});
                    }}
                />
                {watchExpiryDate &&
                <p className={classes.warningMessage}>Setting the expiry date will automatically "Suspend"
                    tenant when
                    the date is reached.</p>}
            </Form.Field>}
            <Form.Field>
                <label>Description</label>
                <textarea placeholder='Notes about the tenant' name="tenantDesc" cols="40" rows="5" ref={register({
                    maxLength: {
                        value: 200,
                        message: "Cannot be longer than 200 characters",
                    },
                })}/>
            </Form.Field>
            <ErrorMessage errors={errors} name="tenantDesc">
                {({message}) => <p className={classes.errorMessage}>{message}</p>}
            </ErrorMessage>
            <Button type={"submit"} primary disabled={isSubmitting || !isDirty}>
                Update
            </Button>


            <ConfirmationModal
                content={<div>
                    You are trying to change the subscription status to be CANCELLED, are you sure you want to
                    proceed?
                    You cannot revert this change.
                    If you would like to temporary suspend the instance, change subscription status to "Suspended"
                    instead.
                </div>}
                confirmationText={"Cancel me"}
                onSubmit={() => dispatch(updateTenant(currentTenant.id, getValues()))}
                onClose={() => setOpenDialog(false)}
                onClickNo={() => setOpenDialog(false)} open={openDialog}/>
        </Form>
    );
};


export default UpdateTenantForm;
