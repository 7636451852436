import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Segment, Table} from "semantic-ui-react";
import {products} from "../../../constants/products";
import {selectLCKP} from "../../../actions/licenses";

const columnHeaders = [
    "Key ID",
    "Key type",
    "Version",
    "Product",
    "Is active?"
];

const LCKPTable = (props) => {

    const {lckps, currentLCKP} = useSelector(state => state.licenses);
    const dispatch = useDispatch();

    return (
        <Segment>
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        {columnHeaders.map((header, i) => (
                            <Table.HeaderCell key={i}>{header}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {lckps && lckps.content && lckps.content.map(lckp => (
                        <Table.Row active={currentLCKP && currentLCKP.keyId === lckp.keyId} key={lckp.keyId}
                                   onClick={() => dispatch(selectLCKP(lckp.keyId))}>
                            <Table.Cell>{lckp.keyId}</Table.Cell>
                            <Table.Cell>{lckp.keyType}</Table.Cell>
                            <Table.Cell>{lckp.version}</Table.Cell>
                            <Table.Cell>{products[lckp.productCode].name}</Table.Cell>
                            <Table.Cell>{lckp.active ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );
};


export default LCKPTable;