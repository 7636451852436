import React from 'react'
import {Container, Icon, Message} from 'semantic-ui-react'

export const Loading = () => (

    <Container text>

        <br/>
        <br/>

        <Message info>
            <Icon size="big" color="blue" name='circle notched' loading/>
            System loading ...
        </Message>

    </Container>
)


