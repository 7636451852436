import React, {Component} from 'react'
import {Button, Modal} from 'semantic-ui-react'
import {updateOrgProducts} from "../../../actions/organizations";
import {CLOSE_PRODUCT_CONFIRM_MODAL} from "../../../actions/actionTypes.js";
import {connect} from "react-redux";
import {globalStyles} from "../../styles";

class OrgProductConfirmation extends Component {
    render() {
        const {open} = this.props;

        return (
            <div>
                <Modal
                    style={globalStyles.modal}
                    open={open}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    onClose={this.props.closeModal}
                >
                    <Modal.Header>Update accessible products for organisation.</Modal.Header>
                    <Modal.Content>
                        <p>Are you sure you want to change accessible products?</p>
                        <p>Full and read permission for the products will be given to org root users and org users who
                            are under this organisation respectively.</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.props.closeModal} negative>
                            No
                        </Button>
                        <Button
                            onClick={this.props.saveProducts}
                            positive
                            labelPosition='right'
                            icon='checkmark'
                            content='Yes'
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.orgs.openProductConfirmModal
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch({type: CLOSE_PRODUCT_CONFIRM_MODAL}),
    saveProducts: () => {
        dispatch(updateOrgProducts())
    }
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgProductConfirmation);
