import React, {useState} from 'react';
import {Button, Form, Icon, Segment, Checkbox, Divider} from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";
import {useForm} from "react-hook-form";
import {createUseStyles} from "react-jss";
import red from "@material-ui/core/colors/red";
import {useDispatch} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {LOGS_FILTER_CHANGED} from "../../../../actions/actionTypes";
import {PropTypes} from "prop-types";
import {globalStyles} from "../../../styles";
import {ErrorMessage} from "@hookform/error-message";
import moment from "moment";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import "./react-datetimepicker.css";

const useStyles = createUseStyles(({
    errorMessage: {
        color: "red",
    },
    warningMessage: {
        color: "#ff9800"
    },
    required: {
        color: red[700],
    },
    formField: {
        marginLeft: 10,
        marginTop: 10,
    },
    ...globalStyles
}));

const formDefs = {
    dateTimeFrom: "dateTimeFrom",
    dateTimeTo: "dateTimeTo",
    useNewCluster: "useNewCluster",
    limit: "limit",
    transId: "transId",
}

const LogsFilter = (props) => {
    const classes = useStyles();
    const {defaultValues} = props;

    const [dateTime, onDateTimeChange] = useState([moment().add(-1, "day").toDate(), moment().toDate()]);

    const {register, handleSubmit, errors, formState, reset, setValue} = useForm({
        defaultValues: {
            dateTimeFrom: dateTime[0],
            dateTimeTo: dateTime[1],
            useNewCluster: true
        }
    });

    const {isDirty, isSubmitting} = formState;
    const dispatch = useDispatch();

    const [useNewCluster, setUseNewCluster] = useState(true)

    const onSubmit = (values) => {

        if (values.limit) {
            values.limit = parseInt(values.limit);
        }

        values.useNewCluster = useNewCluster

        dispatch({type: LOGS_FILTER_CHANGED, filters: values});
    };

    const dateTimeChanged = (dateFrom) => {

        if (dateFrom.length > 0) {
            setValue(formDefs.dateTimeFrom, dateFrom[0], {shouldDirty: true});
        }
        if (dateFrom.length > 1) {
            setValue(formDefs.dateTimeTo, dateFrom[1], {shouldDirty: true});
        }

        onDateTimeChange(dateFrom);
    }

    return (
        <Segment padded>
            <h3>Search logs</h3>


            <DateTimeRangePicker
                onChange={dateTimeChanged}
                value={dateTime}
                maxDetail={"second"}
            />


            <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}>
                    <Grid xs={12} sm={12}>
                        <div className={classes.formField}>
                            <Form.Field>

                                <input hidden placeholder='HH:mm:ss yyyy-MM-dd' name={formDefs.dateTimeFrom}
                                       ref={register}/>
                                <input hidden placeholder='HH:mm:ss yyyy-MM-dd' name={formDefs.dateTimeTo}
                                       ref={register}/>

                            </Form.Field>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={4}>
                        <div className={classes.formField}>
                            <Form.Field>
                                <label>Keywords <Tooltip
                                    title={<Typography>Keywords to look up for. For example the
                                        transaction
                                        ID. You can specify multiple keywords with space delimiter. By default, "AND" is
                                        used, using "OR" for inclusive conditions <br/><br/>Examples: 10e020c0 (ERROR OR
                                        INFO)</Typography>}><Icon name={"info"}/></Tooltip></label>
                                <input placeholder='<Transaction ID> <DEBUG LEVEL>' name={formDefs.transId}
                                       ref={register}/>
                                <ErrorMessage errors={errors} name={formDefs.transId}>
                                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                                </ErrorMessage>
                            </Form.Field>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={4}>
                        <div className={classes.formField}>
                            <Form.Field>
                                <label>Max entries
                                    <Tooltip
                                        title={<Typography variant={'h5'}>Maximum entries to look up, by default it is
                                            set to 100.</Typography>}><Icon name={"info"}/></Tooltip></label>
                                <input placeholder='Max entries' name={formDefs.limit} ref={register}/>
                                <ErrorMessage errors={errors} name={formDefs.limit}>
                                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                                </ErrorMessage>
                            </Form.Field>
                        </div>
                    </Grid>

                    <Divider/>

                    <Grid item xs={12}>
                        <Button type={"submit"} primary disabled={isSubmitting}>
                            Search
                        </Button>
                        <Button onClick={() => {
                            reset(defaultValues);
                            onDateTimeChange([null, null]);
                            onSubmit(null);
                        }} disabled={isSubmitting || !isDirty}>
                            Clear
                        </Button>


                        <Checkbox
                            className={classes.ml1}
                            label='Query logs in the new EKS cluster'
                            onChange={(e, data) => setUseNewCluster(data.checked)}
                            checked={useNewCluster}
                        />


                    </Grid>
                </Grid>

            </Form>
        </Segment>
    );
};

LogsFilter.propTypes = {
    defaultValues: PropTypes.object.isRequired,
};

export default LogsFilter;
