import React from 'react';
import {Button, Divider, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form/es";
import {importLCKP} from "../../../actions/licenses";
import {Field} from "redux-form";
import {RenderedTextArea} from "../../form/formComponents";
import {PropTypes} from "prop-types";

const LckpForm = (props) => {
    const {error, pristine, handleSubmit, submitting} = props;
    return (
        <Segment padded>
            <Header as="h3">Import license common key pair</Header>
            <Message>
                <Message.Header>Common key pair</Message.Header>
                <p>
                    Common key pair is used for licensing ActiveSDK and ActiveServer. If you want to generate a JWK(JSON
                    Web Key) use our <a href={"https://ca.gpayments.com/keys"}>CA server</a>.
                    Key type will be determined automatically by parsing the JWK.
                </p>
            </Message>

            <Form onSubmit={handleSubmit(props.importLCKP)}>
                <Grid>
                    {/*<Grid.Column width={6}>*/}
                    {/*    <Field name="productCode" label="Product" component={RenderedDropDown} autoFocus*/}
                    {/*           options={options}/>*/}
                    {/*</Grid.Column>*/}
                    <Grid.Column width={12}>
                        <Field name="keyContent" label="JWK" placeholder="Generate and paste in JWK (key pair)"
                               component={RenderedTextArea} autoFocus rows={10}/>
                    </Grid.Column>
                </Grid>
                {error && <Message negative>
                    <Message.Header>Import failed</Message.Header>
                    <p>{error}</p>
                </Message>}
                <Divider/>
                <Button type="submit" primary disabled={pristine || submitting}>Import</Button>
            </Form>
        </Segment>
    );
};

const mapStateToProps = (state) => ({
    lckps: state.licenses.lckps,
});

const mapDispatchToProps = (dispatch) => ({
    importLCKP: (values) => dispatch(importLCKP(values)),
});

const LckpView = reduxForm({
    form: "lckpForm",
    enableReinitialize: true,
    validate: () => {

    },
})(LckpForm);

LckpForm.propTypes = {
    initialValues: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(LckpView);