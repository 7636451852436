import React from "react";
import {Button, Form, Header, Message, Modal} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {InputField} from "react-semantic-redux-form";
import {CLOSE_USER_ADD_MODAL} from "../../../actions/actionTypes.js";
import {addNewUser} from "../../../actions/users";
import * as validator from "email-validator";
import {globalStyles} from "../../styles";

const validate = (values) => {
    const errors = {};

    if (!values.password1) {
        errors.password1 = 'New password is required'
    } else if (!values.password2) {
        errors.password2 = 'Please repeat the new password'
    } else if (values.password1.length < 6) {
        errors.password1 = 'New password must have 6 characters or more'
    } else if (values.password1 !== values.password2) {
        errors.password2 = 'Repeat password must be the same'
    } else if (!values.email) {
        errors.email = 'email address is required';
    } else if (!validator.validate(values.email)) {
        errors.email = 'Invalid email address.';
    }

    return errors;

};


const UserAddModal = ({toOpen, closeUserModal, addUser, error, pristine, handleSubmit, submitting, reset}) =>

    <Modal
        style={globalStyles.modal}
        size="small" open={toOpen} onClose={closeUserModal} onSubmit={handleSubmit(addUser)}
        as="form" closeIcon>

        <Header icon="user" content="Add user Account"/>

        <Modal.Content>
            <Form as="div">

                <Field name="firstName" label="First Name" placeholder="First name" component={InputField}
                       autoFocus/>
                <Field name="lastName" label="Last Name" placeholder="Last name" component={InputField}/>
                <Field name="email" label="Email" placeholder="Email" component={InputField}/>
                <Field name="password1" label="Password" placeholder="Password" type="password" component={InputField}/>
                <Field name="password2" label="Confirm Password" placeholder="Confirm Password" type="password"
                       component={InputField}/>
                {error && <Message negative>
                    <Message.Header>New user could not be created</Message.Header>
                    <p>{error}</p>
                </Message>}
            </Form>

        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={closeUserModal}>Cancel</Button>
            <Button positive icon='checkmark' labelPosition='right' content='Create' type='submit' primary
                    disabled={pristine || submitting}/>
        </Modal.Actions>

    </Modal>
;

const UserAddView = reduxForm({
    form: 'addUserForm',
    validate,
    enableReinitialize: true
})(UserAddModal);

const mapStateToProps = (state) => ({
    toOpen: state.users.isUserModalShown
});

const mapDispatchToProps = (dispatch) => ({
    closeUserModal: () => dispatch({type: CLOSE_USER_ADD_MODAL}),
    addUser: (values) => dispatch(addNewUser(values)),
});

export const UserAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAddView);
