import moment from "moment";

export const showDuration = (timeInSeconds) => {
    const duration = moment.duration(timeInSeconds, 'seconds');

    const momentDuration = moment.utc(duration.as('milliseconds'));

    if (momentDuration.format('HH') === '00') {
        return `${momentDuration.format('mm')} min ${momentDuration.format('ss')} sec`;
    }

    if (momentDuration.format('HH') === '00' && momentDuration.format('mm') === '00') {
        return `${momentDuration.format('ss')} sec`;
    }

    return `${momentDuration.format('HH')} hr ${momentDuration.format('mm')} min ${momentDuration.format('ss')} sec`;
};
