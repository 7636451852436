import React, {useState} from 'react';
import {Grid, Icon, Menu, Message, Segment} from "semantic-ui-react";
import {NEW_TENANT_READY} from "../../../../actions/actionTypes";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import TenantsFilter from "./TenantsFilter";
import TenantsTable from "./TenantsTable";
import UpdateTenantForm from "./UpdateTenantForm";
import {OrgDetails} from "../../organizations/OrgDetail";
import SimpleModal from "../../../components/SimpleModal";
import CreateTenantForm from "./CreateTenantForm";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import TenantCapability from "./TenantCapability";

const TenantMain = (props) => {
    const {history} = props;
    const {currentTenant, newTenant} = useSelector(state => state.licenses);
    const {currentOrg} = useSelector(state => state.orgs);
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);

    const onClickViewUsage = () => {
        history.push("/saas/as/tenants/" + currentTenant.id);
    };

    return (
        <Segment attached='bottom'>

            <Grid centered columns={2}>
                <Grid.Column>
                    {newTenant &&
                    <Message info
                             header={`New tenant created`}
                             content={<div>Credentials below is only shown once so please copy the details.<br/>
                                 Login name: <strong>{newTenant.adminUserLoginName}</strong> <br/>
                                 Password: <strong>{newTenant.generatedPassword}</strong>
                             </div>}
                             onDismiss={() => dispatch({type: NEW_TENANT_READY, tenant: null})}
                    />}
                </Grid.Column>
            </Grid>
            <Grid padded>
                <Grid.Column widescreen={10} mobile={16}>
                    <Menu pointing attached="top">
                        <Menu.Menu position={"right"}>
                            <Menu.Item>
                                <Button circular animated='vertical' onClick={() => setOpenDialog(true)}>
                                    <Button.Content hidden>
                                        <Icon name='plane'/>
                                    </Button.Content>
                                    <Button.Content visible>
                                        <Icon name='plus'/>
                                    </Button.Content>
                                </Button>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <Segment padded attached={"bottom"}>
                        <TenantsFilter/>
                    </Segment>
                    <Segment attached={"bottom"}>
                        <TenantsTable/>
                    </Segment>
                </Grid.Column>

                <Grid.Column widescreen={6} mobile={16}>
                    {currentTenant &&
                    <Button onClick={onClickViewUsage} positive>
                        View Usage
                    </Button>
                    }
                    {currentTenant &&
                    <Segment padded>
                        <TenantCapability/>
                    </Segment>
                    }
                    {currentTenant &&
                    <Segment padded>
                        <UpdateTenantForm/>
                    </Segment>
                    }

                    {currentOrg &&
                    <OrgDetails/>
                    }
                </Grid.Column>
            </Grid>

            <SimpleModal open={openDialog} onClose={() => setOpenDialog(false)}>
                <Grid padded>
                    <Grid.Column largeScreen={13} mobile={16}>
                        <CreateTenantForm onSubmit={() => setOpenDialog(false)}/>
                    </Grid.Column>
                </Grid>
            </SimpleModal>
        </Segment>
    );
};

export default withRouter(TenantMain);
