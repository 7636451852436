/**
 * Checks that if the user has any of the given permissions.
 * If the user is an ADMIN we always allow the user to pass through.
 * @param user - the user currently in session.
 * @param authorities - the permissions that user should have.
 */
export const hasAnyPermission = (user, authorities) => {

    if (!authorities || authorities.length === 0) {
        return true
    }

    if (!user || !user.authorities) {
        return false;
    }

    const authorityList = user.authorities.split(",");
    for (const authority of authorityList) {
        if (authorities.includes(authority)) {
            return true;
        }
    }
    return false;
};

/**
 * If url includes ? then prefix would be & otherwise ?
 */
export const getURLPrefix = (url) => {
    return url.includes("?") ? "&" : "?";
};
