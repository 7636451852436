import {Config, get, post, put} from "./http";
import {
    CURRENT_TENANT_CAPABILITY_CHANGED,
    CURRENT_TENANT_UPDATED,
    DEPLOYMENT_INFOS_READY,
    LCKPS_READY,
    NEW_TENANT_READY,
    SDK_SECRET_READY,
    SELECT_LCKP,
    SELECT_SERVER,
    SERVER_USAGE_READY,
    SERVERS_READY,
    TENANT_USAGE_READY
} from "./actionTypes";
import moment from "moment";
import {SubmissionError} from "redux-form";
import {nowLoading, showNotification} from "./utils";
import {TENANT_TYPES} from "../constants/saas";

export const listRemoteServers = (orgId, page = 0) => {
    return (dispatch, getState) => {
        get(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/servers/org/${orgId}?p=${page}&s=10`)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {
            const data = body.data;

            dispatch({type: SERVERS_READY, servers: data})

        })
    }
};


export const getServerUsage = (serverId, startDate, endDate, timeFormat, usageType) => {

    return (dispatch, getState) => {
        dispatch(nowLoading(true));
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");

        // reset the value
        dispatch({type: SERVER_USAGE_READY, usage: null, usageType});

        get(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/servers/${serverId}/usage?fromDate=${start}&toDate=${end}&format=${timeFormat}&usageType=${usageType}`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then(body => {
            dispatch({type: SERVER_USAGE_READY, usage: body.data, usageType});

        }).catch(error => {
            console.log("error", error);
            dispatch(showNotification("failed to get usage", "Failed to ge server usage", "error"))
        }).finally(() => {
            dispatch(nowLoading(false))
        })
    }

};

export const getDeploymentInfos = (page, serverId) => {
    return (dispatch, getState) => {

        get(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/servers/${serverId}/deployment_infos?page=${page}&size=5&sort=receivedTime,desc`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then(body => {

            dispatch({type: DEPLOYMENT_INFOS_READY, info: body.data});
        }).catch(error => {
            console.log("error", error)
        })
    }
};

export const updateServerDetails = (values) => {
    return (dispatch, getState) => {

        // get the name of current org
        const {currServer} = getState().licenses;

        return post(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/servers/${currServer.id}`, {
            ...values,
        })
            .then(response => {
                if (!response.ok) {
                    throw new SubmissionError(response.status + ': ' + response.statusText);
                }
                return response.json();
            }).then((body) => {

                const server = body.data;

                dispatch({type: SELECT_SERVER, server});

                dispatch(showNotification("Saved Changes", "You have successfully saved changes to the instance!"));

            }).catch((error) => {

                console.error("error", error);

                throw new SubmissionError({_error: error});

            })

    }
};

export const getLCKPs = (product, page, size) => {
    return (dispatch, getState) => {
        return get(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/lckps?page=${page}&size=${size}&product=${product}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
                return response.json();
            }).then((body) => {

                const lckps = body.data;

                dispatch({type: LCKPS_READY, lckps});
            }).catch((error) => {

                console.error("error", error);
            })
    }
};

export const importLCKP = (values) => {
    return (dispatch, getState) => {
        return post(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/lckps`, values)
            .then(response => {
                if (!response.ok) {
                    throw new SubmissionError(response.status + ': ' + response.statusText);
                }
                return response.json();
            }).then((body) => {
                const lckp = body.data;

                dispatch(selectLCKP(lckp.keyId));

                // refresh the list
                dispatch(getLCKPs(lckp.productCode, 0, 20));

                dispatch(showNotification("Saved Changes", "You have successfully imported new license common key pair!"));

            }).catch((error) => {
                console.error("error", error);
                dispatch(showNotification("Failed to import", error.errors, "error"));
                throw new SubmissionError({_error: error});

            })
    }
};

export const selectLCKP = (keyId) => {

    return (dispatch, getState) => {

        if (!keyId) {
            dispatch({type: SELECT_LCKP, lckp: null});
            return;
        }

        return get(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/lckps/${keyId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
                return response.json();
            }).then((body) => {

                dispatch({type: SELECT_LCKP, lckp: body.data})
            }).catch((error) => {

                console.error("error", error);
            })
    }
};

export const getCurrentActiveSalt = () => {
    return (dispatch, getState) => {
        return get(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/sdk/secret`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
                return response.json();
            }).then((body) => {

                dispatch({type: SDK_SECRET_READY, secret: body.data})
            }).catch((error) => {

                console.error("error", error);
            })
    }
};

export const importSDKSecret = (values) => {
    return (dispatch, getState) => {
        return post(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/sdk/secret`, values)
            .then(response => {
                if (!response.ok) {
                    throw new SubmissionError(response.status + ': ' + response.statusText);
                }
                return response.json();
            }).then((body) => {
                dispatch({type: SDK_SECRET_READY, secret: body.data});

                dispatch(showNotification("Saved Changes", "You have successfully imported new salt!"));

            }).catch((error) => {
                console.error("error", error);
                dispatch(showNotification("Failed to import", error.errors, "error"));
                throw new SubmissionError({_error: error});

            })
    }
};

export const listTenants = (session, {page = 0, size = 10, orderBy, sortDirection, searchFilters}) => {

    let url = `${Config.licenseApiServerUrl}/admin/api/v1/saas/tenants?page=${page}&size=${size}`

    if (orderBy && sortDirection) {
        url += `&sort=${orderBy},${sortDirection}`;
    }

    if (searchFilters) {
        Object.keys(searchFilters).forEach(key => {
            if (searchFilters[key]) {

                if (Array.isArray(searchFilters[key])) {
                    searchFilters[key].forEach(value => {
                        url += `&${key}=${value}`;
                    })
                } else {
                    url += `&${key}=${searchFilters[key]}`;
                }
            }
        })
    }

    return get(session, url)
        .then(response => {
            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }
            return response.json();
        }).then(body => {
            return body.data;
        }).catch((error) => {
            console.error("error", error);
            return null;
        })
};

export const onboardTenant = (tenant) => {
    return (dispatch, getState) => {

        dispatch(nowLoading(true));
        return post(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/saas/tenants`, tenant)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
                return response.json();
            }).then(body => {

                dispatch({type: NEW_TENANT_READY, tenant: body.data});

                // const {tenantsPage} = getState().licenses;

                // if (tenantsPage) {
                //     dispatch(listTenants({page: tenantsPage.page, size: tenantsPage.size}));
                // }


                dispatch(showNotification('Tenant onboarded', 'New tenant has been created successfully!', 'success'));

            }).catch((error) => {
                console.error("error", error);
                dispatch(showNotification('Failed to onboard', 'Failed to onboard tenants', 'error'));

            }).finally(() => {

                dispatch(nowLoading(false));
            })
    }
};


export const enableCapability = (tenantId, {enableCapability, disableCapability}) => {
    return (dispatch, getState) => {
        return put(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/saas/tenants/${tenantId}`,
            {
                enableCapability: enableCapability,
                disableCapability: disableCapability,
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }

                dispatch({
                    type: CURRENT_TENANT_CAPABILITY_CHANGED,
                    enableCapability: enableCapability,
                    disableCapability: disableCapability
                })
                return response.json();
            }).then(body => {
                dispatch(showNotification('Tenant updated', 'Tenant updated successfully!', 'success'));
            }).catch((error) => {
                console.error("error", error);
                dispatch(showNotification('Failed', 'Failed to update tenant.', 'error'));
            })
    }
}

export const updateTenant = (tenantId, tenant) => {
    return (dispatch, getState) => {
        return put(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/saas/tenants/${tenantId}`, tenant)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
                return response.json();
            }).then(body => {
                // console.log("update tenant", body.data);
                // refresh the tenants

                // const {tenantsPage} = getState().licenses;

                // if (tenantsPage) {
                //     dispatch(listTenants({page: tenantsPage.page, size: tenantsPage.size}));
                // }

                dispatch({
                    type: CURRENT_TENANT_UPDATED, tenant: {
                        id: tenantId,
                        name: tenant.tenantName,
                        desc: tenant.tenantDesc,
                        tenantType: body.data.tenantType,
                        subscriptionStatus: body.data.subscriptionStatus,
                        expiryDate: tenant.expiryDate,
                    }
                });

                dispatch(showNotification('Tenant updated', 'Tenant updated successfully!', 'success'));
            }).catch((error) => {
                console.error("error", error);
                dispatch(showNotification('Failed', 'Failed to update tenant.', 'error'));
            })
    }
};


/**
 * Only allow setting exipiry date for TRIAL or TEST tenant
 */
export const isExpiryDateAllowed = (tenantType) => {
    return tenantType === TENANT_TYPES.TEST.value ||
        tenantType === TENANT_TYPES.TRIAL.value;
};

/**
 * Expiry date must be more than 1 day.
 */
export const getMinimumExpiryDate = () => {

    const exipryDate = new Date();
    exipryDate.setHours(exipryDate.getHours() + 36); // add 36 hour just to be sure, its not at the 24 hour mark
    return exipryDate;
};


export const getTenantUsage = (tenantId, startDate, endDate, dsProfile) => {
    return (dispatch, getState) => {

        dispatch(nowLoading(true));

        return get(getState().session, `${Config.licenseApiServerUrl}/admin/api/v1/saas/tenants/${tenantId}/usage?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}&dsProfileType=${dsProfile}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
                return response.json();
            })
            .then((resp) => {

                dispatch({type: TENANT_USAGE_READY, usage: resp.data});
            })
            .catch(err => {
                console.error(err)
            }).finally(() => {
                dispatch(nowLoading(false));

            })
    }
};
