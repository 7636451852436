import React, {useState} from 'react';
import {Button, Checkbox, Header, Icon, Modal} from "semantic-ui-react";
import {getCapabilityById, TENANT_CAPABILITIES} from "../../../../constants/saas";
import {Grid} from "@material-ui/core";
import {enableCapability} from "../../../../actions/licenses";
import {useDispatch, useSelector} from "react-redux";
import {globalStyles} from "../../../styles";

// const useStyles = createUseStyles(({
//     ...globalStyle()
// }));


const TenantCapability = (props) => {
    const dispatch = useDispatch();

    const {currentTenant} = useSelector(state => state.licenses);


    const [modal, setModal] = useState({
        open: false
    });

    const onClose = () => {
        setModal({open: false});
    }

    const onConfirm = () => {

        if (modal.checked) {
            dispatch(enableCapability(currentTenant.id, {enableCapability: modal.capability}));
        } else {
            dispatch(enableCapability(currentTenant.id, {disableCapability: modal.capability}));
        }
    }

    // console.log(currentTenant);

    const chosenCapability = getCapabilityById(modal.capability);

    return (
        <div>
            <Header>Tenant Capabilities</Header>
            <Grid container spacing={2}>
                {Object.values(TENANT_CAPABILITIES).map(cap => (
                    <React.Fragment key={cap.id}>
                        <Grid item xs={12} sm={6}>
                            <div>{cap.description}</div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Checkbox toggle
                                      checked={(currentTenant.capabilities && currentTenant.capabilities.includes(cap.id))}
                                      onChange={(e, data) => setModal({
                                          open: true,
                                          checked: data.checked,
                                          capability: cap.id,
                                      })}/>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>

            <Modal
                style={globalStyles.modal}
                closeIcon
                open={modal.open}
                onClose={onClose}
            >
                <Header icon='check' content={"Are you sure?"}/>
                <Modal.Content>
                    <p>
                        Are you sure you want
                        to <strong>{modal.checked ? "ENABLE" : "DISABLE"}</strong> {chosenCapability && chosenCapability.description}?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={onClose}>
                        <Icon name='remove'/> No
                    </Button>
                    <Button color='green' onClick={onConfirm}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default TenantCapability;
