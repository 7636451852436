
export const usageTypes = {
    AuthenticationType: {
        PAYMENT: {
            name: "Payment",
            value: "PAYMENT",
        },
        NON_PAYMENT: {
            name: "Non Payment",
            value: "NON_PAYMENT",
        },
        UNRECOGNIZED: {
            name: "Unrecognised",
            value: "UNRECOGNIZED",
        }
    },
    DirectoryServerType: {
        PRODUCTION: {
            name: "Production",
            value: "PRODUCTION"
        },
        GPAYMENTS_TEST_LAB: {
            name: "Test Lab",
            value: "GPAYMENTS_TEST_LAB"
        },
        // TEST: {
        //     name: "Testing",
        // },
        UNRECOGNIZED: {
            name: "Unrecognised",
            value: "UNRECOGNIZED",
        }
    },
    PaymentNetwork: {
        AMERICAN_EXPRESS: {
            name: "American Express",
            value: "AMERICAN_EXPRESS"
        },
        DINERS_CLUB: {
            name: "Diners Club",
            value: "DINERS_CLUB"
        },
        JCB: {
            name: "JCB",
            value: "JCB"
        },
        MASTERCARD: {
            name: "Mastercard",
            value: "MASTERCARD"
        },
        VISA: {
            name: "Visa",
            value: "VISA"
        },
        UNRECOGNIZED: {
            name: "Unrecognised",
            value: "UNRECOGNIZED",
        }
    },
    DeviceChannel: {
        BROWSER: {
            name: "browser",
            value: "BROWSER",
        },
        MOBILE: {
            name: "mobile",
            value: "MOBILE",
        },
        THREE_RI: {
            name: "3RI",
            value: "THREE_RI",
        },
        UNRECOGNIZED: {
            name: "Unrecognised",
            value: "UNRECOGNIZED",
        }
    },
    TransStatus: {
        Y: {
            name: "Success (Y)",
            value: "Y",
        },
        N: {
            name: "Not Authenticated (N)",
            value: "N",
        },
        U: {
            name: "Not Performed (U)",
            value: "U",
        },
        R: {
            name: "Rejected (R)",
            value: "R",
        },
        A: {
            name: "Attempted (A)",
            value: "A",
        },
        CY: {
            name: "Challenge Success (CY)",
            value: "CY",
        },
        CN: {
            name: "Challenge Not Authenticated (CN)",
            value: "CN",
        },
        CU: {
            name: "Challenge Performed (CU)",
            value: "CU",
        },
        CR: {
            name: "Challenge Rejected (CR)",
            value: "CR",
        },
        CA: {
            name: "Challenge Attempted (CA)",
            value: "CA",
        },
        UNRECOGNIZED: {
            name: "Unrecognised",
            value: "UNRECOGNIZED",
        }
    }

};

export const USAGE_TYPE_DEFS = {
    AuthenticationType: {
        value: "AuthenticationType",
        options: usageTypes.AuthenticationType,
    },
    DirectoryServerType: {
        value: "DirectoryServerType",
        options: usageTypes.DirectoryServerType,
    },
    PaymentNetwork: {
        value: "PaymentNetwork",
        options: usageTypes.PaymentNetwork,
    },
    DeviceChannel: {
        value: "DeviceChannel",
        options: usageTypes.DeviceChannel,
    },
    TransStatus: {
        value: "TransStatus",
        options: usageTypes.TransStatus
    }
};
