// Session related
export const SESSION_READY = 'SESSION_READY';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const ORG_READY = 'ORG_READY';
export const ROLES_READY = 'ROLES_READY';
export const ROLE_UPDATED = 'ROLE_UPDATED';
export const ROLE_SELECTED = 'ROLE_SELECTED';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const NOW_LOADING = "NOW_LOADING";
export const LOADING_FINISHED = "LOADING_FINISHED";


//Release related
//version list is fetched from server
export const VERSIONS_READY = 'VERSIONS_READY';
export const OPEN_PROMOTE_PULLBACK_MODAL = 'OPEN_PROMOTE_PULLBACK_MODAL';
export const LOG_QUERY_ID_CHANGED = "LOG_QUERY_ID_CHANGED";
export const LOGS_READY = "LOGS_READY";
export const LOGS_FILTER_CHANGED = "LOGS_FILTER_CHANGED";

//set version to display
export const SET_VERSION_TO_DISPLAY = 'SET_VERSION_TO_DISPLAY';
//a version is promoted
export const VERSION_PROMOTED = 'VERSION_PROMOTED';
export const VERSION_PULLBACK = 'VERSION_PULLBACK';

//set version to display
export const VERSION_FILE_INFO_READY = 'VERSION_FILE_INFO_READY';
//set version summary editing mode
export const SET_VERSION_SUMMARY_MODE = 'SET_VERSION_SUMMARY_MODE';
//summary saved on server side
export const RELEASE_SUMMARY_SAVED = 'RELEASE_SUMMARY_SAVED';
export const UPDATE_PACKAGE_SUMMARY = 'UPDATE_PACKAGE_SUMMARY';
// set packge file to display
export const SELECT_PACKAGE = 'SELECT_PACKAGE';
export const DOWNLOAD_HISTORY_READY = 'DOWNLOAD_HISTORY_READY';
export const INCIDENT_CREATED = "INCIDENT_CREATED";
export const INCIDENT_UPDATED = "INCIDENT_UPDATED";
export const CURRENT_INCIDENT_READY = "CURRENT_INCIDENT_READY";
export const INCIDENTS_READY = "INCIDENTS_READY";
export const INCIDENT_LOGS_READY = "INCIDENT_LOGS_READY";
export const INCIDENT_LOG_ADDED = "INCIDENT_LOG_ADDED";
export const INCIDENT_LOG_UPDATED = "INCIDENT_LOG_UPDATED";
export const INCIDENT_LOG_DELETED = "INCIDENT_LOG_DELETED";

//user related
export const USERS_READY = 'USERS_READY';
export const SELECT_USER = 'SELECT_USER';
export const USER_UPDATED = 'USER_UPDATED';
export const SHOW_USER_ADD_MODAL = 'SHOW_USER_ADD_MODAL';
export const CLOSE_USER_ADD_MODAL = 'CLOSE_USER_ADD_MODAL';
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';
export const PERMISSIONS_READY = 'PERMISSIONS_READY';
export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';
export const ADD_PERMISSION = 'ADD_PERMISSION';
export const CHANGE_FILTER = 'CHANGE_FILTER';

//organisation related
export const ORGS_READY = 'ORGS_READY';
export const SELECT_ORG = 'SELECT_ORG';
export const ORG_UPDATED = 'ORG_UPDATED';
export const SHOW_ORG_ADD_MODAL = 'SHOW_ORG_ADD_MODAL';
export const CLOSE_ORG_ADD_MODAL = 'CLOSE_ORG_ADD_MODAL';
export const UPDATE_SEARCH_KEYWORD = 'UPDATE_SEARCH_KEYWORD';
export const ORG_PRODUCTS_READY = 'ORG_PRODUCTS_READY';
export const ORG_REMOVE_PRODUCT = 'ORG_REMOVE_PRODUCT';
export const ORG_ADD_PRODUCT = 'ORG_ADD_PRODUCT ';
export const CLOSE_PRODUCT_CONFIRM_MODAL = "CLOSE_PRODUCT_CONFIRM_MODAL";
export const OPEN_PRODUCT_CONFIRM_MODAL = "OPEN_PRODUCT_CONFIRM_MODAL";
export const UPDATE_ORG_PRODUCTS_SUCCESSFUL = "UPDATE_ORG_PRODUCTS_SUCCESSFUL";

// license related
export const SERVERS_READY = 'SERVERS_READY';
export const SELECT_SERVER = 'SELECT_SERVER';
export const SERVER_USAGE_READY = 'SERVER_USAGE_READY';
export const DEPLOYMENT_INFOS_READY = 'DEPLOYMENT_INFOS_READY';
export const SELECT_STOP_DATE = 'SELECT_STOP_DATE';
export const LCKPS_READY = 'LCKPS_READY';
export const SELECT_LCKP = 'SELECT_LCKP';
export const SDK_SECRET_READY = 'SDK_SECRET_READY';
export const TENANTS_READY = 'TENANTS_READY';
export const NEW_TENANT_READY = "NEW_TENANT_READY";
export const CURRENT_TENANT_READY = "CURRENT_TENANT_READY";
export const CURRENT_TENANT_UPDATED = "CURRENT_TENANT_UPDATED";
export const CURRENT_TENANT_CAPABILITY_CHANGED = "CURRENT_TENANT_CAPABILITY_CHANGED";
export const TENANT_USAGE_READY = "TENANT_USAGE_READY";

// filters
export const TENANTS_FILTER_CHANGED = "TENANT_FILTER_CHANGED";
export const USAGE_FILTER_CHANGED = "USAGE_FILTER_CHANGED";
