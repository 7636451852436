import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Divider, Header, Icon, Modal} from "semantic-ui-react";
import {createUseStyles} from "react-jss";
import {globalStyles} from "../../../styles";
import IncidentLogForm from "./IncidentLogForm";
import {createIncidentLog, deleteIncidentLog, updateIncidentLog} from "../../../../actions/release";
import {INCIDENT_STATUSES} from "../../../../constants/saas";
import moment from "moment";
import Box from "@material-ui/core/Box";

const useStyles = createUseStyles({
    grow: {
        flexGrow: 1,
    },
    ...globalStyles
});

const IncidentLogList = () => {

    const classes = useStyles();

    const [openAddLog, setOpenAddLog] = useState(false);
    const [openEditLog, setOpenEditLog] = useState(false);
    const [openDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [currentLog, setCurrentLog] = useState(null);
    const dispatch = useDispatch();

    const {incidentLogs, currentIncident} = useSelector(state => state.releases);

    const onCreate = (values) => {
        dispatch(createIncidentLog(currentIncident.incidentID, values));
    };


    const onUpdate = (values) => {
        dispatch(updateIncidentLog(currentLog.incidentId, currentLog.logId, values));
    };


    const allowEdit = (log) => {
        setOpenEditLog(true);
        setCurrentLog(log)
    };

    const openConfirm = (log) => {
        setConfirmDeleteDialog(true);
        setCurrentLog(log)
    };

    const confirmDelete = () => {
        dispatch(deleteIncidentLog(currentLog.incidentId, currentLog.logId));
    };


    return (
        <div>
            {(incidentLogs && Object.values(incidentLogs).length !== 0) ?
                <>
                    <Box display={"flex"} justifyContent="flex-end">
                        <div className={classes.mt1}>
                            <Button positive onClick={() => setOpenAddLog(true)}>Add New</Button>
                        </div>
                    </Box>

                    {Object.values(incidentLogs).map(log => (
                        <Box display={"block"} className={classes.mt1}>
                            <Box display={"flex"}>
                                <div>
                                    <strong>{INCIDENT_STATUSES[log.incidentStatus] && INCIDENT_STATUSES[log.incidentStatus].text}</strong>: {log.description}
                                </div>
                                <div className={classes.grow}/>
                                <Box justifyContent="flex-end">
                                    <Button icon onClick={() => allowEdit(log)}>
                                        <Icon name='edit'/>
                                    </Button>
                                    <Button negative icon onClick={() => openConfirm(log)}>
                                        <Icon name='close'/>
                                    </Button>
                                </Box>
                            </Box>
                            <div style={{marginTop: 10}}>{moment.utc(log.createdTime).local().format("lll")}</div>
                            <Divider light/>

                        </Box>))}
                </>
                :
                <>
                    <Header as='h2' icon textAlign={"center"}>
                        <Icon name='sticky note outline'/>
                        No incident logs
                        <Header.Subheader>
                            No incident logs was found
                        </Header.Subheader>

                        <div className={classes.mt1}>
                            <Button positive onClick={() => setOpenAddLog(true)}>Add New</Button>
                        </div>
                    </Header>
                </>}


            <Modal style={globalStyles.modal}
                   open={openAddLog}
                   onClose={() => setOpenAddLog(false)}
            >
                <Modal.Header>Add an incident status</Modal.Header>
                <Modal.Content>
                    <IncidentLogForm onSubmit={onCreate}/>
                </Modal.Content>
            </Modal>

            <Modal style={globalStyles.modal}
                   open={openDeleteDialog}
                   onClose={() => setConfirmDeleteDialog(false)}
            >
                <Modal.Header>Delete an incident status</Modal.Header>
                <Modal.Content>
                    <div>Are you sure you want to delete this incident status?</div>

                    <Box className={classes.mt1} display={"flex"}>
                        <Button negative onClick={confirmDelete}>
                            Confirm
                        </Button>
                        <Button onClick={() => setConfirmDeleteDialog(false)}>
                            Close
                        </Button>
                    </Box>
                </Modal.Content>
            </Modal>

            <Modal style={globalStyles.modal}
                   open={openEditLog}
                   onClose={() => setOpenEditLog(false)}
            >
                <Modal.Header>Update incident status</Modal.Header>
                <Modal.Content>
                    <IncidentLogForm disableIncidentStatus onSubmit={onUpdate} defaultValues={currentLog}/>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default IncidentLogList;
