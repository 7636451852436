import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {Button, Form, Select} from "semantic-ui-react";
import {INCIDENT_STATUSES} from "../../../../constants/saas";
import {ErrorMessage} from "@hookform/error-message";
import red from "@material-ui/core/colors/red";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {globalStyle} from "../../../../styles/globalStyle";

const useStyles = makeStyles(theme => ({
    errorMessage: {
        color: red[700],
    },
    ...globalStyle()
}));

const formDef = {
    incidentStatus: "incidentStatus",
    description: "description",
};

const IncidentLogForm = (props) => {
    const {onSubmit, defaultValues, disableIncidentStatus} = props;
    const {register, unregister, handleSubmit, errors, formState, control, watch} = useForm({
        defaultValues: defaultValues
    });
    const {isSubmitting, isDirty} = formState;
    const classes = useStyles();
    const watchIncidentStatus = watch(formDef.incidentStatus);

    useEffect(() => {

        register({name: formDef.incidentStatus}, {required: "Incident status is required"});

        return () => {
            unregister(formDef.incidentStatus);
        }

    }, [register, unregister]);

    return (
        <div>
            <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>

                <Controller
                    control={control}
                    name={formDef.incidentStatus}
                    render={({onChange, value}) =>
                        <Form.Field disabled={disableIncidentStatus}
                                    control={Select}
                                    value={value}
                                    onChange={(e, data) => onChange(data.value)}
                                    options={Object.values(INCIDENT_STATUSES)}
                                    label={{
                                        children: <label>Incident status <span
                                            className={classes.required}>*</span></label>,
                                        htmlFor: 'form-select-control-tenant-type'
                                    }}
                                    placeholder='Type of incident status'
                                    search
                                    searchInput={{id: 'form-select-control-tenant-type'}}
                        />}/>
                <ErrorMessage errors={errors} name={formDef.incidentStatus}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                {watchIncidentStatus === INCIDENT_STATUSES.RESOLVED.value &&
                <div className={classes.warningMessage}>Marking incident status to 'Resolved' will recover the
                    incident.</div>}
                <Form.Field>
                    <label>Description <span className={classes.required}>*</span></label>
                    <textarea placeholder='Descriptions of the log' name={formDef.description} cols="40" rows="5"
                              ref={register({
                                  required: true,
                                  minLength: {
                                      value: 5,
                                      message: "Must be longer than 5 characters",
                                  },
                                  maxLength: {
                                      value: 250,
                                      message: "Cannot be longer than 250 characters",
                                  },
                              })}/>
                </Form.Field>

                <ErrorMessage errors={errors} name={formDef.description}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>

                <div>
                    <Button type={"submit"} primary disabled={isSubmitting || !isDirty}>
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    );
};


IncidentLogForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    disableIncidentStatus: PropTypes.bool,
};

export default IncidentLogForm;
