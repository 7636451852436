import {
    isUserGranted,
    ROLE_ADMIN,
    ROLE_DEVELOPER,
    ROLE_FINANCIAL,
    ROLE_ORG,
    ROLE_ORG_ROOT,
    ROLE_TECH_SUPPORT
} from "../actions/users";
import {
    ADD_PERMISSION,
    CHANGE_FILTER,
    CLOSE_CONFIRMATION_MODAL,
    CLOSE_USER_ADD_MODAL,
    PERMISSIONS_READY,
    REMOVE_PERMISSION,
    SELECT_ORG,
    SELECT_USER,
    SHOW_CONFIRMATION_MODAL,
    SHOW_USER_ADD_MODAL,
    UPDATE_SEARCH_KEYWORD,
    USER_UPDATED,
    USERS_READY
} from "../actions/actionTypes";
import {arrayToObject} from "./utils";


const initialSettings = {
    currentPage: null,
    currentUser: null,
    isUserModalShown: false,
    isConfirmationModalShown: false,
    permissions: null, // list of all permissions
    searchKeyword: '',
    filter: null, // filter for searching users
};


const convertUser = (user) => {

    return {
        ...user,

        isAdmin: isUserGranted(user, ROLE_ADMIN),
        isDeveloper: isUserGranted(user, ROLE_DEVELOPER),
        isOrgRoot: isUserGranted(user, ROLE_ORG_ROOT),
        isOrgUser: isUserGranted(user, ROLE_ORG),
        isFinancialManager: isUserGranted(user, ROLE_FINANCIAL),
        isTechSupport: isUserGranted(user, ROLE_TECH_SUPPORT),
    }

};


const updateRowOnUserPage = (page, newUser) => {

    if (!page) return page;

    return {
        ...page,
        content: page.content.map(item => {
            if (item.userId === newUser.userId) {
                return {
                    ...newUser
                }
            } else {
                return item;
            }
        })
    }
};

export const users = (state = initialSettings, action) => {
    switch (action.type) {
        case USERS_READY:

            return {
                ...state,
                currentPage: {
                    ...action.page
                }
            };
        case PERMISSIONS_READY:

            let permissionsObject = {};

            action.permissions.forEach(p => {
                permissionsObject[p.id] = p;
            });

            return {
                ...state,
                permissions: {
                    ...permissionsObject
                }
            };
        case REMOVE_PERMISSION:

            delete state.currentUser.permissions[action.id];

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                },
            };
        case ADD_PERMISSION:

            // if full or read access is already added remove it no point having read & full access
            const productCode = action.id.split("_")[0];
            const permission = Object.keys(state.currentUser.permissions).find(key => state.currentUser.permissions[key].code === productCode);

            delete state.currentUser.permissions[permission];

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    permissions: {
                        ...state.currentUser.permissions,
                        [action.id]: action.permission
                    }
                }

            };
        case SELECT_USER:

            action.user.permissions = arrayToObject(action.user.permissions, "id", "code");
            return {
                ...state,
                currentUser: convertUser(action.user),
            };
        case SELECT_ORG: {
            return {
                ...state,
                currentUser: undefined
            }
        }

        case USER_UPDATED:
            action.user.permissions = arrayToObject(action.user.permissions, "id", "code");

            if (state.currentUser && state.currentUser.userId === action.user.userId) {
                return {
                    ...state,
                    currentUser: convertUser(action.user),
                    currentPage: updateRowOnUserPage(state.currentPage, action.user),
                };

            } else {
                //the updated user is not the selected user.
                return {
                    ...state,
                    currentPage: updateRowOnUserPage(state.currentPage, action.user),
                };
            }
        case CHANGE_FILTER: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    orderBy: action.value
                }
            }
        }

        case SHOW_USER_ADD_MODAL:
            return {
                ...state,
                isUserModalShown: true
            };

        case CLOSE_USER_ADD_MODAL:
            return {
                ...state,
                isUserModalShown: false
            };
        case SHOW_CONFIRMATION_MODAL:
            return {
                ...state,
                isConfirmationModalShown: true,
            };
        case CLOSE_CONFIRMATION_MODAL:
            return {
                ...state,
                isConfirmationModalShown: false
            };
        case UPDATE_SEARCH_KEYWORD: {
            return {
                ...state,
                searchKeyword: action.keyword
            }
        }
        default:
            return state;
    }
};


