import React, {useEffect, useState} from 'react';
import MaterialTable from "material-table";
import {useDispatch, useSelector} from "react-redux";
import {CURRENT_INCIDENT_READY} from "../../../../actions/actionTypes";
import {DATA_POINTS, INCIDENT_VISIBILITIES} from "../../../../constants/saas";
import {getIncidentLogs, getIncidents} from "../../../../actions/release";
import * as moment from "moment";
import CreateIncidentForm from "./CreateIncidentForm";
import {Icon, Modal, Segment} from "semantic-ui-react";
import {globalStyles} from "../../../styles";
import Paper from "@material-ui/core/Paper";
import {showDuration} from "../../../../utils/datetime";


const columns = {
    id: {
        field: "id",
        title: "#",
        render: (row) => row.id,
    },
    description: {
        field: "description",
        title: "Description",
        render: (row) => row.description,
    },
    dataPoint: {
        field: "dataPoint",
        title: "Type",
        render: (row) => DATA_POINTS[row.dataPoint] ? DATA_POINTS[row.dataPoint].text : "N/A",
    },
    occurredAt: {
        field: "occurredAt",
        title: "Occurred at",
        render: (row) => row.occurredAt ? moment.utc(row.occurredAt).local().format("lll") : "-",
        defaultSort: "desc",
    },
    authorId: {
        field: "authorId",
        title: "Manual",
        tooltip: "Manually created by a user and not automatically by the release server, only manual incident can be recovered by the user",
        render: (row) => row.authorId ? "Yes" : "No",
    },
    recoveredAt: {
        field: "recoveredAt",
        title: "Recovered at",
        render: (row) => row.recoveredAt ? moment.utc(row.recoveredAt).local().format("lll") :
            <div style={{color: "#e53935"}}><Icon name={"warning circle"}/>Ongoing</div>,
    },
    downtime: {
        field: "downtime",
        title: "Downtime",
        render: (row) => showDuration(row.downtime),
    },
    visibility: {
        field: "visibility",
        title: "Visibility",
        render: (row) => INCIDENT_VISIBILITIES[row.visibility] && INCIDENT_VISIBILITIES[row.visibility].text,
    },
    isFalsePositive: {
        field: "isFalsePositive",
        title: "False positive?",
        render: (row) => row.falsePositive ? "Yes" : "No",
    },
};

const IncidentTable = () => {
    const [selectedRow, setSelectedRow] = useState(null);


    const {incidents, currentIncident} = useSelector(state => state.releases);
    const dispatch = useDispatch();
    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    useEffect(() => {
        dispatch(getIncidents());
    }, [dispatch]);

    const onRowClick = (row) => {
        dispatch({type: CURRENT_INCIDENT_READY, incident: row});

        // fetch incident logs
        dispatch(getIncidentLogs(row.incidentID));
    };

    useEffect(() => {

        if (currentIncident) {
            setSelectedRow(currentIncident.incidentID);
        }

    }, [currentIncident]);


    return (
        <div>
            <Segment>
                <MaterialTable columns={Object.values(columns)}
                               title={"ActiveServer incidents"}
                               data={incidents ? Object.values(incidents) : []}
                               options={{
                                   // filtering: true
                                   search: false,
                                   sorting: true,
                                   rowStyle: rowData => {
                                       return ({
                                           backgroundColor: (selectedRow === rowData.incidentID) ? '#EEE' : '#FFF'
                                       })
                                   },
                               }}
                               components={{
                                   Container: props => <Paper {...props} elevation={0}/>
                               }}
                               onRowClick={(e, rowData) => onRowClick(rowData)}
                               actions={[
                                   {
                                       icon: 'add',
                                       tooltip: 'Create incident',
                                       onClick: (event, rowData) => {
                                           setOpenCreateDialog(true)
                                       },
                                       isFreeAction: true,
                                   },
                               ]}
                />
            </Segment>
            <Modal style={globalStyles.modal}
                   open={openCreateDialog}
                   onClose={() => setOpenCreateDialog(false)}
            >
                <Modal.Header>Create an incident</Modal.Header>
                <Modal.Content>
                    <CreateIncidentForm onSubmit={() => setOpenCreateDialog(false)}/>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default IncidentTable;
