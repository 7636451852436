import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDeploymentInfos} from "../../../actions/licenses";
import moment from "moment";
import SortTable from "../../components/SortTable";

const tableDefs = {
    // checksum: {
    //     key: "checksum",
    //     label: "Checksum",
    //     render: (row) => row.checksum,
    // },
    asVersion: {
        key: "asVersion",
        label: "AS version",
        render: (row) => row.asVersion,
    },
    osName: {
        key: "osName",
        label: "OS name",
        render: (row) => row.osName,
    },
    osVersion: {
        key: "osVersion",
        label: "OS version",
        render: (row) => row.osVersion,
    },
    databaseType: {
        key: "databaseType",
        label: "DB type",
        render: (row) => row.databaseType,
    },
    dbVersion: {
        key: "dbVersion",
        label: "DB version",
        render: (row) => row.dbVersion,
    },
    javaVersion: {
        key: "javaVersion",
        label: "Java version",
        render: (row) => row.javaVersion,
    },
    cpuInfo: {
        key: "cpuInfo",
        label: "CPU Info",
        render: (row) => row.cpuInfo,
    },
    nodeCount: {
        key: "nodeCount",
        label: "Node count",
        render: (row) => row.nodeCount,
    },
    receivedTime: {
        key: "receivedTime",
        label: "Received time",
        render: (row) => row.receivedTime ? moment(row.receivedTime).format("lll") : "-",
    }
};

const DeploymentInfoTable = () => {
    const {currServer, deploymentInfos} = useSelector(state => state.licenses);

    const dispatch = useDispatch();


    useEffect(() => {
        if (currServer) {
            dispatch(getDeploymentInfos(0, currServer.id))
        }
    }, [dispatch, currServer]);

    return (
        <>
            <SortTable onPageChange={(page) => dispatch(getDeploymentInfos(page, currServer.id))}
                       definitions={Object.values(tableDefs)} pageData={deploymentInfos}/>
        </>
    );
};

export default DeploymentInfoTable;