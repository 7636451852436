export const products = {
    ame: {
        code: 'ame',
        name: 'ActiveMerchant',
    },
    aa: {
        code: 'aa',
        name: 'ActiveAccess'
    },
    as: {
        code: 'as',
        name: 'ActiveServer',
        license: true
    },
    "as-saas": {
        code: 'as-saas',
        name: 'ActiveServer SaaS',
        description: "Allows the user with this permissions to view the uptime page"
    },
    sdk: {
        code: 'sdk',
        name: 'ActiveSDK',
        license: true
    },

};
