// method to show notification and hide after 2 seconds
import {HIDE_NOTIFICATION, LOADING_FINISHED, NOW_LOADING, SHOW_NOTIFICATION} from "./actionTypes";

/**
 * @param header to show
 * @param message message description
 * @param format success, error
 * @returns {Function}
 */
export const showNotification = (header, message, format = "success") => {
    return (dispatch) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            header,
            message,
            format
        });

        setTimeout(() => dispatch({type: HIDE_NOTIFICATION}), 2000); // hide notification
    }
};

export const nowLoading = (isLoading) => {
    return (dispatch) => {
        if (isLoading) {
            dispatch({type: NOW_LOADING});
        } else {
            dispatch({type: LOADING_FINISHED});
        }
    }
};


/**
 * Converts an array to object with given id
 */
export const ArrayToObject = (array, index) =>
    array ? array.reduce((obj, item) => {
        obj[item[index]] = item;
        return obj
    }, {}) : null;
