import {Config, get, post, put} from './http'
import queryString from 'query-string';
import {CLEAR_SESSION, ORG_READY, ROLE_UPDATED, ROLES_READY, SESSION_READY} from "./actionTypes";
import History from '../routes/history';
import {showNotification} from "./utils";


export const STARTING_AUTH = 'STARTING_AUTH';


const doAuthRedirection = () => {

    const state = Math.random().toString(36).substring(7);


    console.log('generate state token: ' + state);
    console.log("current location: ", window.location.href)

    // TODO: Find out why it was saved in sessionStorage before
    // sessionStorage.setItem('sessionState', state);

    const redirectUrl = `${Config.authHost}/oauth/authorize?client_id=gp-trusted-client&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=token&scope=trust&state=${state}`;

    console.log('about to redirect to:', redirectUrl);

    window.location.href = redirectUrl;


};


const startingAuth = () => {
    return {type: STARTING_AUTH}
};

const sessionReady = (token, user) => {

    return (dispatch) => {
        dispatch({type: SESSION_READY, token, user});

        fetchOrganization(token, dispatch);


    }


};


const fetchOrganization = (token, dispatch) => {
    get({token}, Config.authHost + "/api/v1/user/org")
        .then(function (response) {
            if (!response.ok) {

                throw Error(response.status, response.statusText);
            }
            return response.json();
        }).then((body) => {

        console.log('fetch org returns body', body);

        dispatch({type: ORG_READY, payload: body.data});


    }).catch((error) => {

        console.log("fetch org error", error)


    })
}

export const fetchUser = (token, dispatch, updateUrl = true) => {
    get({token}, Config.authHost + "/api/v1/user")
        .then(function (response) {
            if (!response.ok) {

                throw Error(response.status, response.statusText);
            }
            return response.json();
        }).then((body) => {

        console.log('fetch user returns body', body);

        dispatch(sessionReady(token, body));


        if (updateUrl) {


            //to remove the hash which contains access key
            const pathWithoutHash = window.location.pathname + window.location.search;


            console.log('url after fetch user: ', pathWithoutHash);

            //redirect to the path, replace the current path to remove the access token in url.
            History.replace(pathWithoutHash);
        }

    }).catch((error) => {

        console.log("error", error)
        const status = error.response ? error.response.status : "unknown, maybe local error.";

        console.log("user info error: ", status);


    })
}


export const tryAuth = (session, url) => {


    console.log("current config: ", Config);
    console.log("current env", process.env.NODE_ENV);
    console.log("init auth redirection url", url);

    //to return function for the middleware to handle
    return (dispatch) => {

        if (session.loggedIn && session.user) {
            dispatch(sessionReady(session.token, session.user));
            //should check the session valid or not.

            return;
        }

        dispatch(startingAuth());


        console.log("the auth url:", url);
        const parsed = queryString.parse(url);

        console.log(parsed);

        if (parsed && parsed.access_token) {

            //we have the token now.
            fetchUser(parsed.access_token, dispatch)

        } else {

            doAuthRedirection();

        }


    }
}

export const logout = () => {

    return (dispatch) => {

        dispatch({type: CLEAR_SESSION});

        //TODO: How to logout user from oauth2 server?
        //We tried iframe but not possible if x-frame-options are configured
        //Looks like the best option is to redirect user to the auto logout page on the
        //oauth2 server.
        window.location.href = `${Config.authHost}/logout`;

    }


};


export const getRoles = () => {
    return (dispatch, getState) => {

        return get({token: getState().session.token}, Config.authHost + "/api/v1/admin/roles")
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

                console.log('fetch roles returns body', body);

                dispatch({type: ROLES_READY, roles: body});

            }).catch((error) => {
                console.log("fetch roles failed", error)
            })
    }
};

export const updateRole = (currentRole, permissions) => {
    return (dispatch, getState) => {

        return put({token: getState().session.token}, Config.authHost + "/api/v1/admin/roles", {
            name: currentRole.roleName,
            description: currentRole.roleDescription,
            permissions: permissions,
        })
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

                console.log('role updated', body);

                dispatch({type: ROLE_UPDATED, role: body});

                dispatch(showNotification("Success", "Role updated successfully"));

            }).catch((error) => {
                console.log("failed to update role", error);

                dispatch(showNotification("Failed", "Failed to update role", "error"));
            })
    }
};


export const createRole = (values) => {
    return (dispatch, getState) => {

        return post({token: getState().session.token}, Config.authHost + "/api/v1/admin/roles", values)
            .then(function (response) {
                if (!response.ok) {

                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

                console.log('role updated', body);

                dispatch({type: ROLE_UPDATED, role: body});

                dispatch(showNotification("Success", "Role created successfully"));

            }).catch((error) => {
                console.log("failed to update role", error);

                dispatch(showNotification("Failed", "Failed to create role", "error"));
            })
    }
};

