import React from 'react';
import {Grid, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import {switchProduct} from "../../../actions/release";
import {VersionSummary} from "./VersionSummary";
import {VersionList} from "./VersionList";
import {Config} from "../../../actions/http";
import PackageInfo from "./PackageInfo";
import {CustomPlaceholder} from "../../CustomPlaceholder";
import PromotePullbackModal from "./PromotePullbackModal"
import {OPEN_PROMOTE_PULLBACK_MODAL} from "../../../actions/actionTypes.js";
import {products} from "../../../constants/products";


class ReleaseView extends React.Component {

    constructor() {
        super();
        this.state = {
            activeItem: 'versionSummary',
        };
    }

    handleItemClick = (e, {name}) => {
        this.setState({
            activeItem: name
        });
    };

    componentDidMount() {
        console.log('Release page mounted');
        const {switchPrd} = this.props;
        switchPrd(null, {name: products.ame.code});
    }

    render() {
        const {
            currProduct,
            currVersion,
            switchPrd,
            versionList
        } = this.props;

        const {activeItem} = this.state;

        return <Grid padded={true}>

            <Grid.Column width="seven">
                <Menu pointing attached="top">
                    {Object.values(products).map((product, i) => <Menu.Item key={i} name={product.code}
                                                                            active={currProduct === product.code}
                                                                            onClick={switchPrd}>{product.name}</Menu.Item>
                    )}

                </Menu>
                {!versionList || versionList.length === 0 ?
                    <CustomPlaceholder iconName='archive' header={`No versions found for this product`}/> :
                    <VersionList/>}

            </Grid.Column>
            <Grid.Column width="nine">

                <Menu pointing attached="top">
                    <Menu.Item header>{currVersion ? "v" + currVersion.version : ""}</Menu.Item>
                    <Menu.Item name='versionSummary' active={activeItem === 'versionSummary'}
                               onClick={this.handleItemClick}>Version Summary</Menu.Item>
                    <Menu.Item name='packageInfo' active={activeItem === 'packageInfo'}
                               onClick={this.handleItemClick}>Package Info</Menu.Item>

                    {currVersion && <Menu.Menu position="right">

                        <Menu.Item disabled={!currVersion.promoted} name='Pullback' onClick={() => {
                            // this.setState({...this.state, doAction: () => pullback(currProduct, currVersion.version)});
                            this.props.openPromotePullbackModal(true, "pullback");
                        }}/>
                        <Menu.Item disabled={currVersion.promoted} name='Promote' onClick={() => {
                            // this.setState({...this.state, doAction: () => promote(currProduct, currVersion.version)});
                            this.props.openPromotePullbackModal(true, "promote");
                        }}/>
                        <Menu.Item name='Preview On MyAccount'
                                   onClick={() => window.open(`${Config.myAccountUrl}/releases/${currProduct}/v/${currVersion.version}`)}/>
                    </Menu.Menu>}
                </Menu>

                {currVersion && <PromotePullbackModal/>}

                {activeItem === 'versionSummary' && (currVersion ?
                    <VersionSummary/>
                    :
                    <CustomPlaceholder iconName='edit outline' header={`No version summary`}/>)
                }
                {activeItem === 'packageInfo' &&
                <PackageInfo/>
                }

            </Grid.Column>

        </Grid>


    }
}

const mapStateToProps = (state) => ({
    currProduct: state.releases.currentSelectedProduct,
    currVersion: state.releases.currentSelectedVersion,
    versionList: state.releases.versionList[state.releases.currentSelectedProduct],
});

const mapDispatchToProps = (dispatch) => ({
    switchPrd: (e, {name}) => dispatch(switchProduct(name)),
    openPromotePullbackModal: (open, modalType) => dispatch({type: OPEN_PROMOTE_PULLBACK_MODAL, open, modalType}),
});


export const Release = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReleaseView);

