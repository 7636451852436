import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

import {applyMiddleware, compose, createStore} from 'redux';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

import reducers from './reducers';
import {tryAuth} from './actions/auth';
import "react-datepicker/dist/react-datepicker.css"
import App from "./App";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";

const loggerMiddleware = createLogger();
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
);

store.dispatch(tryAuth({}, window.location.hash));


ReactDOM.render(
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <App/>
        </MuiPickersUtilsProvider>
    </Provider>
    , document.getElementById('root'));
registerServiceWorker();
