import React, {useEffect} from 'react'
import {Header, Pagination, Table} from 'semantic-ui-react'
import {getDownloadHistory} from "../../../actions/release";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";

const headers = ["Date", "Organisation", "Email", "First Name", "Last Name"];

const DownloadHistoryTable = (props) => {

    const {currentSelectedPackage: selectedFile} = useSelector(state => state.releases);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDownloadHistory(0, 10));

    }, [dispatch]);

    return (
        <div>
            <Header as="h4">Download History</Header>

            <Table size='large'>
                <Table.Header>

                    <Table.Row>
                        {headers.map(header => (
                            <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {selectedFile && selectedFile.downloadHistory && selectedFile.downloadHistory.content.map((log, i) => (
                        <Table.Row key={i}>
                            <Table.Cell>{moment(log.downloadDate).format("DD/MM/YYYY HH:mm:ss")}</Table.Cell>
                            <Table.Cell>{log.orgName}</Table.Cell>
                            <Table.Cell>{log.userEmail}</Table.Cell>
                            <Table.Cell>{log.firstName}</Table.Cell>
                            <Table.Cell>{log.lastName}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='9'>
                            {(selectedFile && selectedFile.downloadHistory && selectedFile.downloadHistory.totalPages > 1) &&

                            <Pagination defaultActivePage={selectedFile.downloadHistory.number + 1}
                                        totalPages={selectedFile.downloadHistory.totalPages}
                                        onPageChange={(event, data) => dispatch(getDownloadHistory(data.activePage - 1))}
                            />
                            }
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

export default DownloadHistoryTable;