import React, {useEffect, useState} from 'react';
import {Button, Divider, Dropdown, Header, Icon, Segment, Table} from "semantic-ui-react";
import {getPemisssion, PERMISSIONS} from "../../../constants/session";
import {useDispatch, useSelector} from "react-redux";
import {updateRole} from "../../../actions/auth";

const PermissionSettings = () => {

    const {selectedRole} = useSelector(state => state.session);
    const [permissions, setPermissions] = useState({});

    const dispatch = useDispatch();


    useEffect(() => {
        if (selectedRole) {

            let currentPermissions = {};

            selectedRole.permissions.forEach(p => {

                currentPermissions[p] = {
                    value: p,
                    label: getPemisssion(p).label,
                }
            });
            setPermissions(currentPermissions);
        }

    }, [selectedRole]);

    const updateRolePermissions = () => {
        dispatch(updateRole(selectedRole, Object.values(permissions).map(p => p.value)))
    };

    const addPermission = (id, permission) => {

        setPermissions({
            ...permissions,
            [id]: permission
        })
    };

    const removePermission = (id) => {
        const newPermissions = {...permissions};

        delete newPermissions[id];

        setPermissions(newPermissions);
    };

    return (
        <div>
            <Segment attached='bottom' padded>
                <Header
                    as="h3">Role {selectedRole && selectedRole.roleName}</Header>
                <Divider/>

                <Header as="h4">Assigned permissions</Header>
                <Table striped selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Permission name</Table.HeaderCell>
                            <Table.HeaderCell>Permission description</Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {Object.values(permissions).map((permission, index) => (
                                <Table.Row key={permission.value}>
                                    <Table.Cell>{permission.value}</Table.Cell>
                                    <Table.Cell>{permission.label}</Table.Cell>
                                    <Table.Cell>
                                        <Button onClick={() => removePermission(permission.value)} icon
                                                size="mini">
                                            <Icon name="close"/>
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        )}
                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <Dropdown text='Add Permission' icon='add' floating labeled button className='icon'>
                                    <Dropdown.Menu>
                                        <Dropdown.Header icon='key' content='List of all permissions'/>

                                        <Dropdown.Menu scrolling>
                                            {Object.values(PERMISSIONS).map((permission, i) => {

                                                let label = {color: 'red', empty: true, circular: true};

                                                // change the label to green if permissionId is in currentUser
                                                if (permissions && permission.value in permissions) {
                                                    label.color = 'green';
                                                }

                                                return (
                                                    <Dropdown.Item
                                                        key={permission.value}
                                                        onClick={() => addPermission(permission.value, permission)}
                                                        text={permission.value + " - " + permission.label}
                                                        value={permission.value}
                                                        label={label}/>
                                                )
                                            })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Button onClick={() => updateRolePermissions()} primary>Save Change</Button>
            </Segment>

        </div>
    );
};

export default PermissionSettings;
