import React from 'react';
import PropTypes from "prop-types";
import {Pagination, Table} from "semantic-ui-react";
import {uid} from "react-uid";

const SortTable = (props) => {

    const {pageData, definitions, onPageChange, onRowClick} = props;

    return (
        <div>
            <Table striped selectable>
                <Table.Header>
                    <Table.Row>
                        {definitions.map(header => (
                            <Table.HeaderCell key={header.key}>{header.label}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                {pageData && <Table.Body>
                    {pageData.content.map(row =>
                        <Table.Row key={uid(row)} onClick={() => onRowClick(row)}>
                            {definitions.map(d => <Table.Cell key={d.key}>{d.render(row)}</Table.Cell>)}
                        </Table.Row>
                    )}
                </Table.Body>}

                {(pageData && pageData.totalPages > 1) && <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='9'>
                            <Pagination defaultActivePage={pageData.number + 1}
                                        totalPages={pageData.totalPages}
                                        onPageChange={(event, data) => onPageChange(data.activePage - 1)}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
                }
            </Table>
        </div>
    );
};

SortTable.propTypes = {
    pageData: PropTypes.object,
    definitions: PropTypes.array,
    onPageChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
};

export default SortTable;