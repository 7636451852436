import React from 'react';
import {connect} from "react-redux";
import {Pagination, Radio, Table} from "semantic-ui-react";
import {listRemoteUsers, selectUser} from "../../../actions/users";
import {SHOW_CONFIRMATION_MODAL, SHOW_USER_ADD_MODAL} from "../../../actions/actionTypes.js";
import moment from "moment";

export const userTableHeaders = {

    enabled: {
        key: "enabled",
        sortable: false, // currently only the user info table and lastLogin is sortable
        value: "enabled",
        text: "Enabled",
    },
    userEmail: {
        key: "email",
        sortable: true,
        value: "userEmail",
        text: "Email"
    },
    firstName: {
        key: "firstName",
        sortable: true,
        value: "firstName",
        text: "First Name"
    },
    lastName: {
        key: "lastName",
        sortable: true,
        value: "lastName",
        text: "Last Name"
    },
    organisation: {
        key: "org",
        sortable: false,
        value: "organisation",
        text: "Organisation"
    },
    // role: {
    //     key: "role",
    //     sortable: false,
    //     value: "role",
    //     text: "Role"
    // },
    lastLogin: {
        key: "lastLogin",
        sortable: true,
        value: "lastLogin",
        text: "Last Login"
    }
};

class UserListView extends React.Component {

    componentWillMount() {
        this.props.listUsers(0, this.props.searchKeyword);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.searchKeyword !== this.props.searchKeyword) {
            this.props.listUsers(0, nextProps.searchKeyword);
        }
    }

    render() {
        const {userPage, listUsers, selectUser, currentSelectedUser, showConfirmationModal, searchKeyword} = this.props;

        return (

            <div>
                <Table striped selectable>
                    <Table.Header>
                        <Table.Row>
                            {Object.values(userTableHeaders).map(header => (
                                <Table.HeaderCell key={header.key}>{header.text}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>

                    {userPage && <Table.Body>

                        {userPage.content.map(u =>
                            <Table.Row key={u.email} onClick={() => selectUser(u)}
                                       active={currentSelectedUser && u.userId === currentSelectedUser.userId}
                            >
                                <Table.Cell>
                                    <Radio onClick={showConfirmationModal} toggle checked={u.enabled}/>
                                </Table.Cell>
                                <Table.Cell disabled={!u.enabled}>{u.email}</Table.Cell>
                                <Table.Cell disabled={!u.enabled}>{u.firstName}</Table.Cell>
                                <Table.Cell disabled={!u.enabled}>{u.lastName}</Table.Cell>
                                <Table.Cell disabled={!u.enabled}>{u.organization}</Table.Cell>
                                <Table.Cell disabled={!u.enabled}>
                                    {u.lastLogin ? moment(u.lastLogin).fromNow() : "N/A"}
                                </Table.Cell>
                            </Table.Row>
                        )}

                    </Table.Body>}

                    {(userPage && userPage.totalPages > 1) && <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='9'>
                                <Pagination defaultActivePage={userPage.number + 1}
                                            totalPages={userPage.totalPages}
                                            onPageChange={(event, data) => listUsers(data.activePage - 1, searchKeyword)}
                                />
                            </Table.HeaderCell>
                        </Table.Row>

                    </Table.Footer>
                    }
                </Table>

            </div>

        );
    }
}


const mapStateToProps = (state) => ({
    user: state.session.user,
    isAdmin: state.session.admin,
    userPage: state.users.currentPage,
    currentSelectedUser: state.users.currentUser,
    searchKeyword: state.users.searchKeyword,
    filter: state.users.filter
});

const mapDispatchToProps = (dispatch) => ({
    listUsers: (page, keyword) => dispatch(listRemoteUsers(page, keyword)),
    selectUser: (user) => dispatch(selectUser(user)),
    showConfirmationModal: () => dispatch({type: SHOW_CONFIRMATION_MODAL}),
    showUserAddModal: () => dispatch({type: SHOW_USER_ADD_MODAL}),

});


export const UserList = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserListView);

