import React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {promoteVersion, switchProduct} from "../../../actions/release";
import {connect} from "react-redux";
import {OPEN_PROMOTE_PULLBACK_MODAL} from "../../../actions/actionTypes.js";
import {globalStyles} from "../../styles";

class PromotePullbackModal extends React.Component {

    constructor(props) {
        super();
        this.state = {
            error: false,
            date: new Date()
        };
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleConfirm = () => {

        const {modal, currVersion} = this.props;

        if (modal.type === 'promote' && !this.state.date) {
            this.setState({error: true});
        } else {
            currVersion.releaseDate = this.state.date;
            this.props.confirmAction(modal.type, currVersion)
        }
    };

    handleChange(date) {
        this.setState({
            date: date
        });
    }

    render() {

        const {currVersion, modal} = this.props;

        return (
            <Modal
                style={globalStyles.modal}
                open={modal.open}
                onClose={() => this.props.openPromotePullbackModal(!modal.open, modal.type)}
                size='small'
            >
                <Modal.Header>Confirm {modal.type}</Modal.Header>
                <Modal.Content>

                    <p>Are you sure to {modal.type} version {currVersion.version}? This change will be propagated
                        immediately.</p>
                    {modal.type === 'promote' &&
                    <div>
                        <p>Release date</p>
                        <DatePicker
                            dropdownMode={"scroll"}
                            selected={this.state.date}
                            onChange={this.handleChange}
                            dateFormat={"dd-MM-yyyy"}
                        />

                        {this.state.error &&
                        <Message negative>
                            <Message.Header>Please select valid date</Message.Header>
                            <p>You must select valid date to promote</p>
                        </Message>
                        }
                    </div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button icon='check' positive content='Confirm' onClick={this.handleConfirm}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    currVersion: state.releases.currentSelectedVersion,
    modal: state.releases.promotePullbackModal
});

const mapDispatchToProps = (dispatch) => ({
    switchPrd: (e, {name}) => dispatch(switchProduct(name)),
    confirmAction: (action, version) => dispatch(promoteVersion(action, version)),
    openPromotePullbackModal: (open, modalType) => dispatch({type: OPEN_PROMOTE_PULLBACK_MODAL, open, modalType}),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromotePullbackModal);
