import React from "react";
import {connect} from "react-redux";
import {Pagination, Table} from "semantic-ui-react";
import {listRemoteServers} from "../../../actions/licenses";
import {withRouter} from "react-router-dom";
import moment from "moment";
import {CustomPlaceholder} from "../../CustomPlaceholder";
import {SELECT_SERVER} from "../../../actions/actionTypes";


const tableHeaders = {
    serverName: {
        key: "serverName",
        text: "Server Name"
    },
    externalUrl: {
        key: "url",
        text: "External URL"
    },
    regDate: {
        key: "regDate",
        text: "Registered Date"
    },
    actDate: {
        key: "actDate",
        text: "Activated Date"
    },
};

class ASServerList extends React.Component {

    componentWillMount() {
        const {orgId} = this.props;
        this.props.listInstances(orgId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    render() {
        const {serversPage, orgId, currServer} = this.props;

        if (!serversPage || serversPage.totalPages === 0) {
            return <CustomPlaceholder iconName='file outline' header='No server instances for this oragnisation.'/>
        }

        return (

            <div>
                <Table striped selectable>
                    <Table.Header>
                        <Table.Row>
                            {Object.values(tableHeaders).map(header => (
                                <Table.HeaderCell key={header.key}>{header.text}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    {serversPage && <Table.Body>

                        {serversPage.content.map(server =>
                            <Table.Row key={server.id} onClick={() => this.props.selectInstance(server)}
                                       active={currServer && server.id === currServer.id}
                            >

                                <Table.Cell>{server.name}</Table.Cell>
                                <Table.Cell>{server.url}</Table.Cell>
                                <Table.Cell>{moment(server.registeredDate).format('lll')}</Table.Cell>
                                <Table.Cell>{server.activatedDate ? moment(server.activatedDate).format('lll') : 'Not Yet'}</Table.Cell>
                            </Table.Row>
                        )}

                    </Table.Body>}

                    {(serversPage && serversPage.totalPages > 1) && <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='9'>
                                <Pagination defaultActivePage={serversPage.number + 1}
                                            totalPages={serversPage.totalPages}
                                            onPageChange={(event, data) => this.props.listInstances(orgId, data.activePage - 1)}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                    }
                </Table>

            </div>

        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    currentOrg: state.orgs.currentOrg,
    serversPage: state.licenses.serversPage,
    orgId: ownProps.location.pathname.split('/')[2],
    currServer: state.licenses.currServer
});

const mapDispatchToProps = (dispatch) => ({
    listInstances: (orgId, page = 0) => dispatch(listRemoteServers(orgId, page)),
    selectInstance: (server) => dispatch({type: SELECT_SERVER, server})
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ASServerList));