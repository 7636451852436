import {
    CLEAR_SESSION,
    HIDE_NOTIFICATION,
    LOADING_FINISHED,
    NOW_LOADING,
    ROLE_SELECTED,
    ROLE_UPDATED,
    ROLES_READY,
    SESSION_READY,
    SHOW_NOTIFICATION
} from '../actions/actionTypes';
import {ArrayToObject} from "../actions/utils";

const initialSettings = {
    admin: false,
    user: null,
    token: {},
    notification: null,
    isLoading: false,
    roles: null,
    selectedRole: null,
};

export const userIsOrgRoot = (user) => user && user.authorities && user.authorities.includes('ROLE_ROOT_ORG');
export const userIsAdmin = (user) => user && user.authorities && user.authorities.includes('ROLE_ADMIN');


export const session = (state = initialSettings, action) => {
    switch (action.type) {
        case SESSION_READY:
            return {
                ...state,
                user: action.user,
                token: action.token,
                admin: userIsAdmin(action.user)
            };

        case CLEAR_SESSION:
            return {
                ...state,
                user: {},
                token: {},
            };

        case SHOW_NOTIFICATION:
            return {
                ...state,
                notification: {
                    header: action.header,
                    message: action.message,
                    format: action.format,
                }
            };
        case HIDE_NOTIFICATION:
            return {
                ...state,
                notification: null
            };
        case NOW_LOADING: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case LOADING_FINISHED: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ROLES_READY: {
            return {
                ...state,
                roles: ArrayToObject(action.roles, 'roleName'),
            }
        }
        case ROLE_UPDATED: {
            return {
                ...state,
                roles: {
                    ...state.roles,
                    [action.role.roleName]: action.role,
                }
            }

        }
        case ROLE_SELECTED: {
            return {
                ...state,
                selectedRole: action.role,
            }

        }
        default:
            return state;
    }
};




