export const isoCountries = [
    {key: 'AF', value: 'AF', text: 'Afghanistan'},
    {key: 'AX', value: 'AX', text: 'Aland Islands'},
    {key: 'AL', value: 'AL', text: 'Albania'},
    {key: 'DZ', value: 'DZ', text: 'Algeria'},
    {key: 'AS', value: 'AS', text: 'American Samoa'},
    {key: 'AD', value: 'AD', text: 'Andorra'},
    {key: 'AO', value: 'AO', text: 'Angola'},
    {key: 'AI', value: 'AI', text: 'Anguilla'},
    {key: 'AQ', value: 'AQ', text: 'Antarctica'},
    {key: 'AG', value: 'AG', text: 'Antigua And Barbuda'},
    {key: 'AR', value: 'AR', text: 'Argentina'},
    {key: 'AM', value: 'AM', text: 'Armenia'},
    {key: 'AW', value: 'AW', text: 'Aruba'},
    {key: 'AU', value: 'AU', text: 'Australia'},
    {key: 'AT', value: 'AT', text: 'Austria'},
    {key: 'AZ', value: 'AZ', text: 'Azerbaijan'},
    {key: 'BS', value: 'BS', text: 'Bahamas'},
    {key: 'BH', value: 'BH', text: 'Bahrain'},
    {key: 'BD', value: 'BD', text: 'Bangladesh'},
    {key: 'BB', value: 'BB', text: 'Barbados'},
    {key: 'BY', value: 'BY', text: 'Belarus'},
    {key: 'BE', value: 'BE', text: 'Belgium'},
    {key: 'BZ', value: 'BZ', text: 'Belize'},
    {key: 'BJ', value: 'BJ', text: 'Benin'},
    {key: 'BM', value: 'BM', text: 'Bermuda'},
    {key: 'BT', value: 'BT', text: 'Bhutan'},
    {key: 'BO', value: 'BO', text: 'Bolivia'},
    {key: 'BA', value: 'BA', text: 'Bosnia And Herzegovina'},
    {key: 'BW', value: 'BW', text: 'Botswana'},
    {key: 'BV', value: 'BV', text: 'Bouvet Island'},
    {key: 'BR', value: 'BR', text: 'Brazil'},
    {key: 'IO', value: 'IO', text: 'British Indian Ocean Territory'},
    {key: 'BN', value: 'BN', text: 'Brunei Darussalam'},
    {key: 'BG', value: 'BG', text: 'Bulgaria'},
    {key: 'BF', value: 'BF', text: 'Burkina Faso'},
    {key: 'BI', value: 'BI', text: 'Burundi'},
    {key: 'KH', value: 'KH', text: 'Cambodia'},
    {key: 'CM', value: 'CM', text: 'Cameroon'},
    {key: 'CA', value: 'CA', text: 'Canada'},
    {key: 'CV', value: 'CV', text: 'Cape Verde'},
    {key: 'KY', value: 'KY', text: 'Cayman Islands'},
    {key: 'CF', value: 'CF', text: 'Central African Republic'},
    {key: 'TD', value: 'TD', text: 'Chad'},
    {key: 'CL', value: 'CL', text: 'Chile'},
    {key: 'CN', value: 'CN', text: 'China'},
    {key: 'CX', value: 'CX', text: 'Christmas Island'},
    {key: 'CC', value: 'CC', text: 'Cocos (Keeling) Islands'},
    {key: 'CO', value: 'CO', text: 'Colombia'},
    {key: 'KM', value: 'KM', text: 'Comoros'},
    {key: 'CG', value: 'CG', text: 'Congo'},
    {key: 'CD', value: 'CD', text: 'Congo}, Democratic Republic'},
    {key: 'CK', value: 'CK', text: 'Cook Islands'},
    {key: 'CR', value: 'CR', text: 'Costa Rica'},
    {key: 'CI', value: 'CI', text: 'Cote D\'Ivoire'},
    {key: 'HR', value: 'HR', text: 'Croatia'},
    {key: 'CU', value: 'CU', text: 'Cuba'},
    {key: 'CY', value: 'CY', text: 'Cyprus'},
    {key: 'CZ', value: 'CZ', text: 'Czech Republic'},
    {key: 'DK', value: 'DK', text: 'Denmark'},
    {key: 'DJ', value: 'DJ', text: 'Djibouti'},
    {key: 'DM', value: 'DM', text: 'Dominica'},
    {key: 'DO', value: 'DO', text: 'Dominican Republic'},
    {key: 'EC', value: 'EC', text: 'Ecuador'},
    {key: 'EG', value: 'EG', text: 'Egypt'},
    {key: 'SV', value: 'SV', text: 'El Salvador'},
    {key: 'GQ', value: 'GQ', text: 'Equatorial Guinea'},
    {key: 'ER', value: 'ER', text: 'Eritrea'},
    {key: 'EE', value: 'EE', text: 'Estonia'},
    {key: 'ET', value: 'ET', text: 'Ethiopia'},
    {key: 'FK', value: 'FK', text: 'Falkland Islands (Malvinas)'},
    {key: 'FO', value: 'FO', text: 'Faroe Islands'},
    {key: 'FJ', value: 'FJ', text: 'Fiji'},
    {key: 'FI', value: 'FI', text: 'Finland'},
    {key: 'FR', value: 'FR', text: 'France'},
    {key: 'GF', value: 'GF', text: 'French Guiana'},
    {key: 'PF', value: 'PF', text: 'French Polynesia'},
    {key: 'TF', value: 'TF', text: 'French Southern Territories'},
    {key: 'GA', value: 'GA', text: 'Gabon'},
    {key: 'GM', value: 'GM', text: 'Gambia'},
    {key: 'GE', value: 'GE', text: 'Georgia'},
    {key: 'DE', value: 'DE', text: 'Germany'},
    {key: 'GH', value: 'GH', text: 'Ghana'},
    {key: 'GI', value: 'GI', text: 'Gibraltar'},
    {key: 'GR', value: 'GR', text: 'Greece'},
    {key: 'GL', value: 'GL', text: 'Greenland'},
    {key: 'GD', value: 'GD', text: 'Grenada'},
    {key: 'GP', value: 'GP', text: 'Guadeloupe'},
    {key: 'GU', value: 'GU', text: 'Guam'},
    {key: 'GT', value: 'GT', text: 'Guatemala'},
    {key: 'GG', value: 'GG', text: 'Guernsey'},
    {key: 'GN', value: 'GN', text: 'Guinea'},
    {key: 'GW', value: 'GW', text: 'Guinea-Bissau'},
    {key: 'GY', value: 'GY', text: 'Guyana'},
    {key: 'HT', value: 'HT', text: 'Haiti'},
    {key: 'HM', value: 'HM', text: 'Heard Island & Mcdonald Islands'},
    {key: 'VA', value: 'VA', text: 'Holy See (Vatican City State)'},
    {key: 'HN', value: 'HN', text: 'Honduras'},
    {key: 'HK', value: 'HK', text: 'Hong Kong'},
    {key: 'HU', value: 'HU', text: 'Hungary'},
    {key: 'IS', value: 'IS', text: 'Iceland'},
    {key: 'IN', value: 'IN', text: 'India'},
    {key: 'ID', value: 'ID', text: 'Indonesia'},
    {key: 'IR', value: 'IR', text: 'Iran'},
    {key: 'IQ', value: 'IQ', text: 'Iraq'},
    {key: 'IE', value: 'IE', text: 'Ireland'},
    {key: 'IM', value: 'IM', text: 'Isle Of Man'},
    {key: 'IL', value: 'IL', text: 'Israel'},
    {key: 'IT', value: 'IT', text: 'Italy'},
    {key: 'JM', value: 'JM', text: 'Jamaica'},
    {key: 'JP', value: 'JP', text: 'Japan'},
    {key: 'JE', value: 'JE', text: 'Jersey'},
    {key: 'JO', value: 'JO', text: 'Jordan'},
    {key: 'KZ', value: 'KZ', text: 'Kazakhstan'},
    {key: 'KE', value: 'KE', text: 'Kenya'},
    {key: 'KI', value: 'KI', text: 'Kiribati'},
    {key: 'KR', value: 'KR', text: 'Korea'},
    {key: 'KW', value: 'KW', text: 'Kuwait'},
    {key: 'KG', value: 'KG', text: 'Kyrgyzstan'},
    {key: 'LA', value: 'LA', text: 'Lao People\'s Democratic Republic'},
    {key: 'LV', value: 'LV', text: 'Latvia'},
    {key: 'LB', value: 'LB', text: 'Lebanon'},
    {key: 'LS', value: 'LS', text: 'Lesotho'},
    {key: 'LR', value: 'LR', text: 'Liberia'},
    {key: 'LY', value: 'LY', text: 'Libyan Arab Jamahiriya'},
    {key: 'LI', value: 'LI', text: 'Liechtenstein'},
    {key: 'LT', value: 'LT', text: 'Lithuania'},
    {key: 'LU', value: 'LU', text: 'Luxembourg'},
    {key: 'MO', value: 'MO', text: 'Macao'},
    {key: 'MK', value: 'MK', text: 'Macedonia'},
    {key: 'MG', value: 'MG', text: 'Madagascar'},
    {key: 'MW', value: 'MW', text: 'Malawi'},
    {key: 'MY', value: 'MY', text: 'Malaysia'},
    {key: 'MV', value: 'MV', text: 'Maldives'},
    {key: 'ML', value: 'ML', text: 'Mali'},
    {key: 'MT', value: 'MT', text: 'Malta'},
    {key: 'MH', value: 'MH', text: 'Marshall Islands'},
    {key: 'MQ', value: 'MQ', text: 'Martinique'},
    {key: 'MR', value: 'MR', text: 'Mauritania'},
    {key: 'MU', value: 'MU', text: 'Mauritius'},
    {key: 'YT', value: 'YT', text: 'Mayotte'},
    {key: 'MX', value: 'MX', text: 'Mexico'},
    {key: 'FM', value: 'FM', text: 'Micronesia}, Federated States Of'},
    {key: 'MD', value: 'MD', text: 'Moldova'},
    {key: 'MC', value: 'MC', text: 'Monaco'},
    {key: 'MN', value: 'MN', text: 'Mongolia'},
    {key: 'ME', value: 'ME', text: 'Montenegro'},
    {key: 'MS', value: 'MS', text: 'Montserrat'},
    {key: 'MA', value: 'MA', text: 'Morocco'},
    {key: 'MZ', value: 'MZ', text: 'Mozambique'},
    {key: 'MM', value: 'MM', text: 'Myanmar'},
    {key: 'NA', value: 'NA', text: 'Namibia'},
    {key: 'NR', value: 'NR', text: 'Nauru'},
    {key: 'NP', value: 'NP', text: 'Nepal'},
    {key: 'NL', value: 'NL', text: 'Netherlands'},
    {key: 'AN', value: 'AN', text: 'Netherlands Antilles'},
    {key: 'NC', value: 'NC', text: 'New Caledonia'},
    {key: 'NZ', value: 'NZ', text: 'New Zealand'},
    {key: 'NI', value: 'NI', text: 'Nicaragua'},
    {key: 'NE', value: 'NE', text: 'Niger'},
    {key: 'NG', value: 'NG', text: 'Nigeria'},
    {key: 'NU', value: 'NU', text: 'Niue'},
    {key: 'NF', value: 'NF', text: 'Norfolk Island'},
    {key: 'MP', value: 'MP', text: 'Northern Mariana Islands'},
    {key: 'NO', value: 'NO', text: 'Norway'},
    {key: 'OM', value: 'OM', text: 'Oman'},
    {key: 'PK', value: 'PK', text: 'Pakistan'},
    {key: 'PW', value: 'PW', text: 'Palau'},
    {key: 'PS', value: 'PS', text: 'Palestinian Territory}, Occupied'},
    {key: 'PA', value: 'PA', text: 'Panama'},
    {key: 'PG', value: 'PG', text: 'Papua New Guinea'},
    {key: 'PY', value: 'PY', text: 'Paraguay'},
    {key: 'PE', value: 'PE', text: 'Peru'},
    {key: 'PH', value: 'PH', text: 'Philippines'},
    {key: 'PN', value: 'PN', text: 'Pitcairn'},
    {key: 'PL', value: 'PL', text: 'Poland'},
    {key: 'PT', value: 'PT', text: 'Portugal'},
    {key: 'PR', value: 'PR', text: 'Puerto Rico'},
    {key: 'QA', value: 'QA', text: 'Qatar'},
    {key: 'RE', value: 'RE', text: 'Reunion'},
    {key: 'RO', value: 'RO', text: 'Romania'},
    {key: 'RU', value: 'RU', text: 'Russian Federation'},
    {key: 'RW', value: 'RW', text: 'Rwanda'},
    {key: 'BL', value: 'BL', text: 'Saint Barthelemy'},
    {key: 'SH', value: 'SH', text: 'Saint Helena'},
    {key: 'KN', value: 'KN', text: 'Saint Kitts And Nevis'},
    {key: 'LC', value: 'LC', text: 'Saint Lucia'},
    {key: 'MF', value: 'MF', text: 'Saint Martin'},
    {key: 'PM', value: 'PM', text: 'Saint Pierre And Miquelon'},
    {key: 'VC', value: 'VC', text: 'Saint Vincent And Grenadines'},
    {key: 'WS', value: 'WS', text: 'Samoa'},
    {key: 'SM', value: 'SM', text: 'San Marino'},
    {key: 'ST', value: 'ST', text: 'Sao Tome And Principe'},
    {key: 'SA', value: 'SA', text: 'Saudi Arabia'},
    {key: 'SN', value: 'SN', text: 'Senegal'},
    {key: 'RS', value: 'RS', text: 'Serbia'},
    {key: 'SC', value: 'SC', text: 'Seychelles'},
    {key: 'SL', value: 'SL', text: 'Sierra Leone'},
    {key: 'SG', value: 'SG', text: 'Singapore'},
    {key: 'SK', value: 'SK', text: 'Slovakia'},
    {key: 'SI', value: 'SI', text: 'Slovenia'},
    {key: 'SB', value: 'SB', text: 'Solomon Islands'},
    {key: 'SO', value: 'SO', text: 'Somalia'},
    {key: 'ZA', value: 'ZA', text: 'South Africa'},
    {key: 'GS', value: 'GS', text: 'South Georgia And Sandwich Isl.'},
    {key: 'ES', value: 'ES', text: 'Spain'},
    {key: 'LK', value: 'LK', text: 'Sri Lanka'},
    {key: 'SD', value: 'SD', text: 'Sudan'},
    {key: 'SR', value: 'SR', text: 'Suriname'},
    {key: 'SJ', value: 'SJ', text: 'Svalbard And Jan Mayen'},
    {key: 'SZ', value: 'SZ', text: 'Swaziland'},
    {key: 'SE', value: 'SE', text: 'Sweden'},
    {key: 'CH', value: 'CH', text: 'Switzerland'},
    {key: 'SY', value: 'SY', text: 'Syrian Arab Republic'},
    {key: 'TW', value: 'TW', text: 'Taiwan'},
    {key: 'TJ', value: 'TJ', text: 'Tajikistan'},
    {key: 'TZ', value: 'TZ', text: 'Tanzania'},
    {key: 'TH', value: 'TH', text: 'Thailand'},
    {key: 'TL', value: 'TL', text: 'Timor-Leste'},
    {key: 'TG', value: 'TG', text: 'Togo'},
    {key: 'TK', value: 'TK', text: 'Tokelau'},
    {key: 'TO', value: 'TO', text: 'Tonga'},
    {key: 'TT', value: 'TT', text: 'Trinidad And Tobago'},
    {key: 'TN', value: 'TN', text: 'Tunisia'},
    {key: 'TR', value: 'TR', text: 'Turkey'},
    {key: 'TM', value: 'TM', text: 'Turkmenistan'},
    {key: 'TC', value: 'TC', text: 'Turks And Caicos Islands'},
    {key: 'TV', value: 'TV', text: 'Tuvalu'},
    {key: 'UG', value: 'UG', text: 'Uganda'},
    {key: 'UA', value: 'UA', text: 'Ukraine'},
    {key: 'AE', value: 'AE', text: 'United Arab Emirates'},
    {key: 'GB', value: 'GB', text: 'United Kingdom'},
    {key: 'US', value: 'US', text: 'United States'},
    {key: 'UM', value: 'UM', text: 'United States Outlying Islands'},
    {key: 'UY', value: 'UY', text: 'Uruguay'},
    {key: 'UZ', value: 'UZ', text: 'Uzbekistan'},
    {key: 'VU', value: 'VU', text: 'Vanuatu'},
    {key: 'VE', value: 'VE', text: 'Venezuela'},
    {key: 'VN', value: 'VN', text: 'Viet Nam'},
    {key: 'VG', value: 'VG', text: 'Virgin Islands}, British'},
    {key: 'VI', value: 'VI', text: 'Virgin Islands}, U.S.'},
    {key: 'WF', value: 'WF', text: 'Wallis And Futuna'},
    {key: 'EH', value: 'EH', text: 'Western Sahara'},
    {key: 'YE', value: 'YE', text: 'Yemen'},
    {key: 'ZM', value: 'ZM', text: 'Zambia'},
    {key: 'ZW', value: 'ZW', text: 'Zimbabwe'}
];

export const getCountryName = (countryCode) => {
    return isoCountries.filter(country => {
        return country.key === countryCode
    })[0];
};
