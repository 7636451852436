import React from 'react';
import {Grid, Segment} from "semantic-ui-react";
import {connect, useSelector} from "react-redux";
import {UserPermissions} from "./UserPermissions";
import {UserAccount} from "./UserAccount";
import Toolbar from "../Toolbar";
import {UserList} from "./UserList";
import {ConfirmationModal} from "./ConfirmationModal";
import {listAllRemotePermissions} from "../../../actions/users";
import SimpleTabMenu from "../../components/SimpleTabMenu";
import UserDetailView from "./UserDetail";


const UserDetailsFrom = () => {
    const {currentUser} = useSelector(state => state.users);
    return (<UserDetailView defaultValues={currentUser}/>)
};

const userMenuTabs = {
    details: {
        key: "details",
        tabName: "Details",
        component: <UserDetailsFrom/>
    },
    permissions: {
        key: "permissions",
        tabName: "Permissions",
        component: <UserPermissions/>
    }
    ,
    account: {
        key: "account",
        tabName: "Account",
        component: <UserAccount/>
    }
};

class UsersView extends React.Component {

    componentDidMount() {
        this.props.listAllPermissions();
    }

    render() {
        const {currentUser} = this.props;

        return (

            <Grid padded={true}>
                <Grid.Column widescreen={"10"} mobile={"16"} tablet={"16"}>
                    <Toolbar/>

                    <Segment padded>
                        <UserList/>
                    </Segment>
                </Grid.Column>

                {/*Show below only if curent user is enabled*/}

                <Grid.Column widescreen={"6"} mobile={"16"} tablet={"16"}>

                    {currentUser && currentUser.enabled &&
                    <SimpleTabMenu menu={userMenuTabs}/>
                    }

                </Grid.Column>
                <ConfirmationModal/>
            </Grid>


        )
    }


}


const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    listAllPermissions: () => dispatch(listAllRemotePermissions()),
});


export const UsersPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersView);

