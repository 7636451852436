import React, {useEffect} from 'react';
import {Table} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {getRoles} from "../../../actions/auth";
import {ROLE_SELECTED} from "../../../actions/actionTypes";

const RolesList = (props) => {

    const {roles} = useSelector(state => state.session);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getRoles());

    }, [dispatch]);

    const selectRole = (role) => {
        dispatch({type: ROLE_SELECTED, role: role});
    };

    return (
        <div>
            <Table striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Role name</Table.HeaderCell>
                        <Table.HeaderCell>Role description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {roles && Object.values(roles).map((role, index) => (
                        <Table.Row onClick={() => selectRole(role)} key={role.roleName}>
                            <Table.Cell>{role.roleName}</Table.Cell>
                            <Table.Cell>{role.roleDescription}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default RolesList;
