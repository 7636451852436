import React from 'react';
import {Button, Divider, Form, Header, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {InputField, SelectField} from 'react-semantic-redux-form';
import {updateOrgDetails} from "../../../actions/organizations";
import {isoCountries} from "../../../constants/countries";

const OrgDetailView = ({error, org, pristine, handleSubmit, updateOrgDetail, submitting, reset}) => <div>

    <Segment attached={"bottom"} padded as="form" onSubmit={handleSubmit(updateOrgDetail)}>

        <Header as="h3">Organisation: {org ? org.name : "Not Selected"}</Header>
        <Divider/>

        <Form as="div">
            {/*<Form.Group widths='equal'>*/}

                <Field name="name" label="Name" placeholder="Organisation name" component={InputField} autoFocus/>
                {/*<Field name="trackerUrl" label="Tracker Url" placeholder="https://tracker.creative.com.au"*/}
                {/*component={InputField}/>*/}
            {/*</Form.Group>*/}
            <Form.Group widths='equal'>

                <Field name="address" label="Address" placeholder="Address" component={InputField}/>
                <Field name="address1" label="Address1" placeholder="Address1" component={InputField}/>
            </Form.Group>

            <Form.Group widths='equal'>
                <Field name="city" label="City" placeholder="City" component={InputField}/>
                <Field name="state" label="State" placeholder="State" component={InputField}/>
            </Form.Group>
            <Form.Group widths='equal'>
                <Field name="country" label="Country" placeholder="Country" component={SelectField}
                       options={isoCountries}/>
                {/*<Field name="country" label="Country" placeholder="Country" component={InputField}/>*/}
                <Field name="postcode" label="Post Code" placeholder="Post Code" component={InputField}/>
            </Form.Group>
            <Form.Group widths='equal'>
                <Field name="businessType" label="Business type" placeholder="Business type" component={InputField}/>
                <Field name="email" label="Email" placeholder="Email" component={InputField}/>
            </Form.Group>
            <Form.Group widths='equal'>
                <Field name="contactPerson" label="Contact Person" placeholder="Contact Person" component={InputField}/>
                <Field name="phone" label="Phone Number" placeholder="Phone Number" component={InputField}/>
            </Form.Group>
            <Divider/>
            <Button type='submit' primary disabled={pristine || submitting}>Save Change</Button>
            <Button disabled={pristine || submitting} onClick={reset}>Undo Changes</Button>


            {error && <Message negative>
                <Message.Header>Update failed</Message.Header>
                <p>{error}</p>
            </Message>}

        </Form>


    </Segment>


</div>;


const OrgDetailsView = reduxForm({
    form: 'orgForm',
    validate: () => {
    },
    enableReinitialize: true
})(OrgDetailView);


const mapStateToProps = (state) => ({
    initialValues: state.orgs.currentOrg,
    org: state.orgs.currentOrg,
    isAdmin: state.session.admin,
});

const mapDispatchToProps = (dispatch) => ({
    updateOrgDetail: (values) => dispatch(updateOrgDetails(values)),
});


export const OrgDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgDetailsView);

