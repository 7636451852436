import {Config, get, post, put} from "./http";
import {CLOSE_USER_ADD_MODAL, PERMISSIONS_READY, SELECT_USER, USER_UPDATED, USERS_READY,} from "./actionTypes";
import {SubmissionError} from 'redux-form';
import {listRemoteOrganizations} from "./organizations";
import {showNotification} from "./utils";


export const ROLE_ADMIN = "ADMIN";
export const ROLE_USER = "USER";
export const ROLE_DEVELOPER = "DEVELOPER";
export const ROLE_FINANCIAL = "FINANCE";
export const ROLE_TECH_SUPPORT = "TECH_SUPPORT";
export const ROLE_RELEASE = "RELEASE";

export const ROLE_ORG_ROOT = "ROOT_ORG";
export const ROLE_ORG = "ORG";


export const isUserGranted = (user, role) => {
    if (user && user.authorities) {
        return user.authorities.includes(role);
    }

    return false;
};


const usersReady = (page) => {
    return {
        type: USERS_READY,
        page,
    }
};

export const selectUser = (user) => {
    return (dispatch, getState) => {
        get(getState().session, `${Config.authHost}/api/v1/admin/user/${user.userId}`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then(body => {
            if (body.success) {

                const user = body.data;

                console.log("select user", user);

                dispatch({type: SELECT_USER, user});

                // list the remote organization available
                dispatch(listRemoteOrganizations(0, 5, user.organization));

            }

        }).catch(error => {
            console.log("error", error)
        })
    }

};

const userUpdated = (user) => {
    return {
        type: USER_UPDATED,
        user
    }
};

export const listRemoteUsers = (page = 0, keyword = null) => {

    return (dispatch, getState) => {

        const {filter} = getState().users;

        let url = `${Config.authHost}/api/v1/admin/users?p=${page}&s=10`;

        if (filter) {
            url += `&sort=${filter.orderBy}`;

            // only sort last login by DSC
            if (filter.orderBy === "lastLogin") {
                url += `&sort-dir=DESC`;
            } else {
                url += '&sort-dir=ASC';
            }
        }

        if (keyword) {
            url += `&q=${keyword}`;
        }

        console.log('listing users for page', page);
        get(getState().session, url)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('fetch users', body);

            let data = body.data;

            dispatch(usersReady(data));

            console.log("data", data);

            if (data && data.content && data.content.length > 0) {
                //get the files
                dispatch(selectUser(data.content[0]));
            }
        }).catch((error) => {

            console.log("error", error)

        })


    }
};


export const updateUserDetails = (user) => {

    //convert flags to Role List.
    return (dispatch, getState) => {
        console.log('update user details', user);

        // convert permissions back to array
        user.permissions = Object.values(user.permissions);

        console.log("permissions", user.permissions);

        return post(getState().session, `${Config.authHost}/api/v1/admin/user/${user.userId}`, {...user,}).then(response => {
            console.log("response", response);
            if (!response.ok) {
                throw new SubmissionError(response.status + ': ' + response.statusText);
            }
            return response.json();
        }).then((body) => {

            console.log('user updated', body);

            let data = body.data;

            dispatch(userUpdated(data));

            dispatch(showNotification("Saved Changes", "You have successfully saved changes to the user!"));

        }).catch((error) => {

            console.log("error", error.message);
            dispatch(showNotification("Failed to update user!", error.message, "error"));
            throw new SubmissionError({_error: error});
        })


    }
};


export const updateUserPassword = (values) => {
    console.log("update user password", values);
    //convert flags to Role List.
    return (dispatch, getState) => {
        return post(getState().session, `${Config.authHost}/api/v1/admin/user/${values.userId}/password`, {
            ...values,
        }).then(response => {
            if (!response.ok) {
                throw new SubmissionError(response.status + ': ' + response.statusText);
            }
            return response.json();
        }).then((body) => {

            console.log('user updated', body);
            dispatch(showNotification("Saved Changes", "You have successfully updated user password!"));

        }).catch((error) => {

            console.log("error", error);

            throw new SubmissionError({_error: error});

        })
    }
};

/**
 * Add new users and close modal
 * @param user
 * @returns {function(*, *): Promise<T>}
 */
export const addNewUser = (user) => {
    return (dispatch, getState) => {
        return put(getState().session, `${Config.authHost}/api/v1/admin/user`, {
            ...user,
        }).then(response => {
            if (!response.ok) {
                throw new SubmissionError(response.status + ': ' + response.statusText);
            }
            return response.json();
        }).then((body) => {
            //list the last page, since it will be the last entry
            dispatch(listRemoteUsers(getState().users.currentPage.totalPages - 1));

            dispatch({type: CLOSE_USER_ADD_MODAL});

            dispatch(showNotification("New user added!", 'You have successfully created new user'));

        }).catch((error) => {

            console.log("error", error.message);

            dispatch({type: CLOSE_USER_ADD_MODAL});
            dispatch(showNotification("Failed to create new user!", error.message, "error"));

        })
    }
}

/**
 * Get all permissions
 * @param page
 * @returns {Function}
 */
export const listAllRemotePermissions = () => {

    return (dispatch, getState) => {
        get(getState().session, `${Config.authHost}/api/v1/admin/permissions`)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }
                return response.json();
            }).then((body) => {

            console.log('fetch users', body);

            const data = body.data;

            dispatch({type: PERMISSIONS_READY, permissions: data});

        }).catch((error) => {

            console.log("error", error);

            dispatch(showNotification("Failed to get permissions!", error.message, "error"));

        })
    }
};
