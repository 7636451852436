import React from 'react';
import {connect} from "react-redux";
import {Button, Icon, Pagination, Table} from "semantic-ui-react";
import {listRemoteOrganizations, selectOrg} from "../../../actions/organizations";
import {getCountryName} from "../../../constants/countries";
import {Link} from "react-router-dom";

class OrganizationListView extends React.Component {

    componentWillMount() {
        const {searchKeyword} = this.props;
        this.props.listOrgs(0, searchKeyword);
    }

    componentDidMount() {
        const {orgPage} = this.props;
        if (orgPage && orgPage.content.length > 0) {
            this.props.selectOrg(orgPage.content[0].id)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.searchKeyword !== this.props.searchKeyword) {
            this.props.listOrgs(0, nextProps.searchKeyword);
        }
    }

    render() {

        const {orgPage, listOrgs, selectOrg, currentSelectedOrg, searchKeyword} = this.props;

        return (

            <div>

                <Table striped selectable>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Organisation Name</Table.HeaderCell>
                            <Table.HeaderCell>Country</Table.HeaderCell>
                            <Table.HeaderCell>Business Type</Table.HeaderCell>
                            {/*<Table.HeaderCell>Email</Table.HeaderCell>*/}
                            {/*<Table.HeaderCell>Contact Person</Table.HeaderCell>*/}
                            {/*<Table.HeaderCell>Phone</Table.HeaderCell>*/}
                        </Table.Row>
                    </Table.Header>

                    {orgPage && <Table.Body>

                        {orgPage.content.map(org => {

                                const country = getCountryName(org.country);

                                return (
                                    <Table.Row key={org.name} onClick={() => selectOrg(org.id)}
                                               active={currentSelectedOrg && org.name === currentSelectedOrg.name}
                                    >
                                        <Table.Cell>{org.name}</Table.Cell>
                                        <Table.Cell>{country && country.text}</Table.Cell>
                                        <Table.Cell>{org.businessType}</Table.Cell>
                                        {/*<Table.Cell>{org.email}</Table.Cell>*/}
                                        {/*<Table.Cell>{org.contactPerson}</Table.Cell>*/}
                                        {/*<Table.Cell>{org.phone}</Table.Cell>*/}
                                    </Table.Row>)
                            }
                        )}

                    </Table.Body>}

                    <Table.Footer>

                        <Table.Row>
                            <Table.HeaderCell colSpan='9'>
                                {(orgPage && orgPage.totalPages > 1) &&

                                <Pagination defaultActivePage={orgPage.number + 1}
                                            totalPages={orgPage.totalPages}
                                            onPageChange={(event, data) => listOrgs(data.activePage - 1, searchKeyword)}
                                />
                                }

                                {currentSelectedOrg &&
                                <Button floated='right' icon labelPosition='left' positive size='small' as={Link}
                                        to={`/orgs/${currentSelectedOrg.id}/license`}>
                                    <Icon name='money bill alternate outline'/>Usage & Server Details
                                </Button>
                                }
                            </Table.HeaderCell>
                        </Table.Row>

                    </Table.Footer>

                </Table>

            </div>


        )

    }
}

const mapStateToProps = (state) => ({
    isAdmin: state.session.admin,
    orgPage: state.orgs.currentPage,
    currentSelectedOrg: state.orgs.currentOrg,
    searchKeyword: state.users.searchKeyword
});

const mapDispatchToProps = (dispatch) => ({
    listOrgs: (page, searchKeyword) => dispatch(
        listRemoteOrganizations(page, 10, searchKeyword)),
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
});


export const OrganizationList = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationListView);


