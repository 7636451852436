import {
    CLOSE_ORG_ADD_MODAL,
    CLOSE_PRODUCT_CONFIRM_MODAL,
    OPEN_PRODUCT_CONFIRM_MODAL,
    ORG_ADD_PRODUCT,
    ORG_PRODUCTS_READY,
    ORG_REMOVE_PRODUCT,
    ORG_UPDATED,
    ORGS_READY,
    SELECT_ORG,
    SELECT_USER,
    SHOW_ORG_ADD_MODAL
} from "../actions/actionTypes";
import {arrayToObject} from "./utils";


const initialSettings = {
    currentPage: null,
    currentOrg: null,
    allOrgs: null, //list of all orgs selected from UserDetails dropdown
    orgIds: null,
    isOrgModalShown: false,
    openProductConfirmModal: false,
};


const updateRowOnOrgPage = (page, oldOrg, newOrg) => {

    if (!page) return page;

    return {
        ...page,
        content: page.content.map(item => {
            if (item.name === oldOrg.name) {
                return {
                    ...newOrg
                }
            } else {
                return item;
            }
        })
    }
};


export const orgs = (state = initialSettings, action) => {
    switch (action.type) {
        case ORGS_READY:
            let orgs = [];
            let orgIds = {};

            //prepare the all orgs list for dropdown
            action.page.content.forEach((org, i) => {
                orgs.push({
                    key: i,
                    text: org.name,
                    value: org.name
                });
                orgIds[org.id] = {
                    key: i,
                    text: org.name,
                    value: org.id,
                };
            });

            return {
                ...state,
                currentPage: {
                    ...action.page
                },
                allOrgs: orgs,
                orgIds: orgIds,

            };
        case SELECT_ORG:
            return {
                ...state,
                currentOrg: action.org
            };

        case SELECT_USER: {
            return {
                ...state,
                currentOrg: undefined
            }
        }
        case ORG_UPDATED:
            return {
                ...state,
                currentPage: updateRowOnOrgPage(state.currentPage, state.currentOrg, action.org),
                currentOrg: action.org,
            };
        case SHOW_ORG_ADD_MODAL:
            return {
                ...state,
                isOrgModalShown: true
            };
        case CLOSE_ORG_ADD_MODAL:
            return {
                ...state,
                isOrgModalShown: false
            };

        case ORG_PRODUCTS_READY: {

            action.products = arrayToObject(action.products, "productCode", "productCode");

            return {
                ...state,
                currentOrg: {
                    ...state.currentOrg,
                    products: action.products
                }
            }
        }

        case ORG_REMOVE_PRODUCT: {

            delete state.currentOrg.products[action.code];
            return {
                ...state,
                currentOrg: {
                    ...state.currentOrg
                }
            }
        }

        case ORG_ADD_PRODUCT: {

            const product = {
                productCode: action.product.code,
                productName: action.product.name
            };
            return {
                ...state,
                currentOrg: {
                    ...state.currentOrg,
                    products: {
                        ...state.currentOrg.products,
                        [action.product.code]: product
                    }
                }
            }
        }

        case CLOSE_PRODUCT_CONFIRM_MODAL: {
            return {
                ...state,
                openProductConfirmModal: false
            }
        }

        case OPEN_PRODUCT_CONFIRM_MODAL: {
            return {
                ...state,
                openProductConfirmModal: true
            }
        }
        default:
            return state;
    }
};


