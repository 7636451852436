import React from 'react';
import {useSelector} from "react-redux";
import MaterialTable from "material-table";
import moment from "moment";
import {Icon} from "semantic-ui-react";

import Typography from "@material-ui/core/Typography";

const LOG_ERROR = "ERROR";
const LOG_START_INIT_AUTH = "Starting init authentication V2 process";

const loadFlagIcon = (row) => {
    if (row.message) {
        if (row.message.includes(LOG_ERROR)) {
            return <Icon name='flag' color='red' size='large'/>
        } else if (row.message.includes(LOG_START_INIT_AUTH)) {
            return <Icon name='angle double right' color='green' size='large'/>

        } else return null;

    } else {
        return null;
    }
}

const columns =
    [
        {
            width: "2%",
            title: "",
            render: loadFlagIcon
        },
        {
            width: '10%',
            title: "Timestamp",
            tooltip: <Typography variant={"h5"}>Shown in local timezone. The browser time zone.</Typography>,
            render: (row) => moment.utc(row.timestamp).local().format("YYYY-MM-DD HH:mm:ss.SSS")
        },
        {
            width: "88%",
            title: "Message",
            field: "message"
        }
    ];


const LogsTable = () => {

    const {logs} = useSelector(state => state.releases);
    return (
        <div>
            <MaterialTable columns={columns}
                           title={"ActiveServer CloudWatch logs"}
                           options={{
                               // filtering: true
                               search: false,
                               tableLayout: "fixed",
                               pageSize: 20,
                               pageSizeOptions: [20, 40, 100],
                               exportButton: true,
                               exportAllData: true
                           }}
                           data={logs ? Object.values(logs) : []}/>
        </div>
    );
};

export default LogsTable;
