import {Dashboard} from "./Dashboard";
import {Release} from "./release/Release";
import {OrganisationPage} from "./organizations/OrgPage";
import {UsersPage} from "./users/UserPage";
import ASInstanceLicense from "./license/ASInstanceLicense";
import LicenseMain from "./license/LicenseMain";
import SaasMain from "./saas/SaasMain";
import LogsMain from "./saas/devops/LogsMain";
import {Icon} from "semantic-ui-react";
import React from "react";
import {PERMISSIONS, ROLES} from "../../constants/session";
import SettingsMain from "./settings/SettingsMain";
import NotFound from "./404/NotFound";
import IncidentMain from "./saas/incidents/IncidentMain";
import TenantMain from "./saas/tenants/TenantMain";
import ASSaasUsageMain from "./saas/tenants/as/ASSaasUsageMain";


export const childPaths = {
    tenants: {
        path: '/saas/tenants',
        component: TenantMain,
        label: "Tenants",
        icon: <Icon name='id badge outline'/>,
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.AS_SAAS_READ_TENANTS.value],
    },
    logs: {
        path: '/saas/logs',
        component: LogsMain,
        label: "Logs",
        icon: <Icon name='file alternate outline'/>,
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.AS_SAAS_READ_LOGS.value],
    },
    incidents: {
        path: '/saas/incidents',
        component: IncidentMain,
        label: "Incidents",
        icon: <Icon name='exclamation triangle'/>,
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.AS_SAAS_READ_INCIDENTS.value],
    },

};

export const headers = [
    {
        path: '/',
        component: Dashboard,
        label: "Home",
        icon: "home",
        permissions: []
    },
    {
        path: '/users',
        component: UsersPage,
        label: "Users",
        icon: "user",
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.MY_ACCOUNT_READ_USERS.value]
    },
    {
        path: '/orgs',
        component: OrganisationPage,
        icon: "building",
        label: "Organisations",
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.MY_ACCOUNT_READ_ORGS.value]
    },
    {
        label: "Saas",
        path: "/saas",
        icon: "cloud",
        component: SaasMain,
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.AS_SAAS_READ_LOGS.value, PERMISSIONS.AS_SAAS_READ_INCIDENTS.value],
        children: {
            tenants: childPaths.tenants,
            logs: childPaths.logs,
            incidents: childPaths.incidents,
        }
    },
    {
        path: '/releases',
        component: Release,
        icon: "archive",
        label: "Releases",
        permissions: [ROLES.ROLE_ADMIN.value,
            PERMISSIONS.AS_READ_RELEASE.value,
            PERMISSIONS.AME_READ_RELEASE.value,
            PERMISSIONS.AA_READ_RELEASE.value],
    },
    {
        path: "/licenses",
        component: LicenseMain,
        icon: "drivers license",
        label: "Licenses",
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.AS_READ_LICENSE.value, PERMISSIONS.SDK_READ_LICENSE.value],
    },
    {
        path: "/settings",
        component: SettingsMain,
        icon: "setting",
        label: "Settings",
        permissions: [ROLES.ROLE_ADMIN.value,],
    }
];

export const modules = [
    ...Object.values(childPaths),
    ...headers,
    {
        path: '/orgs/:orgId/license',
        component: ASInstanceLicense,
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.MY_ACCOUNT_READ_ORGS.value, PERMISSIONS.AS_READ_LICENSE.value]
    },
    {
        ...childPaths.tenants,
        path: "/saas"
    },
    {
        path: '/saas/as/tenants/:tenantId',
        component: ASSaasUsageMain,
        permissions: [ROLES.ROLE_ADMIN.value, PERMISSIONS.AS_SAAS_READ_TENANTS.value,]
    },

    {
        path: "/404",
        component: NotFound,
        permissions: []
    },

];
