import React from 'react';
import {useForm} from "react-hook-form";
import {Button, Form} from "semantic-ui-react";
import {createUseStyles} from "react-jss";
import {globalStyle} from "../../../styles/globalStyle";
import PropTypes from "prop-types";
import {ErrorMessage} from "@hookform/error-message";


const useStyles = createUseStyles(({
    ...globalStyle()
}));

const formKeys = {
    name: "name",
    description: "description",
};

const CreateRoleForm = (props) => {

    const {onSubmit} = props;
    const classes = useStyles();

    const {register, handleSubmit, errors, formState} = useForm({});
    const {isDirty, isSubmitting} = formState;

    return (
        <div>
            <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
                <h3>Create a new Role</h3>
                <Form.Field>
                    <label>Role name <span className={classes.required}>*</span></label>
                    <input placeholder='NEW_ROLE' name={formKeys.name} ref={register({
                        minLength: {
                            value: 3,
                            message: "Must be more than 3 characters"
                        },
                        maxLength: {
                            value: 100,
                            message: "Must be at less than 100 characters"
                        },
                        pattern: {
                            value: /^[A-Z_]+$/,
                            message: "can only contain uppercase letters and underscore"
                        },
                        required: "Role name is required",
                    })}/>
                    <ErrorMessage errors={errors} name={formKeys.name}>
                        {({message}) => <p className={classes.errorMessage}>{message}</p>}
                    </ErrorMessage>
                </Form.Field>
                <Form.Field>
                    <label>Description</label>
                    <textarea placeholder='Notes about the role' name={formKeys.description} cols="40" rows="5"
                              ref={register({
                                  maxLength: {
                                      value: 200,
                                      message: "Cannot be longer than 200 characters",
                                  },
                              })}/>
                </Form.Field>
                <ErrorMessage errors={errors} name={formKeys.description}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                <Button type={"submit"} primary disabled={isSubmitting || !isDirty}>
                    Create
                </Button>
            </Form>
        </div>
    );
};

CreateRoleForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default CreateRoleForm;
