import {Button, Dropdown, Icon, Input, Menu} from "semantic-ui-react";
import React from "react";
import {userTableHeaders} from "./users/UserList";
import {listRemoteOrganizations} from "../../actions/organizations";
import {listAllRemotePermissions, listRemoteUsers} from "../../actions/users";
import {
    CHANGE_FILTER,
    SHOW_ORG_ADD_MODAL,
    SHOW_USER_ADD_MODAL,
    UPDATE_SEARCH_KEYWORD
} from "../../actions/actionTypes.js";
import {connect} from "react-redux";
import {UserAdd} from "./users/UserAdd";
import {OrgAdd} from "./organizations/OrgAdd";

class Toolbar extends React.Component {

    render() {
        const {filter, searchKeyword, currentUser, currentOrg} = this.props;

        return (

            <Menu pointing attached="top">

                <Menu.Menu>
                    <Menu.Item>
                        <Input icon='search' placeholder="Search ..." value={searchKeyword}
                               onChange={(e) => this.props.updateSearchKeyword(e.target.value)}/>
                    </Menu.Item>

                </Menu.Menu>
                <Menu.Menu position={"right"}>

                    {currentUser &&
                    <Menu.Item>
                        <Dropdown text={filter ? userTableHeaders[filter.orderBy].text : "None"} icon='filter'
                                  floating
                                  labeled button className='icon'>
                            <Dropdown.Menu>
                                {Object.values(userTableHeaders).map(header => (
                                    (header.sortable &&
                                        <Dropdown.Item key={header.key}
                                                       onClick={() => this.props.changeFilter(header.value)}
                                                       selected={filter && filter.orderBy === header.key}>{header.text}</Dropdown.Item>
                                    )
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                    }
                    <Menu.Item>
                        {currentUser &&
                        <Button circular animated='vertical' onClick={this.props.showUserAddModal}>
                            <Button.Content hidden>
                                <Icon name='user'/>
                            </Button.Content>
                            <Button.Content visible>
                                <Icon name='plus'/>
                            </Button.Content>
                        </Button>
                        }

                        {currentOrg &&
                        <Button circular animated='vertical' onClick={this.props.showOrgAddModal}>
                            <Button.Content hidden>
                                <Icon name='building'/>
                            </Button.Content>
                            <Button.Content visible>
                                <Icon name='plus'/>
                            </Button.Content>
                        </Button>
                        }
                    </Menu.Item>
                </Menu.Menu>

                <UserAdd/>
                <OrgAdd/>
            </Menu>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    currentUser: state.users.currentUser,
    currentOrg: state.orgs.currentOrg,
    isAdmin: state.session.admin,
    searchKeyword: state.users.searchKeyword,
    filter: state.users.filter
});

const mapDispatchToProps = (dispatch) => ({

    listOrgs: (keyword = null) =>
        dispatch(listRemoteOrganizations(0, 10, keyword)),
    listAllPermissions: () => dispatch(listAllRemotePermissions()),
    showUserAddModal: () => dispatch({type: SHOW_USER_ADD_MODAL}),
    showOrgAddModal: () => dispatch({type: SHOW_ORG_ADD_MODAL}),
    updateSearchKeyword: (keyword) => {
        dispatch({type: UPDATE_SEARCH_KEYWORD, keyword});
    },
    changeFilter: (value) => {
        dispatch({type: CHANGE_FILTER, value});
        dispatch(listRemoteUsers())
    }
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Toolbar);
