export const Config = {
    authHost: process.env.REACT_APP_OAUTH2_SERVER_URL,
    licenseApiServerUrl: process.env.REACT_APP_LICENSE_API_SERVER_URL,
    releaseApiServerUrl: process.env.REACT_APP_RELEASE_API_SERVER_URL,
    myAccountUrl: process.env.REACT_APP_MYACCOUNT_URL,
    saasDomainName: process.env.REACT_APP_SAAS_DOMAIN_NAME,
    // licenseApiServerUrl: dev ? "http://localhost:7070/api/v1" : "https://uat.lic0.gpayments.com/api/v1",
    // releaseApiServerUrl: dev ? "http://localhost:8080/api/v1/admin" : "https://uat.release.gpayments.com/api/v1/admin",
    // myAccountUrl: dev? "http://localhost:3000":"https://uat.myaccount.gpayments.com"
};


export const get = (session, url) => {
    return request('GET', session, url);
};

export const post = (session, url, data = null) => {
    return request('POST', session, url, data);
};

export const put = (session, url, data = null) => {
    return request('PUT', session, url, data);
};


export const del = (session, url) => {
    return request('DELETE', session, url);
};

export const request = (method, session, url, data = null) => {

    return fetch(url, {
        headers: {
            Authorization: `Bearer ${session.token}`,
            'Content-Type': 'application/json'
        },
        method: method,
        body: data && JSON.stringify(data)
    });
};

