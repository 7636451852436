import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Grid, Menu, Segment} from "semantic-ui-react";
import ASInstanceList from "./ASServerList";
import InstanceDetails from "./ServerDetails";
import UsageInfo from "./UsageInfo";

const serverMenuTabs = {
    usages: {
        key: "usages",
        tabName: "Usages",
        component: <UsageInfo/>
    },
    details: {
        key: "details",
        tabName: "Details",
        component: <InstanceDetails/>
    },
};

const ASInstanceLicense = (props) => {
    const {currServer} = useSelector(state => state.licenses);

    const [activeItem, setActiveItem] = useState("usages");

    const handleItemClick = (e, {name}) => {
        setActiveItem(name);
    };

    return (
        <div>
            <Grid padded>
                <Grid.Column largeScreen={7} mobile={16}>
                    <Segment padded>
                        <h2>ActiveServer list</h2>
                        <ASInstanceList/>
                    </Segment>
                </Grid.Column>

                {currServer &&
                <Grid.Column largeScreen={9} mobile={16}>
                    <Menu attached="top" pointing>
                        {Object.values(serverMenuTabs).map((item, i) => (
                            <Menu.Item key={i} name={item.key} active={activeItem === item.key}
                                       onClick={handleItemClick}>{item.tabName}</Menu.Item>
                        ))}
                    </Menu>
                    {serverMenuTabs[activeItem].component}
                </Grid.Column>
                }
            </Grid>
        </div>
    )
};

export default ASInstanceLicense;

