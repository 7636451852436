import React, {useEffect} from 'react';
import {Grid, Header, Segment} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import ReactJson from "react-json-view";
import {selectLCKP} from "../../../actions/licenses";

const LckpDetails = () => {
    const {currentLCKP, lckps} = useSelector(state => state.licenses);

    const dispatch = useDispatch();
    const publicKey = JSON.parse(currentLCKP.publicKey);
    const keyPair = JSON.parse(currentLCKP.keyContent);

    useEffect(() => {
        let keyId = null;
        if (lckps && lckps.content && lckps.content.length > 0) {
            keyId = lckps.content[0].keyId;
        }

        dispatch(selectLCKP(keyId));

    }, [dispatch, lckps]);

    return (
        <Segment>
            <Header as="h3">License common key pair details</Header>
            <Grid columns={2}>
                <Grid.Column width={3}>
                    <label>Key ID</label>
                </Grid.Column>
                <Grid.Column width={13}>
                    <div>{currentLCKP.keyId}</div>
                </Grid.Column>
                <Grid.Column width={3}>
                    <label>Key type</label>
                </Grid.Column>
                <Grid.Column width={13}>
                    <div>{currentLCKP.keyType}</div>
                </Grid.Column>
                <Grid.Column width={3}>
                    <label>Public key</label>
                </Grid.Column>
                <Grid.Column width={13}>
                    <ReactJson src={publicKey} displayDataTypes={false} displayObjectSize={false}
                               collapseStringsAfterLength={40}/>
                </Grid.Column>
                <Grid.Column width={3}>
                    <label>Key pair</label>
                </Grid.Column>
                <Grid.Column width={13}>
                    <ReactJson src={keyPair} displayDataTypes={false} displayObjectSize={false}
                               collapseStringsAfterLength={40}/>
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default LckpDetails;