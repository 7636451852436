import React, {useState} from "react";
import {Menu} from "semantic-ui-react";
import {PropTypes} from "prop-types";

const SimpleTabMenu = (props) => {

    const [activeItem, setActiveItem] = useState(Object.keys(props.menu)[0]);

    const {menu} = props;

    const handleItemClick = (e, {name}) => {
        setActiveItem(name);
    };

    return (
        <div>

            <Menu attached="top" pointing>

                {Object.values(menu).map((item, i) => (

                    <Menu.Item key={i} name={item.key} active={activeItem === item.key}
                               onClick={handleItemClick}>{item.tabName}</Menu.Item>
                ))}
            </Menu>
            {menu[activeItem].component}
            {props.children && props.children(activeItem)}
        </div>
    )
};


SimpleTabMenu.propTypes = {
    menu: PropTypes.object.isRequired,
};

export default SimpleTabMenu;
