import red from "@material-ui/core/colors/red";

export const globalStyle = theme => ({

    errorMessage: {
        color: red[700],
    },
    warningMessage: {
        color: "#ff9800"
    },
    required: {
        color: red[700],
    },
    container: {
        margin: 16
    },
    m2: {
        margin: 16
    },
    ml2: {
        marginLeft: 16,
    },
    mt1: {
        marginTop: 8
    },
    mb2: {
        marginBottom: 16
    }
});
