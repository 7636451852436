import React from 'react';
import TenantMain from "./tenants/TenantMain";
import SimpleTabMenu from "../../components/SimpleTabMenu";
import IncidentMain from "./incidents/IncidentMain";
import {createUseStyles} from "react-jss";
import {globalStyle} from "../../../styles/globalStyle";

const useStyles = createUseStyles(({
    ...globalStyle()
}));


const saasTabs = {
    tenants: {
        key: "tenants",
        tabName: "Tenants",
        component: <TenantMain/>
    },
    incidents: {
        key: "incidents",
        tabName: "Incidents",
        component: <IncidentMain/>
    }
};


const SaasMain = () => {
    const classes = useStyles();


    return (
        <div className={classes.container}><SimpleTabMenu menu={saasTabs}/></div>
    );
};

export default SaasMain;
