import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {createUseStyles} from "react-jss";
import {globalStyle} from "../../../../styles/globalStyle";
import {Button, Checkbox, Form, Icon, Select} from "semantic-ui-react";
import * as moment from "moment";
import {useDispatch} from "react-redux";
import {updateIncident} from "../../../../actions/release";
import PropTypes from "prop-types";
import {ErrorMessage} from "@hookform/error-message";
import {INCIDENT_VISIBILITIES} from "../../../../constants/saas";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Typography from "@material-ui/core/Typography";

const useStyles = createUseStyles(({
    ...globalStyle()
}));


const formDefs = {
    falsePositive: "falsePositive",
    recoveredAt: "recoveredAt",
    description: "description",
    visibility: "visibility",
};

const UpdateIncidentForm = (props) => {
    const {defaultValues} = props;
    const classes = useStyles();
    const {register, unregister, handleSubmit, errors, setValue, formState, watch,} = useForm({
        defaultValues: {
            ...defaultValues,
            recoveredAt: defaultValues.recoveredAt ? moment.utc(defaultValues.recoveredAt).toDate() : null,
        },
    });
    const {isSubmitting, isDirty} = formState;
    const watchIsFalsePositive = watch(formDefs.falsePositive);
    const watchVisibility = watch(formDefs.visibility);

    const dispatch = useDispatch();

    useEffect(() => {
        register(formDefs.falsePositive);
        register({name: formDefs.visibility}, {required: "Visibility is required"});

        return () => {
            unregister(formDefs.falsePositive);
            unregister(formDefs.visibility);

        }

    }, [register, unregister]);

    useEffect(() => {
        if (defaultValues.recoveredAt) {
            setValue(formDefs.recoveredAt, moment.utc(defaultValues.recoveredAt).toDate());
        } else {
            setValue(formDefs.recoveredAt, null);
        }

        setValue(formDefs.falsePositive, defaultValues.falsePositive);
        setValue(formDefs.visibility, defaultValues.visibility);
        setValue(formDefs.description, defaultValues.description);

    }, [defaultValues, setValue]);

    const onSubmit = (values) => {

        // if (values.recoveredAt) {
        //     values.recoveredAt = moment.utc(values.recoveredAt).format("YYYY-MM-DDTHH:mm:ss")
        // }

        dispatch(updateIncident(defaultValues.incidentID, values));
    };

    const handleFalsePositiveChange = (e, {value}) => {

        setValue(formDefs.falsePositive, value, {shouldDirty: true});
    };

    const onVisibilityChange = (e, data) => {
        setValue(formDefs.visibility, data.value, {shouldDirty: true});

    };



    return (
        <div>
            <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
                <h3>Update Incident</h3>
                {/*<Form.Field>*/}
                {/*    <label>Recovered at</label>*/}
                {/*    <Controller*/}
                {/*        name={formDefs.recoveredAt}*/}
                {/*        control={control}*/}
                {/*        render={({onChange, value}) => (*/}
                {/*            <ReactDatePicker*/}
                {/*                selected={value}*/}
                {/*                onChange={onChange}*/}
                {/*                locale="pt-BR"*/}
                {/*                showTimeSelect*/}
                {/*                timeFormat="HH:mm"*/}
                {/*                timeIntervals={15}*/}
                {/*                dateFormat="dd/MM/yyyy HH:mm"*/}
                {/*                todayButton="Today"*/}
                {/*                maxDate={new Date()}*/}
                {/*                placeholderText="Select the date and time in which the incident recovered"*/}
                {/*            />*/}
                {/*        )}*/}
                {/*    />*/}
                {/*</Form.Field>*/}
                <ErrorMessage errors={errors} name={formDefs.recoveredAt}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                <Form.Field>
                    <label>Is false positive?</label>
                    <Form.Field>
                        <Checkbox
                            radio
                            label='Yes'
                            name={formDefs.falsePositive}
                            value={true}
                            checked={watchIsFalsePositive}
                            onChange={handleFalsePositiveChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            radio
                            label='No'
                            name={formDefs.falsePositive}
                            value={false}
                            checked={!watchIsFalsePositive}
                            onChange={handleFalsePositiveChange}
                        />
                    </Form.Field>
                </Form.Field>
                <ErrorMessage errors={errors} name={formDefs.falsePositive}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                <Form.Field
                    control={Select}
                    onChange={onVisibilityChange}
                    value={watchVisibility}
                    options={Object.values(INCIDENT_VISIBILITIES)}
                    label={{
                        children: <label>Visibility <span className={classes.required}>*</span>
                            <Tooltip title={
                                <Typography variant={"h5"}>
                                    The visibility of the incident<br/>
                                    ● Published - Shows up on MyAccount uptime page.<br/>
                                    ● Major/Minor - Internal use only.
                                </Typography>}>
                                <Icon name={"info circle"}/>
                            </Tooltip></label>,
                        htmlFor: 'form-select-control-tenant-type'
                    }}
                    placeholder='Visibility of incident'
                    search
                    searchInput={{id: 'form-select-control-tenant-type'}}
                />
                <ErrorMessage errors={errors} name={formDefs.visibility}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                <Form.Field>
                    <label>Description</label>
                    <textarea
                        placeholder='Notes about the incident' name={formDefs.description} cols="40" rows="5"
                        ref={register({
                            maxLength: {
                                value: 200,
                                message: "Cannot be longer than 200 characters",
                            },
                        })}/>
                </Form.Field>
                <ErrorMessage errors={errors} name={formDefs.description}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                <Button type={"submit"} primary disabled={isSubmitting || !isDirty}>
                    Update
                </Button>
            </Form>
        </div>
    );
};

UpdateIncidentForm.propTypes = {
    defaultValues: PropTypes.object,
};

export default UpdateIncidentForm;
