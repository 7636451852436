import React, {useEffect, useState} from 'react';
import LCKPTable from "./LCKPTable";
import {Button, Divider, Grid, Header, Menu, Segment} from "semantic-ui-react";
import LCKPForm from "./LCKPForm";
import {products} from "../../../constants/products";
import {useDispatch, useSelector} from "react-redux";
import {getLCKPs} from "../../../actions/licenses";
import LckpDetails from "./LCKPDetails";
import SimpleModal from "../../components/SimpleModal";
import SdkSecret from "./SDKSecret";
import SDKSecretForm from "./SDKSecretForm";

const LicenseMain = (props) => {

    const {currentLCKP} = useSelector(state => state.licenses);
    const [currentProduct, setProduct] = useState(products.sdk.code);
    const [lckpModal, setLCKPModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        // TODO: later if we need to do paging we can dynamically change this values


        dispatch(getLCKPs(currentProduct, 0, 20));
    }, [dispatch, currentProduct]);


    return (
        <div>

            <Menu pointing attached="top">
                {Object.values(products).filter(p => p.license).map((product, i) => <Menu.Item key={i}
                                                                                               name={product.code}
                                                                                               active={currentProduct === product.code}
                                                                                               onClick={() => setProduct(product.code)}>{product.name}</Menu.Item>
                )}
            </Menu>
            <Segment attached={"bottom"}>
                <Header as="h2">Common key pair (LCKP)</Header>
                <Divider/>
                <Grid columns={2} padded>
                    <Grid.Column widescreen={10}>
                        <LCKPTable/>
                        <Button primary onClick={() => setLCKPModal(true)}>Import new</Button>
                    </Grid.Column>
                    <Grid.Column widescreen={6}>
                        {currentLCKP &&
                        <LckpDetails/>}
                    </Grid.Column>
                </Grid>
            </Segment>
            {currentProduct === products.sdk.code &&

            <Segment attached={"bottom"}>
                <Header as="h2">SDK secret</Header>
                <Divider/>
                <Grid columns={2} padded>
                    <Grid.Column widescreen={8}>
                        <SdkSecret/>
                    </Grid.Column>
                    <Grid.Column widescreen={6}>
                        <SDKSecretForm/>
                    </Grid.Column>
                </Grid>
            </Segment>}
            <SimpleModal open={lckpModal} header={"Import license common key pair"} onClose={() => setLCKPModal(false)}>
                <LCKPForm initialValues={{productCode: currentProduct}}/>
            </SimpleModal>
        </div>
    );
};

export default LicenseMain;