export const globalStyles = {
        ml1: {
            marginLeft: 10,
        },
        mt1: {
            marginTop: 10,
        },
        modal: {top: '10%', left: "35%"}
    }
;
