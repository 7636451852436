import {Header, Icon, Segment} from "semantic-ui-react";
import React from "react";

export const CustomPlaceholder = ({iconName, header}) => (
    <Segment attached="bottom" padded textAlign="center">
        <Header icon>
            <Icon name={iconName}/>
            {header}
        </Header>
    </Segment>
);
