import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import {Dropdown, Grid} from "semantic-ui-react";
import {getServerUsage} from "../../../actions/licenses";
import React, {useEffect, useState} from 'react';
import ReactEcharts from "echarts-for-react";
import {USAGE_TYPE_DEFS} from "../../../constants/usage";
import {timeFormats} from "../../../constants/datetime";

const timeOptions = [
    {key: 1, text: 'Daily', value: timeFormats.DAILY.value},
    {key: 2, text: 'Weekly', value: timeFormats.WEEKLY.value},
    {key: 3, text: 'Monthly', value: timeFormats.MONTHLY.value},
    {key: 4, text: 'Yearly', value: timeFormats.YEARLY.value},
];

const usageTypeOptions = [
    {key: 1, text: 'Total Authentications', value: USAGE_TYPE_DEFS.DirectoryServerType.value},
    {key: 2, text: 'Card Schemes', value: USAGE_TYPE_DEFS.PaymentNetwork.value},
    {key: 3, text: 'Device Channel', value: USAGE_TYPE_DEFS.DeviceChannel.value},
    {key: 4, text: 'Transaction Status', value: USAGE_TYPE_DEFS.TransStatus.value},
];

const ServerUsage = (props) => {
// set the date to be the same if not valid.
    const {usage, currServer} = useSelector(state => state.licenses);

    const [usageType, setUsageType] = useState(USAGE_TYPE_DEFS.DirectoryServerType.value);

    const date = new Date();
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(new Date(date.getFullYear() + 1, 0, 1));
    const [timeFormat, setTimeFormat] = useState(timeFormats.MONTHLY.value);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServerUsage(currServer.id, startDate, endDate, timeFormat, usageType));
    }, [dispatch, startDate, endDate, timeFormat, usageType, currServer]);

    // const changeUsageOption = (event, type) => dispatch({type: USAGE_TYPE_CHANGED, value: type.value});
    const changeStartDate = (date) => {
        if (date > endDate) {
            setEndDate(date);
        }
        setStartDate(date);
    };
    const changeEndDate = (date) => {
        if (date < startDate) {
            setStartDate(date);
        }
        setEndDate(date);
    };

    const changeTimeFormat = (event, format) => {
        setTimeFormat(format.value);
    };

    const handleChangeUsageType = (event, data) => {
        setUsageType(data.value);
    };

    return (
        <>
            <Grid>
                <Grid.Column width={2}>
                    <h4>Start Date</h4>
                </Grid.Column>
                <Grid.Column width={3}>
                    <DatePicker
                        dropdownMode="scroll"
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        onChange={changeStartDate}
                        dateFormat={"dd-MM-yyyy"}
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <h4>End Date</h4>
                </Grid.Column>
                <Grid.Column width={3}>

                    <DatePicker
                        dropdownMode="scroll"
                        selected={endDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        onChange={changeEndDate}
                        dateFormat={"dd-MM-yyyy"}
                    />
                </Grid.Column>
            </Grid>
            <Grid padded>
                <Grid.Column width={3}>
                    <Dropdown clearable={"true"} options={timeOptions} selection defaultValue={timeFormat}
                              onChange={changeTimeFormat}/>
                </Grid.Column>
                <Grid.Column width={1}/>

                <Grid.Column width={3}>
                    <Dropdown clearable={"true"} options={usageTypeOptions} selection
                              defaultValue={usageType}
                              onChange={handleChangeUsageType}/>
                </Grid.Column>
            </Grid>


            {usage &&
            <ReactEcharts
                option={usage}
                notMerge={true}
                lazyUpdate={true}
            />}
        </>
    );
};

export default ServerUsage;

