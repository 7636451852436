import React, {useEffect, useState} from 'react';
import {Button, Form, Select} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {INTERNAL_TENANT_TYPES, TENANT_TYPES} from "../../../../constants/saas";
import {useDispatch, useSelector} from "react-redux";
import {listRemoteOrganizations} from "../../../../actions/organizations";
import {getMinimumExpiryDate, isExpiryDateAllowed, onboardTenant} from "../../../../actions/licenses";
import {createUseStyles} from "react-jss";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import {globalStyle} from "../../../../styles/globalStyle";
import {ErrorMessage} from "@hookform/error-message";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = createUseStyles(({
    ...globalStyle()
}));


const CreateTenantForm = (props) => {
    const classes = useStyles();
    const {orgIds} = useSelector(state => state.orgs);

    const [expiryDate, setExpiryDate] = useState(null);

    const {register, unregister, handleSubmit, errors, setValue, formState, watch} = useForm({});

    const dispatch = useDispatch();
    const {isDirty, isSubmitting} = formState;

    const watchTenantType = watch('tenantType');


    useEffect(() => {
        register({name: 'tenantType'}, {required: "Tenant type is required"});
        register({name: 'orgId'},);

        return () => {
            unregister("tenantType");
            unregister("orgId");
        }

    }, [register, unregister]);

    useEffect(() => {
        dispatch(listRemoteOrganizations())
    }, [dispatch]);

    const onTenantChange = (e, data) => {
        setValue('tenantType', data.value);
    };

    const onOrgChange = (e, data) => {
        setValue('orgId', data.value);
    };

    const onSearchChange = (e, data) => {
        dispatch(listRemoteOrganizations(0, 10, e.target.value));

    };

    const onSubmit = data => {

        // only TEST or TRIAL tenant can have the expiry date
        if (isExpiryDateAllowed(data.tenantType) && expiryDate) {
            data.expiryDate = moment.utc(expiryDate).format("YYYY-MM-DDTHH:mm:ss");
        }

        dispatch(onboardTenant(data));
        props.onSubmit(data);
    };

    return (
        <Form as={"form"} onSubmit={handleSubmit(onSubmit)}>
            <h3>Onboard tenant</h3>
            <Form.Field>
                <label>Tenant name <span className={classes.required}>*</span></label>
                <input placeholder='Tenant Admin' name={"tenantName"} ref={register({
                    minLength: {
                        value: 2,
                        message: "Must be more than 2 characters"
                    },
                    maxLength: {
                        value: 100,
                        message: "Must be at less than 100 characters"
                    },
                    required: "Tenant name is required",
                })}/>
                <ErrorMessage errors={errors} name="tenantName">
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
            </Form.Field>

            <Form.Field>
                <label>Host name <span className={classes.required}>*</span></label>

                <input placeholder='saas-dev-1' name={"hostName"} ref={register({
                    maxLength: {
                        value: 63,
                        message: "Must be less than 63 characters"
                    },
                    minLength: {
                        value: 2,
                        message: "Must be at least 2 characters"
                    },
                    pattern: {
                        value: /^[a-zA-Z0-9][a-zA-Z0-9.\-_]+[a-zA-Z0-9]$/,
                        message: "invalid sub-domain prefix"
                    },
                    required: "Host name is required",
                })}/>
                <ErrorMessage errors={errors} name="hostName">
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>

            </Form.Field>

            <Form.Field>
                <label>Admin Email <span className={classes.required}>*</span></label>
                <input placeholder='saas-admin@gpayments.com' name={"adminEmail"} ref={register({
                    required: "Email is required",
                    pattern: {
                        value: emailRegex,
                        message: "Invalid email",
                    }
                })}/>
                <ErrorMessage errors={errors} name="adminEmail">
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
            </Form.Field>
            <Form.Field
                control={Select}
                onChange={onTenantChange}
                options={Object.values({...INTERNAL_TENANT_TYPES, ...TENANT_TYPES})}
                label={{
                    children: <label>Tenant type <span className={classes.required}>*</span></label>,
                    htmlFor: 'form-select-control-tenant-type'
                }}
                placeholder='Tenant type'
                search
                searchInput={{id: 'form-select-control-tenant-type'}}
            />
            <ErrorMessage errors={errors} name="tenantType">
                {({message}) => <p className={classes.errorMessage}>{message}</p>}
            </ErrorMessage>
            {isExpiryDateAllowed(watchTenantType) &&
            <Form.Field>
                <label>Expiry date</label>
                <DatePicker
                    todayButton="Today"
                    minDate={getMinimumExpiryDate()}
                    placeholderText="Select the expiry date of tenant"
                    selected={expiryDate}
                    locale="pt-BR"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    onChange={date => setExpiryDate(date)}
                />
                {expiryDate &&
                <p className={classes.warningMessage}>Setting the expiry date will automatically "Suspend" tenant when
                    the date is reached.</p>}
            </Form.Field>}
            {orgIds &&
            <Form.Field
                control={Select}
                onSearchChange={onSearchChange}
                onChange={onOrgChange}
                options={Object.values(orgIds)}
                label={{children: 'Organisation', htmlFor: 'form-select-control-tenant-type'}}
                placeholder='Organisation'
                search
                searchInput={{id: 'form-select-control-tenant-type'}}
            />}
            <ErrorMessage errors={errors} name="orgId">
                {({message}) => <p className={classes.errorMessage}>{message}</p>}
            </ErrorMessage>
            <Form.Field>
                <label>Description</label>
                <textarea placeholder='Notes about the tenant' name="tenantDesc" cols="40" rows="5" ref={register({
                    maxLength: {
                        value: 200,
                        message: "Cannot be longer than 200 characters",
                    },
                })}/>
            </Form.Field>
            <ErrorMessage errors={errors} name="tenantDesc">
                {({message}) => <p className={classes.errorMessage}>{message}</p>}
            </ErrorMessage>

            <Button type={"submit"} primary disabled={isSubmitting || !isDirty}>
                Onboard
            </Button>
        </Form>
    );
};

CreateTenantForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default CreateTenantForm;
