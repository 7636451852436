import React from 'react';
import {Button, Divider, Dropdown, Header, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {ADD_PERMISSION, REMOVE_PERMISSION} from "../../../actions/actionTypes";
import {updateUserDetails} from "../../../actions/users";


const UserPermissionsView = ({currentUser, permissions, removePermission, addPermission, savePermission}) => {
    return (
        <Segment attached='bottom' padded>
            <Header
                as="h3">Permissions: {currentUser ? currentUser.firstName + " " + currentUser.lastName : "Not Selected"}</Header>
            <Divider/>

            <Header as="h4">Allowed permissions</Header>
            <Table striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Product Code</Table.HeaderCell>
                        <Table.HeaderCell>Product Name</Table.HeaderCell>
                        <Table.HeaderCell>Access Level</Table.HeaderCell>
                        <Table.HeaderCell/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentUser && currentUser.permissions &&
                    Object.values(currentUser.permissions).map((permission, index) => (
                        <Table.Row key={permission.id}>
                            <Table.Cell>{permission.code}</Table.Cell>
                                <Table.Cell>{permission.name}</Table.Cell>
                            <Table.Cell>{permission.accessLevel}</Table.Cell>
                                <Table.Cell>
                                    <Button onClick={() => removePermission(permission.id)} icon
                                            size="mini">
                                        <Icon name="close"/>
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )
                    )}
                </Table.Body>

                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan='4'>
                            <Dropdown text='Add Permission' icon='add' floating labeled button className='icon'>
                                <Dropdown.Menu>
                                    <Dropdown.Header icon='key' content='List of all permissions'/>
                                    <Dropdown.Menu scrolling>
                                        {Object.values(permissions).map((permission, i) => {

                                            let label = {color: 'red', empty: true, circular: true};

                                            // change the label to green if permissionId is in currentUser
                                            if (currentUser.permissions && permission.id in currentUser.permissions) {
                                                label.color = 'green';
                                            }

                                            return (
                                                <Dropdown.Item
                                                    key={permission.id}
                                                    onClick={() => addPermission(permission.id, permission)}
                                                    text={permission.name + " " + permission.accessLevel}
                                                    value={permission.code} label={label}/>
                                            )
                                        })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            <Button onClick={() => savePermission(currentUser)} primary>Save Change</Button>
        </Segment>
    )
}


const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
    permissions: state.users.permissions
});

const mapDispatchToProps = (dispatch) => ({

    removePermission: (id) => dispatch({type: REMOVE_PERMISSION, id}),
    addPermission: (id, permission) => dispatch({type: ADD_PERMISSION, id, permission}),
    savePermission: (user) => dispatch(updateUserDetails(user)),
});


export const UserPermissions = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPermissionsView);

