import React from 'react';
import ServerUsage from "./ServerUsage";
import DeploymentInfoTable from "./DeploymentInfoTable";
import {Segment} from "semantic-ui-react";

const UsageInfo = () => {
    return (
        <Segment attached='bottom' padded>
            <h3>Transactions</h3>
            <ServerUsage/>
            <h3>Deployment Info</h3>
            <DeploymentInfoTable/>
        </Segment>
    );
};

export default UsageInfo;