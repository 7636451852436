import React from 'react';
import Box from "@material-ui/core/Box";
import {Header} from "semantic-ui-react";

const NotFound = () => {
    return (
        <div>
            <Box display={"flex"} justifyContent={"center"}>
                <Box display={"block"}>
                    <Header as='h2'>
                        Not Found
                        <Header.Subheader>
                            Page not found
                        </Header.Subheader>
                    </Header>
                </Box>
            </Box>
        </div>
    );
};

export default NotFound;
