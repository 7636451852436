import {
    CURRENT_TENANT_CAPABILITY_CHANGED,
    CURRENT_TENANT_READY,
    CURRENT_TENANT_UPDATED,
    DEPLOYMENT_INFOS_READY,
    LCKPS_READY,
    NEW_TENANT_READY,
    SDK_SECRET_READY,
    SELECT_LCKP,
    SELECT_ORG,
    SELECT_SERVER,
    SERVER_USAGE_READY,
    SERVERS_READY,
    TENANT_USAGE_READY,
    TENANTS_READY
} from "../actions/actionTypes";
import {usageTypes} from "../constants/usage";
import {SUBSCRIPTION_STATUSES} from "../constants/saas";


const initialSettings = {
    serversPage: null,
    currServer: null,
    usage: null,
    lckps: [],
    currentLCKP: null,
    currentSDKSecret: null,
    deploymentInfos: null,
    tenantsPage: null,
    newTenant: null,
    currentTenant: null,
    tenantUsage: {
        daily: [],
    }
};

export const licenses = (state = initialSettings, action) => {
    switch (action.type) {

        case SERVERS_READY: {
            return {
                ...state,
                serversPage: action.servers
            }
        }

        case SELECT_SERVER: {

            return {
                ...state,
                currServer: action.server
            }
        }
        case SERVER_USAGE_READY: {

            const option = {
                title: {
                    text: 'Usage'
                },
                legend: {
                    data: [] // we need to fill this
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [] // we need to fill this
            };

            const series = {}; // map with key as usage type value

            Object.values(usageTypes[action.usageType]).forEach(serie => {
                option.legend.data.push(serie.name);
                series[serie.value] = {
                    name: serie.name,
                    data: [],
                    type: "line"
                };
            });


            if (action.usage) {
                // for each date
                action.usage.data.forEach((data, i) => {
                    // make the x axis
                    option.xAxis.data.push(data.date);

                    // add default values if it does not exist
                    Object.values(usageTypes[action.usageType]).forEach(usageType => {

                        let authCount = data.authCounts ? data.authCounts.find(d => d.name === usageType.value) : null;

                        if (authCount) {
                            series[usageType.value].data.push(authCount.count);
                        } else {
                            series[usageType.value].data.push(0);
                        }
                    });
                });
            }


            return {
                ...state,
                usage: {
                    ...option,
                    series: Object.values(series),
                }
            }
        }
        case DEPLOYMENT_INFOS_READY: {
            return {
                ...state,
                deploymentInfos: action.info,
            }
        }
        case SELECT_ORG: {
            return {
                ...state,
                currServer: null
            }
        }
        case LCKPS_READY: {
            return {
                ...state,
                lckps: action.lckps
            }
        }
        case SELECT_LCKP: {

            return {
                ...state,
                currentLCKP: action.lckp
            }
        }
        case SDK_SECRET_READY: {
            return {
                ...state,
                currentSDKSecret: action.secret
            }
        }
        case TENANTS_READY: {
            return {
                ...state,
                tenantsPage: action.tenants,
            }
        }
        case NEW_TENANT_READY: {
            return {
                ...state,
                newTenant: action.tenant,
            }
        }
        case CURRENT_TENANT_READY: {
            return {
                ...state,
                currentTenant: action.tenant,
            }
        }
        case CURRENT_TENANT_UPDATED: {
            const tenant = {...action.tenant};
            const subscriptionStatus = Object.values(SUBSCRIPTION_STATUSES).find(s => s.value === action.tenant.subscriptionStatus);
            if (subscriptionStatus) {
                tenant.subscriptionStatus = subscriptionStatus.text;
            }

            return {
                ...state,
                currentTenant: tenant,
            }
        }
        case CURRENT_TENANT_CAPABILITY_CHANGED: {
            const capabilities = state.currentTenant.capabilities ? state.currentTenant.capabilities.split(",") : [];
            if (action.enableCapability) {
                capabilities.push(action.enableCapability);
            } else if (action.disableCapability) {
                const index = capabilities.indexOf(action.disableCapability);
                if (index !== -1) {
                    capabilities.splice(index, 1);
                }
            }

            return {
                ...state,
                currentTenant: {
                    ...state.currentTenant,
                    capabilities: capabilities.join(","),
                }
            }
        }

        case TENANT_USAGE_READY: {
            return {
                ...state,
                tenantUsage: action.usage,
            }
        }
        default:
            return state;
    }
};
