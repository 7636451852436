import React from 'react';
import {Segment} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import TimeRangeSelector from "../../../../components/TimeRangeSelector";
import {getTenantUsage} from "../../../../../actions/licenses";
import {getTenantType} from "../../../../../constants/saas";
import moment from "moment";

// const useStyles = createUseStyles(({
//
//     ...globalStyles
// }));


const UsageFilter = () => {
    // const classes = useStyles();
    const {currentTenant} = useSelector(state => state.licenses);

    const dispatch = useDispatch();

    const onSubmit = (values) => {

        const tenantType = getTenantType(currentTenant.tenantType);

        dispatch(getTenantUsage(currentTenant.id, values.startDate, values.endDate, tenantType ? tenantType.dsProfile : null))
    };

    return (
        <Segment padded>
            <h3>Usage report</h3>
            <TimeRangeSelector onSubmit={onSubmit} buttonText={"Generate"}
                               defaultStartDate={moment().startOf("year").toDate()}
                               defaultEndDate={moment().endOf("year").toDate()}/>
        </Segment>
    );
};

export default UsageFilter;
