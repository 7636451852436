import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCurrentActiveSalt} from "../../../actions/licenses";
import {Grid, Header, Message, Segment} from "semantic-ui-react";

const SdkSecret = (props) => {

    const {currentSDKSecret} = useSelector(state => state.licenses);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentActiveSalt())
    }, [dispatch]);

    return (
        <div>
            <Segment attached={"bottom"}>
                <Header as="h3">Current active salt</Header>
                {currentSDKSecret && currentSDKSecret.version ?
                    <div><Message>
                        <Message.Header>Salt</Message.Header>
                        <p>Current active salt used for the wrapping key generation is displayed below.</p>
                    </Message>
                        <Grid columns={2}>
                            <Grid.Column width={3}>
                                <label>Version</label>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <div>{currentSDKSecret && currentSDKSecret.version}</div>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <label>Salt</label>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <div>{currentSDKSecret && currentSDKSecret.salt}</div>
                            </Grid.Column>
                        </Grid></div> : <Message warning>
                        <Message.Header>Salt</Message.Header>
                        <p>No active salt. Please import from the right hand panel.</p>
                    </Message>}
            </Segment>
        </div>
    );
};

export default SdkSecret;