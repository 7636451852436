import React, {useEffect, useState} from 'react';
import {Button, Divider, Form, Header, Message, Segment, Select} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {updateUserDetails} from "../../../actions/users";
import {listRemoteOrganizations} from "../../../actions/organizations";
import {getRoles} from "../../../actions/auth";
import {Controller, useForm} from "react-hook-form";
import {createUseStyles} from "react-jss";
import {globalStyle} from "../../../styles/globalStyle";
import {ErrorMessage} from "@hookform/error-message";
import {PropTypes} from "prop-types";

const useStyles = createUseStyles(({
    ...globalStyle()
}));

const formKeys = {
    firstName: "firstName",
    lastName: "lastName",
    organization: "organization",
    roles: "roles",
};

const UserDetailView = (props) => {

    const {error, defaultValues} = props;
    const {allOrgs} = useSelector(state => state.orgs);
    const {currentUser} = useSelector(state => state.users);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {roles} = useSelector(state => state.session);
    const {register, control, errors, handleSubmit, formState, reset, setValue} = useForm({
        defaultValues: defaultValues,
    });

    const {isSubmitting, isDirty} = formState;
    const [rolesOptions, setRolesOptions] = useState();

    const onSubmit = (values) => {
        dispatch(updateUserDetails({
            ...defaultValues,
            ...values
        }));
    };

    useEffect(() => {

        if (roles) {
            setRolesOptions(Object.values(roles).map(r => ({
                key: r.roleName,
                text: r.roleName,
                value: r.roleName
            })));
        }

    }, [roles]);

    const onSearchChange = (e, {searchQuery}) => {
        console.log("search query", searchQuery);
        dispatch(listRemoteOrganizations(0, 20, searchQuery))
    };

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    useEffect(() => {

        Object.values(formKeys).forEach(key => {
            setValue(key, defaultValues[key]);
        })

    }, [setValue, defaultValues]);


    return (
        <Segment attached='bottom' padded as="form" onSubmit={handleSubmit(onSubmit)}>

            <Header as="h3">User
                Details: {currentUser ? currentUser.firstName + " " + currentUser.lastName : "Not Selected"}</Header>
            <Divider/>

            <Form as="div">

                <Form.Field>
                    <label>First name <span className={classes.required}>*</span></label>
                    <input placeholder='John' name={formKeys.firstName} ref={register({
                        minLength: {
                            value: 2,
                            message: "Must be more than 2 characters"
                        },
                        maxLength: {
                            value: 100,
                            message: "Must be at less than 100 characters"
                        },
                        required: "First name is required",
                    })}/>
                    <ErrorMessage errors={errors} name={formKeys.firstName}>
                        {({message}) => <p className={classes.errorMessage}>{message}</p>}
                    </ErrorMessage>
                </Form.Field>
                <Form.Field>
                    <label>Last name <span className={classes.required}>*</span></label>
                    <input placeholder='John' name={formKeys.lastName} ref={register({
                        minLength: {
                            value: 2,
                            message: "Must be more than 2 characters"
                        },
                        maxLength: {
                            value: 100,
                            message: "Must be at less than 100 characters"
                        },
                        required: "Last name is required",
                    })}/>
                    <ErrorMessage errors={errors} name={formKeys.lastName}>
                        {({message}) => <p className={classes.errorMessage}>{message}</p>}
                    </ErrorMessage>
                </Form.Field>

                {allOrgs &&
                <Controller
                    control={control}
                    name={formKeys.organization}
                    render={({onChange, onBlur, value, name}) => (
                        <Form.Field
                            control={Select}
                            onSearchChange={onSearchChange}
                            onChange={(e, data) => onChange(data.value)}
                            options={Object.values(allOrgs)}
                            label={{children: 'Organisation', htmlFor: 'form-select-control-tenant-type'}}
                            placeholder='Organisation'
                            search
                            value={value}
                            searchInput={{id: 'form-select-control-tenant-type'}}
                        />
                    )}/>}
                <ErrorMessage errors={errors} name={formKeys.organization}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>
                {rolesOptions &&
                <Controller
                    control={control}
                    name={formKeys.roles}
                    render={({onChange, onBlur, value, name}) => (
                        <Form.Field
                            control={Select}
                            fluid multiple selection
                            onChange={(e, data) => onChange(data.value)}
                            options={rolesOptions}
                            value={value}
                            label={{children: 'Roles', htmlFor: 'form-select-control-tenant-type'}}
                            placeholder='Roles'
                            searchInput={{id: 'form-select-control-tenant-type'}}
                        />
                    )}/>}
                <ErrorMessage errors={errors} name={formKeys.roles}>
                    {({message}) => <p className={classes.errorMessage}>{message}</p>}
                </ErrorMessage>

                <Button type='submit' primary disabled={!isDirty || isSubmitting}>Save Change</Button>
                <Button disabled={!isDirty || isSubmitting} onClick={() => reset()}>Undo Changes</Button>

                {error && <Message negative>
                    <Message.Header>Update failed</Message.Header>
                    <p>{error}</p>
                </Message>}

            </Form>

        </Segment>
    )
};

UserDetailView.propTypes = {
    defaultValues: PropTypes.object.isRequired,
};

export default UserDetailView;
// const mapStateToProps = (state) => ({
//     initialValues: state.users.currentUser,
//     user: state.users.currentUser,
//     isAdmin: state.session.admin,
//     allOrgs: state.orgs.allOrgs,
//     roles: state.session.roles,
// });
//
// const mapDispatchToProps = (dispatch) => ({
//     updateUserDetail: (values) => dispatch(updateUserDetails(values)),
//     searchOrgs: (keyword) => dispatch(listRemoteOrganizations(0, 20, keyword))
// });
//
//
// export const UserDetails = connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(UserDetailView);

