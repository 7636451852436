import React, {useState} from 'react';
import MaterialTable from "material-table";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const columns = {
    usageDate: {
        field: "usageDate",
        title: "Date",
        render: (row) => row.usageDate,
    },
    ds: {
        field: "ds",
        title: "DS",
        render: (row) => row.ds,
    },
    messageCategory: {
        field: "messageCategory",
        title: "Message Category",
        render: (row) => row.messageCategory,
    },
    deviceChannel: {
        field: "deviceChannel",
        title: "Device Channel",
        render: (row) => row.deviceChannel,
    },
    y: {
        field: "y",
        title: "Y",
        render: (row) => row.y,
        isDetails: true,
        tooltip: "ARes = Y (Frictionless)"
    },
    n: {
        field: "n",
        title: "N",
        render: (row) => row.n,
        isDetails: true,
        tooltip: "ARes = N (Frictionless)"
    },
    r: {
        field: "r",
        title: "R",
        render: (row) => row.r,
        isDetails: true,
        tooltip: "ARes = R (Frictionless)"
    },
    u: {
        field: "u",
        title: "U",
        render: (row) => row.u,
        isDetails: true,
        tooltip: "ARes = U (Frictionless)"
    },
    a: {
        field: "a",
        title: "A",
        render: (row) => row.a,
        isDetails: true,
        tooltip: "ARes = A (Frictionless)"
    },
    i: {
        field: "i",
        title: "I",
        render: (row) => row.i,
        isDetails: true,
        tooltip: "ARes = I (Frictionless)"
    },
    unknownFrictionless: {
        field: "unknownFrictionless",
        title: "Unknown frictionless",
        render: (row) => row.unknownFrictionless,
        isDetails: true,
        tooltip: "Unknown frictionless (Frictionless), it should not happen but just in case."
    },
    frictionless: {
        field: "frictionless",
        title: "Frictionless",
        render: (row) => row.frictionless,
    },
    c: {
        field: "c",
        title: "C",
        render: (row) => row.c,
        isDetails: true,
        tooltip: "ARes = C, RReq = Y (Challenge)"
    },
    cn: {
        field: "cn",
        title: "CN",
        render: (row) => row.cn,
        isDetails: true,
        tooltip: "ARes = C, RReq = N (Challenge)"
    },
    cr: {
        field: "cr",
        title: "CR",
        render: (row) => row.cr,
        isDetails: true,
        tooltip: "ARes = C, RReq = R (Challenge)"
    },
    cu: {
        field: "u",
        title: "U",
        render: (row) => row.u,
        isDetails: true,
        tooltip: "ARes = U (Challenge)"
    },
    ca: {
        field: "ca",
        title: "CA",
        render: (row) => row.ca,
        isDetails: true,
        tooltip: "ARes = A, RReq = A (Challenge)"
    },
    unknownChallenge: {
        field: "unknownChallenge",
        title: "Unknown challenge",
        render: (row) => row.unknownChallenge,
        isDetails: true,
        tooltip: "Unknown challenge (Challenge), it should not happen but just in case."
    },
    challenge: {
        field: "challenge",
        title: "Challenge",
        render: (row) => row.challenge,
    },
    d: {
        field: "d",
        title: "D",
        render: (row) => row.d,
        isDetails: true,
        tooltip: "ARes = D, RReq = Y (Decoupled)"
    },
    dn: {
        field: "dn",
        title: "DN",
        render: (row) => row.dn,
        isDetails: true,
        tooltip: "ARes = D, RReq = N (Decoupled)"
    },
    dr: {
        field: "dr",
        title: "DR",
        render: (row) => row.dr,
        isDetails: true,
        tooltip: "ARes = D, RReq = R (Decoupled)"
    },
    du: {
        field: "u",
        title: "U",
        render: (row) => row.u,
        isDetails: true,
        tooltip: "ARes = U (Decoupled)"
    },
    da: {
        field: "da",
        title: "DA",
        render: (row) => row.da,
        isDetails: true,
        tooltip: "ARes = A, RReq = A (Decoupled)"
    },
    unknownDecoupled: {
        field: "unknownDecoupled",
        title: "Unknown decoupled",
        render: (row) => row.unknownDecoupled,
        isDetails: true,
        tooltip: "Unknown decoupled (Decoupled), it should not happen but just in case."
    },
    decoupled: {
        field: "decoupled",
        title: "Decoupled",
        render: (row) => row.decoupled,
    },
    chargeableErrors: {
        field: "chargeableErrors",
        title: "Chargeable Errors",
        render: (row) => row.chargeableErrors,
        tooltip: <Typography variant={"h6"}>Chargeable errors includes errors that were caused after forming the AReq
            and sent to DS. These errors are chargeable since AReq was sent.</Typography>
    },
    systemErrors: {
        field: "systemErrors",
        title: "Non-chargeable Errors",
        render: (row) => row.systemErrors,
        tooltip: <Typography variant={"h6"}>Non-chargeable errors, the errors may be caused by issues due to
            ActiveServer such DB connection error, these errors are not chargeable.</Typography>
    },
    chargeable: {
        field: "chargeable",
        title: "Total Chargeable",
        render: (row) => row.chargeable,
        tooltip: <Typography variant={"h6"}>The total chargeable transactions. (Frictionless + Challenge + Decoupled +
            Chargeable Errors)</Typography>
    },
};


const UsageTable = () => {
    const {currentTenant, tenantUsage} = useSelector(state => state.licenses);
    // const dispatch = useDispatch();
    // const [showDetail, setShowDetails] = useState(false);
    const [currentColumns,] = useState([...Object.values(columns)]);


    // const onSwitch = () => {
    //
    //     if (!showDetail) {
    //         setCurrentColumns([
    //             ...Object.values(columns),
    //         ]);
    //
    //     } else {
    //
    //         setCurrentColumns([
    //             ...Object.values(columns).filter(c => !c.isDetails),
    //         ]);
    //
    //     }
    //
    //     setShowDetails(!showDetail)
    // };

    return (
        <div>

            <MaterialTable columns={currentColumns}
                           data={[

                               {
                                   usageDate: "Total",
                                   ds: "-",
                                   messageCategory: "-",
                                   deviceChannel: "-",
                                   ...tenantUsage.total,
                               },
                               ...tenantUsage.daily.map(d => (
                                   {
                                       ...d,
                                       usageDate: moment(d.usageDate).format("DD/MM/YYYY")
                                   }))]}
                           style={{zIndex: 1}} // the dropdown has zIndex of 12
                           title={`Usage_Reporting_${currentTenant && currentTenant.name}_`}
                           options={{
                               paging: false,
                               showTitle: false,
                               sorting: false,
                               exportButton: true,
                               search: false,
                           }}
                           actions={[
                               // {
                               //     icon: showDetail ? 'check_box' : 'check_box_outline_blank',
                               //     tooltip: showDetail ? 'Hide details' : 'Show details',
                               //     isFreeAction: true,
                               //     onClick: (event) => onSwitch(),
                               // }
                           ]}
            />
        </div>
    );
};

export default UsageTable;
